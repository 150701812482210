import React, { useContext, useState } from "react";
//import LangContext from "./lang-context";
import { getCookie, setCookie } from "./Cookies";

function stringToBoolean(string){
  switch(string.toLowerCase().trim()){
      case "true": 
      case "yes": 
      case "1": 
        return true;

      case "false": 
      case "no": 
      case "0": 
      case null: 
        return false;

      default: 
        return Boolean(string);
  }
}

const ThemeContext = React.createContext({
  isDarkTheme: getCookie('is_dark') === null ? false : stringToBoolean(getCookie('is_dark')),
  toggleDarkTheme: () => {},
});
const ThemeContextProvider = (props) => {  
  let currThemeCookie = getCookie('is_dark');
  if(currThemeCookie === null){
    setCookie('is_dark', false, 3650);
  } else {
    currThemeCookie = stringToBoolean(currThemeCookie);
  }
  //console.log(useContext(LangContext));
  const [isDarkTheme, setIsDarkTheme] = useState(currThemeCookie);
  
  /*
  const lng = useContext(LangContext);
  const [langCtx, setLang] = useState(lng);
  */

  const toggleDarkTheme = () => {
    setIsDarkTheme((prevState) => !prevState);
    setCookie("is_dark", !isDarkTheme, 3650);
  };

  return (
    <ThemeContext.Provider
      value={{ isDarkTheme, toggleDarkTheme/*, langCtx, switchLanguage*/ }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export { ThemeContextProvider };
export default ThemeContext;
