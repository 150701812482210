import React, { useState } from "react";
import { getCookie, setCookie } from "./Cookies";

const LangContext = React.createContext({
  lang: getCookie("lang") === null ? 
        (navigator.language || navigator.userLanguage).split('-')[0] !== 'en' && (navigator.language || navigator.userLanguage).split('-')[0] !== 'bg' ? 
            'en' : (navigator.language || navigator.userLanguage).split('-')[0] : 
            getCookie("lang"),
  switchLanguage: () => {},
});

const LangContextProvider = (props) => {
  let currCookie = getCookie('lang');
  if(currCookie === null){
    let newCookie = (navigator.language || navigator.userLanguage) !== 'en' && (navigator.language || navigator.userLanguage) !== 'bg' ? 
    'en' : navigator.language || navigator.userLanguage;
    setCookie('lang', newCookie, 3650);
  }
  const [lang, setLang] = useState(getCookie('lang'));

  const switchLanguage = (ev) => {
    let selVal = ev.target.value;
    setLang(selVal);
    setCookie("lang", selVal, 3650);
  };

  return (
    <LangContext.Provider value={{ lang, switchLanguage }}>
      {props.children}
    </LangContext.Provider>
  );
};

export { LangContextProvider };
export default LangContext;
