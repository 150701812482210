import React, { useEffect, useMemo, useState, useContext, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDevice,
  openDevice,
  setError,
} from "../../redux/CurrentDevice/currentDeviceSlice";

import ThemeContext from "../../context/theme-context";

import styles from "./sideBar.module.scss";

import Header from "./Header/Header";
import AQIChart from "./AQIChart/AQIChart";
import Main from "./Main/Main";
import Loader from "react-loader-spinner";
import { icons } from "../../assets/appIcons";
import LangContext from "../../context/lang-context";
import { translator } from "../../utils/translator";
import DataReliability from "./DataReliability/DataReliability";
import { CSSTransition } from "react-transition-group";
import './sidebar.transition.css';
//import { sortAlphaNum } from "../../functions/functions";

const updateDeviceDataSeconds = 30;

const SideBar = ({mapIdx, map}) => {
  const dispatch = useDispatch();

  const langCtx = useContext(LangContext);
  const { lang } = langCtx;

  const [categories, setCategories] = useState([]);
  //const [status, setStatus] = useState({ online: true });
  const [error, setError] = useState(false);

  const { device } = useSelector((state) => state.currentDevice);
  const [ isOnline, setOnlineStatus ] = useState(device.onlineStatus.isOnline); 
  const [ lastSubmission, setLastSubmission ] = useState(device.onlineStatus.lastSubmission);
  const { token, indexes } = device;
  const [loaderMsg, setLoaderMsg] = useState('');
  
  const ctx = useContext(ThemeContext);
  let { isDarkTheme } = ctx;

  const [showSideBar, setShowSideBar] = useState(true);
  const nodeRef = useRef(null);

  //NOT Updating channels!!!!

  const fetchDeviceData = useCallback(async () => {
    try{
      let { deviceURL } = device;
      //console.log("device: ", device);
      let deviceLangURL = deviceURL + '?lang=' + lang;
      // console.log("categories: ", deviceLangURL);
      const res = await fetch(deviceLangURL);

      if (!res.ok) {
        console.log('SideBar err: ', res);
        setError(true);
        if(res.status === 429){ // too many requests -> blocked
          setLoaderMsg(translator.textMessages[lang].error_429);
          setOnlineStatus(false);
          throw new Error(translator.textMessages[lang].error_429);
        } else if(res.status === 204){ // no content
          setLoaderMsg(translator.textMessages[lang].noData);
          setOnlineStatus(false);
          throw new Error(translator.textMessages[lang].noData);
        } else {
          setLoaderMsg(translator.textMessages[lang].deviceOffline);
          setOnlineStatus(false);
          throw new Error(translator.textMessages[lang].somethingWrong);          
        }
      }

      const deviceData = await res.json();
      const { data, status } = deviceData;      
      //console.log('Dev Status: ', status);
      if(status === undefined){
        setOnlineStatus(false);
        setLoaderMsg(translator.textMessages[lang].deviceOffline);
        throw new Error(translator.textMessages[lang].somethingWrong);
      }
      setOnlineStatus(status.online);
      setLastSubmission(status.lastSubmission);
      /*
      if(!status.online && !data.length){        
        setLoaderMsg(translator.textMessages[lang].deviceOffline);
      }
      */

      //console.log('SideBar Status: ', status);
      /*
      let catData = data.filter((d) => {
        return d.uiCategories === device.uiCategories;
      })
      */
      setCategories(data);
      //setStatus(status);
      setError(false);
    } catch(err){
      console.log('fetchDeviceData Error: ', err);
      //alert(err);
      setError(true);
    }
  }, [lang, device]);

  useEffect(() => {
    let isSubscribed = true;
    fetchDeviceData().catch((e) => {
      if(isSubscribed){
        setError(true);
      }
    });

    if(isSubscribed){
      const updateDeviceDataIntervalID = setInterval(() => {
        if(document.visibilityState === 'visible'){
          fetchDeviceData().catch((e) => setError(true));
        }
      }, 1000 * updateDeviceDataSeconds);
      return () => {
        clearInterval(updateDeviceDataIntervalID);
      };
    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
  }, [fetchDeviceData, lang, device]);

  const [headerKey, setHeaderKey] = useState(Math.random().toString());

  const updateHeaderKey = () => {
    setHeaderKey(Math.random().toString());
    //console.log('headerKey', headerKey);
  };

  const header = useMemo(() => {
    return (
      <Header
        key={headerKey}
        indexes={indexes}
        updateHeader={updateHeaderKey}
        device={device}
        lang={lang}
        isDarkTheme={isDarkTheme}
        mapIdx={mapIdx}
        map={map}
      />
    );
  }, [headerKey, indexes, device, lang, isDarkTheme]);

  const airQualityIndexChart = useMemo(() => {
    return (
      <AQIChart device={device} source={device.dataSource} token={token} indexes={indexes} lang={lang} />
    );
  }, [token, device.dataSource, indexes, lang]);

  const dataReliability = useMemo(() => {
    return (
      <DataReliability device={device} lang={lang} isDarkTheme={isDarkTheme} />
    );
  }, [device, lang, isDarkTheme, indexes]);

  function closeSideBar() {
    setShowSideBar(false);
      setTimeout( () => {
        dispatch(closeDevice());
      }, 280 );
      // change title and meta header tags      
      let titleTag = document.getElementsByTagName("title");
      let ogTagUrl = document.querySelector('[property="og:url"]');
      let descTag = document.querySelector('[name="description"]');
      let keyWordTag = document.querySelector('[name="keywords"]');
      let ogTagTitle = document.querySelector('[property="og:title"]');
      ogTagUrl.content = "https://senstate.com/map"; 
      switch(device.uiCategories){
        case '2':
          titleTag[0].innerHTML = "Senstate Water Level and Quality Live Map";
          ogTagTitle.content = "Senstate Water Level and Quality Live Map";
          ogTagUrl.content = "https://senstate.com/map";
          descTag.content = 'Check the water level and quality in your area. Browse environmental sensory data on the map';      
          keyWordTag.content = 'water quality, water level,live,map,water,quality index,caqi,eaqi,european,american,asian';
          break;
        case '3':
          titleTag[0].innerHTML = "Senstate Ambient Radiation Doses Live Map";
          ogTagTitle.content = "Senstate Ambient Radiation Doses Live Map";
          ogTagUrl.content = "https://senstate.com/map";
          descTag.content = 'Check the ambient radiation doses in your area. Browse environmental sensory data on the map';      
          keyWordTag.content = 'radiation,radiation doses,live,map,ambient,quality index,caqi,eaqi,european,american,asian';
          break;
        case '4':
          titleTag[0].innerHTML = "Senstate Background Noise Levels Live Map";
          ogTagTitle.content = "Senstate Background Noise Levels Live Map";
          ogTagUrl.content = "https://senstate.com/map";
          descTag.content = 'Check the background noise levels in your area. Browse environmental sensory data on the map';      
          keyWordTag.content = 'noise,background,level,live,map,water,quality index,caqi,eaqi,european,american,asian';
          break;
        default:
          titleTag[0].innerHTML = "Senstate Air Quality and Environmental Live Map";        
          ogTagTitle.content = "Senstate Air Quality and Environmental Live Map";               
          descTag.content = 'Check the air quality and air pollution in your area. Browse environmental sensory data on the map';        
          keyWordTag.content = 'air quality,air pollution,live,map,environment,quality index,caqi,eaqi,european';
          break;
      }
  }

  return (

    <CSSTransition
      in={showSideBar}
      key="sidebar-group"
      nodeRef={nodeRef}
      timeout={200}
      unmountOnExit
      appear
      enter={false}
      exit={true}
      classNames="sidebarCsstransition"
    >
    <div className={isDarkTheme ? styles.container_dark : styles.container}  
      ref={nodeRef}
    >
      <div
        className={`${styles.closeBtnContainer} ${
          isDarkTheme && !isOnline && styles.closeBtnContainerDarkTheme
        }`}
      >
        <button onClick={() => closeSideBar()}>{icons.close}</button>
      </div>

      {(error && !isOnline) && (
        <div className={styles.errorContainer}>
          <Loader
            type="Rings"
            color={isDarkTheme ? "white" : "#16123f"}
            height={100}
            width={100}
          />
            { /*<p>{translator.textMessages[lang].deviceOffline}</p> */ }
            {<p>{loaderMsg}</p>}
        </div>
      )}

      {!error && (
        <div>
          {header}
          {indexes.length > 0 && device.uiCategories === "1" && airQualityIndexChart}
          {dataReliability}
          {categories.length && (
            <Main 
              token={token} 
              categories={categories} 
              lastSubmition={lastSubmission} 
              isOnline={isOnline}
              limits={device.limits} 
              uiCategories={device.uiCategories}
              isDarkTheme={isDarkTheme}
              lang={lang} />
          )}
        </div>
      )}
    </div>
    </CSSTransition>
  );
};

export default SideBar;
