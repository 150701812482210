import { createSlice } from "@reduxjs/toolkit";

const filters = {
  1: "air",
  2: "water",
  3: "radiation",
  4: "noise"
};

const initialState = {
  selectedFilter: 1,
  filterName: filters[1],
};

export const currentFilterSlice = createSlice({
  name: "currentFilter",
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      //const currFilter = state.payload;
      // console.log("currFilter: ", initialState);

      state.selectedFilter = action.payload;
      state.filterName = filters[action.payload];
    },
    getSelectedFilter: (state) => {
      return state;
    }
  },
});

export const { setSelectedFilter, getSelectedFilter } = currentFilterSlice.actions;

export default currentFilterSlice.reducer;
