export const mapConfig = {
  0: {
    url: "https://api.maptiler.com/maps/basic/{z}/{x}/{y}@2x.png?key=a2uJClaYtUvdcFdoXNJZ",
    attrib: "© <a target='_blank' href='https://www.maptiler.com/copyright/'>MapTiler</a> © <a target='_blank' href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
  },
  1: {
    url: "",
    attrib: "",
    active: false,
    day_style: 'mapbox://styles/senstate/ckxvm3p0x0bsg14l5qb29xrw1',    
    night_style: 'mapbox://styles/senstate/ckxvm2cwy0bpp15t7767izh3g',
    token: 'pk.eyJ1Ijoic2Vuc3RhdGUiLCJhIjoiY2w0ejl6NzhiMDVnNzNqcDY1b281OHJ6MSJ9.S2XxS4phw--V7tuINJFOTw',
    apiToken: 'kjdfsjhg%$#^%$&dfjgjgdakdjdksj#'
  },
  updateMapFrequencySeconds: 300,
  active: 1
};