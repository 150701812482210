
/**
 * Sort Alphanumeric
 * arr - array to sort (required)
 * lang - locale string (Example: "bg", "en", ...)
 * arraProp - property of array to comapare (Example: arr.name) - default: undfined
 * returns - empty array if arr not given or empty, epty array if arr has propery and arrProp is not defined 
 * or sorted array
 */
const sortAlphaNum = (arr, lang, arrProp) => {
  if(arr === undefined || !arr.length){
    return [];
  }
  if(typeof lang === 'undefined'){
    lang = "en";
  }
  //debugger;
  let newArr = typeof arrProp === 'undefined' ? 
                arr.sort((a, b) => Object.keys(a).length ? arr : a.localeCompare(b, lang, { numeric: true })) :
                arr.sort((a, b) => typeof a[arrProp] === 'undefined' ? arr : a[arrProp].localeCompare(b[arrProp], lang, { numeric: true }));
  return newArr;
};

/**
 * 
 * @param {*} arr 
 * @param {*} lang 
 * @param {*} arrProp 
 * @returns Sorted descendens array by given arrProp
 */
const sortAlphaNumDesc = (arr, lang, arrProp) => {
  if(arr === undefined || !arr.length){
    return [];
  }
  const strDescending = [...arr].sort((a, b) =>
    a.arrProp > b.arrProp ? -1 : 1,
  );
  return strDescending;
};

/**
 * 
 * @param {categories array} arr 
 * @param {uiCategory type} uiType 
 * @returns 
 */
const addSortToCategories = (arr, uiType) => {
  if(arr[0].sort) return arr; // if categories has sort property - return
  arr.forEach(el => {
    switch(uiType){
      case '1':
        if(el.type === "Particulates"){
          el['sort'] = 1;
        }
        if(el.type === "Weather"){
          el['sort'] = 2;
        }
        if(el.type === "Gases"){
          el['sort'] = 3
        }
        break;
      case '2':
        if(el.type === "General"){
          el['sort'] = 1;
        }
        if(el.type === "WaterQuality"){
          el['sort'] = 2;
        }
        break;
      case '3':
        if(el.type === "General"){
          el['sort'] = 1;
        }
        if(el.type === "Radiation"){
          el['sort'] = 2;
        }
        break;
      case '4':
        if(el.type === "General"){
          el['sort'] = 1;
        }
        if(el.type === "Noise"){
          el['sort'] = 2;
        }
        break;
      default:
    }
  });
  return arr;
}


/**
 * adds sort key value to channels
 * @param {*} arr channels array
 * @param {*} catType category
 * @returns 
 */
const addSortToChannel = (arr, catType) => {
  if(arr[0].sort) return arr;
  arr.forEach((el, idx) => {
    switch(catType){
      case 'Particulates':
        if(el.type === 'ParticleSize'){
          el['sort'] = -1;
        }
        if(el.type === 'PM10'){
          el['sort'] = 1;
        }
        if(el.type === 'PM25'){
          el['sort'] = 2;
        }
        if(el.type === 'PM40'){
          el['sort'] = 3;
        }
        if(el.type === 'PM100'){
          el['sort'] = 4;
        }
      break;
      case 'Weather':
        if(el.type === 'Temperature'){
          el['sort'] = 1;
        }
        if(el.type === 'Pressure'){
          el['sort'] = 2;
        }
        if(el.type === 'Humidity'){
          el['sort'] = 3;
        }
        if(el.type === 'WindSpeed'){
          el['sort'] = 4;
        }
        if(el.type === 'WindDirection'){
          el['sort'] = 5;
        }
        break;
      case 'Gases':
        if(el.type === 'CO'){
          el['sort'] = 1;
        }
        if(el.type === 'NO2'){
          el['sort'] = 2;
        }
        if(el.type === 'SO2'){
          el['sort'] = 3;
        }
        if(el.type === 'O3'){
          el['sort'] = 4;
        }
        break;
      case "General": // for Water, Radiation and Noise
        if(el.type === "Depth"){ // Water
          el['sort'] = 1;
        }
        if(el.type === "Radiation"){ // radiation
          el['sort'] = 1;
        }
        if(el.type === "SoundAvg"){ // noise
          el['sort'] = 1;
        }
        if(el.type === "SoundMin"){ // noise
          el['sort'] = 2
        }
        if(el.type === "SoundMax"){ // noise
          el['sort'] = 3;
        }
        break;
      case "WaterQuality":
        el['sort'] = idx;
        break;
      
      default:
        el['sort'] = idx;
    }
  });
  return arr;
};


/**
 * 
 * @param {*} arr array to sort
 * @param {*} key array object key, which is used to sort the array
 * @param {*} direction ascendens or descendens, default - asc
 * @returns 
 */
const sortArrByNum = (arr, key, direction = 'asc') => {
  if(arr === undefined) return arr;
  let newArr = [];
  if(direction === 'asc'){
    newArr = arr.sort((a,b)=> a[key] - b[key]);
  } else {
    newArr = arr.sort((a,b)=> b[key] - a[key]);
  }
  return newArr;
}

/**
 * Parses number with comma (,) for thousend separator and dot (.) for decimal
 * @param {*} strg - string with number
 * @returns float value with dot (.)
 */
const parseNumberWithThousendComma = (string) => {
  let strg = string || "";
  let decimal = '.';
  strg = strg.replace(/[^0-9$.,]/g, '');
  if(strg.indexOf(',') > strg.indexOf('.')) decimal = ',';
  if((strg.match(new RegExp("\\" + decimal,"g")) || []).length > 1) decimal="";
  if (decimal !== "" && (strg.length - strg.indexOf(decimal) - 1 === 3) && strg.indexOf("0" + decimal)!==0) decimal = "";
  strg = strg.replace(new RegExp("[^0-9$" + decimal + "]","g"), "");
  strg = strg.replace(',', '.');
  return parseFloat(strg);
}

/**
 * Returns Object { isShortBar: for color bar - true or false, percent: percentage of level of the water }
 * @param {*} value - Value of deep level
 * @param {*} limitHigh - assigned high risk level
 * @param {*} limitLow - assigned low level, if not: default = 0
 * @param {*} hasRisk - true if level under or over limits
 * @returns 
 */
const calcWaterLevel = (value, limitHigh, limitLow = null, hasRisk = false) =>{
  
  // value = 300; //for test

  let isShort = limitLow === null ? false : true;
  if(limitLow !== null){
    let middlePoint = (limitHigh - limitLow) / 2 + limitLow;
    //let percent = ((value - (!hasRisk ? limitLow: 0)) / (limitHigh - (!hasRisk ? limitLow: 0))) * 100;
    //let percent = parseFloat(((((value-(!hasRisk ? limitLow: 0))/2)+(!hasRisk ? limitLow: 0))/((limitHigh-((!hasRisk ? limitLow: 0)))/2)) * 100);
    //let percent = parseFloat(((((value-limitLow)/2)+limitLow)/((limitHigh-limitLow)/2)) * 100);
    //let percentTooltip = ((value - (!hasRisk ? limitLow: 0)) / ((limitHigh - (!hasRisk ? limitLow: 0))/2)) * 100;
    let percent = parseFloat(((value - limitLow) / ((limitHigh - limitLow))) * 100);
    let percentTooltip = ((value - (((limitHigh - limitLow)/2) + limitLow)) / ((limitHigh - limitLow)/2)) * 100;
    let backColor = "#7abc6b";    
    
    if(value < middlePoint){       
      percentTooltip *= (-1);
      if(percentTooltip > 25 && percentTooltip <= 50){
        backColor = "#bbcf4c"
      } else if (percentTooltip > 50 && percentTooltip <= 75){
        backColor = "#eec20e";
      } else if(percentTooltip > 75 && percentTooltip <= 99){
        backColor = "#f29305";
      } else if(percentTooltip > 99){
        backColor = "#e8416f";
      }
      if(percent <= 0){
        percent = 1;
      }
      return {
        isShortBar: isShort,
        percent: percent.toFixed(2) + "%",
        tooltip: parseFloat(percentTooltip).toFixed(2) + "%",
        levelIndicator: "limitLow", 
        isValueOver: value < limitLow ? true : false,
        backColor: backColor,   
      }
    } else if(value > middlePoint){      
      //percentTooltip = percentTooltip - 100;
      if(percentTooltip > 25 && percentTooltip <= 50){
        backColor = "#bbcf4c"
      } else if (percentTooltip > 50 && percentTooltip <= 75){
        backColor = "#eec20e";
      } else if(percentTooltip > 75 && percentTooltip <= 99){
        backColor = "#f29305";
      } else if(percentTooltip > 99){
        backColor = "#e8416f";
      }
      if(percent >= 100){
        percent = 99;
      }
      return {
        isShortBar: isShort,
        percent: percent.toFixed(2) + "%",
        tooltip: percentTooltip.toFixed(2) + "%",
        levelIndicator: "limitHigh",
        isValueOver: value > limitHigh ? true : false,
        backColor: backColor, 
      }
    } else { // value at the middle (0)
      return {
        isShortBar: isShort,
        percent: parseFloat(50).toFixed(2) + "%",
        tooltip: parseFloat(0).toFixed(2) + "%",
        levelIndicator: "limitZero",
        isValueOver: false,
        backColor: backColor, 
      }
    }
  }

  let percent = ((value - limitLow) / (limitHigh - limitLow)) * 100;
  let percentTooltip = percent;
  if(percent <= 0){
    percent = 1;
  }
  if(percent >= 100){
    percent = 99;
  }
  let backColor = "#7abc6b";
  if(percentTooltip > 25 && percentTooltip <= 50){
    backColor = "#bbcf4c"
  } else if (percentTooltip > 50 && percentTooltip <= 75){
    backColor = "#eec20e";
  } else if(percentTooltip > 75 && percentTooltip <= 99){
    backColor = "#f29305";
  } else if(percentTooltip > 99){
    backColor = "#e8416f";
  }
  return {
    isShortBar: isShort,
    percent: parseFloat(percent).toFixed(2) + "%",
    tooltip: parseFloat(percentTooltip).toFixed(2) + "%",
    levelIndicator: "limitHigh",
    isValueOver: value < 0 || value > limitHigh ? true : false,
    backColor: backColor, 
  }
  // Formula: ((value - low)/(high - low) * 100) 
  
}

/**
 * Returns channels depending of categorie
 * @param {*} uiCategories - device uiCategories
 * @param {*} arr - channels array
 * @returns new filtered array
 */
const categoriesByFilter = (uiCategories, arr) => {
  if(typeof uiCategories === "string"){
    uiCategories = parseInt(uiCategories);
  }
  let catArr = {
    1: ["Weather", "Particulates", "Gases"],
    2: ["WaterQuality", "General"],
    3: ["Radiation", "General"],
    4: ["Noise", "General"],
  }
  let filter = catArr[uiCategories];
  let newArr = arr.filter((a) => {
    return filter.includes(a.type);
  });
  return newArr;
}

/**
 * Returns Array corresponding to uiCategories
 * @param {*} uiCategories 
 * @returns string Array of categories
 */
const channelsByFilter = (uiCategories) => {
  if(typeof uiCategories === "string"){
    uiCategories = parseInt(uiCategories);
  }
  let filteredChannels = {
    1: ["Temperature", "Humidity", "Pressure", "WindSpeed", "WindDirection", "IAQ", "CO2", "bVOC", "PM10", "PM25", "PM40", "PM100", "VOC", "ParticleSize", "NO2", "O3", "CO", "SO2", "HCHO"],
    2: ["Depth", "Distance", "Do", "Tur", "CT", "pH", "wTemp", "Orp", "Chl", "Bga", "Blue Green Algae", "Chlorophyll", "Conductivity", "Dissolved Oxygen", "Turbidity", "Water Temperature", "ORP"],
    3: ["Radiation"],
    4: ["SoundMax", "SoundAvg", "SoundMin"]
  };
  return filteredChannels[uiCategories];
}

/**
 * Gets Data sources from server
 * @param { url to get the data from} url 
 * @returns 
 */
const getDataTableDataFromUrl = async (url, lang) => {
 // const fetchData = async () => {
    try {
      const response = await fetch(url);
      if(response.status === 429){
        return [{id: 1, name: lang === 'en' ? 'Server rejected request' : 'Сървърът отхвърли заявката', acronym: 'err', url: null}]
      }
      if(response.status === 400){
        return [{id: 1, name: lang === 'en' ? 'Wrong url' : 'Грешен url', acronym: 'err', url: null}]
      }
      const json = await response.json();
      console.log('Translate: ', json.dataSources);
      return json;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  //};
}

const selectedDeviceType = (val) => {
  const dict = {
    1: "air",
    2: "water",
    3: "radiation",
    4: "noise"
  };
  return dict[val];
}

export { sortAlphaNum, sortAlphaNumDesc, parseNumberWithThousendComma, calcWaterLevel, categoriesByFilter, 
  channelsByFilter, addSortToCategories, addSortToChannel, sortArrByNum, getDataTableDataFromUrl, selectedDeviceType };