//import { L, Point } from "leaflet";
import React from "react";
import { Circle, Rectangle, LayerGroup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { openDevice } from "../../redux/CurrentDevice/currentDeviceSlice";
import { closeAboutFaq } from "../../redux/AboutFaq/aboutFaqSlice";
import { isDataRecent } from "../../utils/utils";
//import { translator } from "../../utils/translator";

import "./pins.css";

const getPinColor = (indexValue, uiCat) => {
  let color = "";
  if(uiCat !== "2"){
    if (indexValue <= 25) {
      color = "#79BC6A";
    } else if (indexValue <= 50) {
      color = "#BBCF4C";
    } else if (indexValue <= 75) {
      color = "#EEC20B";
    } else if (indexValue <= 100) {
      color = "#F29305";
    } else {
      color = "#E8416F";
    }
  } else {
    switch(indexValue){
      case 0:
        color = "#79bc6a";
        break;
      case 1:
        color = "#bbcf4c";
        break;
        case 2:
          color = "#eec20b";
          break;
        case 3:
          color = "#f29305";
          break;
        case 4:
          color = "#e8416f";
          break;

      default:
    }
  }

  return color;
};

//const updateDeviceIndexMinutes = 10;
//const markOfflineTreshold = 3;
//const recentDataLimitMinutes = updateDeviceIndexMinutes * markOfflineTreshold;

const Pin = ({ device, id, zoomLevel, getUrlDevice/*, lang, onChange, onChangeFaqAbout*/ }) => {
  var openedDeviceToken = useSelector(
    (state) => getUrlDevice !== '' ? getUrlDevice : state.currentDevice.device.token
  );

  const dispatch = useDispatch();
  const { token, indexes, location } = device;

  const { latitude, longtitude, radius } = location;

  const { isOnline } = device.onlineStatus;

  //const isCurrDataRecent = isDataRecent(lastSubmission, recentDataLimitMinutes);
  //console.log('location, isCurrDataRecent: ', location.address, isCurrDataRecent);

  const onDeviceOpen = () => {
    if (openedDeviceToken !== token) {
      dispatch(openDevice({ device }));
      dispatch(closeAboutFaq());
    }
  };

  let getAllSvg = (latitude, longtitude, pinColor, radius, zoom) => {
    if(zoom < 14){
      radius = 10000 / zoom;
    }
    if(zoom < 13){
      radius = 20000 / zoom;
    }
    if(zoom < 11){
      radius = 60000 / zoom;
    }
    if(zoom < 9){
      radius = 150000 / zoom;
    }
    if(zoom < 6){
      radius = 600000 / zoom;
    }
    return (
    <>
    <Circle
      id={id}
      center={[latitude, longtitude]}
      pathOptions={{
        fillColor: pinColor,
        fillOpacity: 0.2,
      }}
      radius={radius}
      stroke={false}
      eventHandlers={{
        //click: onDeviceOpen(),
        //mouseover: () => {console.log('C1 mouseover')},
        add: (p) => {
          p.target.bringToBack()
        },
      }}
      zIndexOffset="200"
    />
    <Circle
      center={[latitude, longtitude]}
      pathOptions={{ fillColor: pinColor, fillOpacity: 0.4 }}
      radius={0.8 * radius}
      stroke={false}
      eventHandlers={{
        //click: onDeviceOpen,
        //mouseover: () => {console.log('C2 mouseover')},
        add: (p) => {
          p.target.bringToBack()
        },
      }}
      zIndexOffset="200"
    />
    <Circle
      center={[latitude, longtitude]}
      pathOptions={{ fillColor: pinColor, fillOpacity: 0.6 }}
      radius={0.6 * radius}
      stroke={false}
      eventHandlers={{
        //click: onDeviceOpen,
        //mouseover: () => {console.log('C3 mouseover')}
        add: (p) => {
          p.target.bringToBack()
        },
      }}
      zIndexOffset="300"
    />
    {/*
    <Circle
      center={[latitude, longtitude]}
      pathOptions={{
        color: isDeviceOpen ? "#16123F" : pinColor,
        fillColor: isDeviceOpen ? "#16123F" : pinColor,
        fillOpacity: 1,
      }}
      radius={0.4 * radius}
      stroke={true}
      eventHandlers={{
        click: onDeviceOpen,
        mouseover: () => {console.log('C4 mouseover')}
      }}
    />
    */}
    </>);
  }

  /*
  const fetchDeviceData = async () => {
    try{
      if(device.indexes.length > 0){
        let { deviceURL } = device.dataEndpoint;
        let deviceLangURL = deviceURL.replace('{Token}', token) + '?lang=' + lang;
        const res = await fetch(deviceLangURL);

        if (!res.ok) {
          //throw new Error(translator.textMessages[lang].somethingWrong);
          return false;
        }

        const deviceData = await res.json();
        const { status } = deviceData;
        return status.online;
      } else {
        return false;
      }

    } catch(err){
      console.log('Pin fetchDeviceData', err);
      return false;
    }
  };
  */

  let pinColor = "#979997";
  //const isOnline  = fetchDeviceData();  
  if (indexes.length > 0 || typeof(device.riskLevel) !== "undefined") {
    let pinValue = 0;
    if(indexes.length){
      let index = indexes.find((index) => index.slug === "sbaqi");
      if (!index) {
        index = indexes[0];
      }
      pinValue = index.value;
    }

    if(typeof(device.riskLevel.level) !== "undefined" && device.riskLevel.isAvailable) {
      pinValue = device.riskLevel.level;
    }

    //const { value } = index;

    //const isCurrDataRecent = isDataRecent(lastSubmission, recentDataLimitMinutes);

    if (isOnline) {
      pinColor = getPinColor(pinValue, device.uiCategories);      
    } else {
      //pinColor = "#16123F"
    }
  } else {
    pinColor = "#16123F"
  }

  /*
  if(!isCurrDataRecent){
    pinColor = "#979997";
  }
  */

  //const exLatLng = [[latitude - 0.02, longtitude - 0.03], [latitude + 0.02, longtitude + 0.03]];

  /*
  const polygon1 = [
    [
      [latitude - 0.0015, longtitude],
      [latitude - 0.0015, longtitude],
      [latitude - 0.00088, longtitude - 0.0009],
      [latitude - 0.0009, longtitude + 0.0009],
    ],
  ];

  const polygon2 = [
    [
      [latitude - 0.0025, longtitude],
      [latitude - 0.0014, longtitude - 0.0001],
      [latitude - 0.0003, longtitude + 0.00126],
    ],
  ];

  const polygon3 = [
    [
      [latitude - 0.0025, longtitude],
      [latitude - 0.0014, longtitude - 0.00012],
      [latitude - 0.0003, longtitude - 0.00126],
    ],
  ];
  */

  const handleMouseOver = (evt) => {
    /*
    let popupContent = 'Test';
    evt.target.bindPopup(popupContent);
    evt.target.openPopup(evt.target.latLng);
    */
  }

  const handleMouseOut = (evt) => {
    /*
    evt.target.closePopup();
    */   
  }

  const isDeviceOpen = getUrlDevice !== '' ? getUrlDevice === token : openedDeviceToken === token;

  
  if(getUrlDevice !== ''){
    setTimeout(() => {
      openedDeviceToken = getUrlDevice;
      dispatch(openDevice({ device }));
    }, 4000);
  }

  let multiplier = 0.3;
  let latMultiplier = 0;
  let lonMultiplier = 0;
  switch(zoomLevel){
    case 0:
    case 1:
    case 2:
      multiplier = 300;
      latMultiplier = 0.06;
      lonMultiplier = 0.06;
      break;
    case 3:
      multiplier = 200;
      latMultiplier = 0.3;
      lonMultiplier = 0.4;
      break;
    case 4:
      multiplier = 120;
      latMultiplier = 0.2;
      lonMultiplier = 0.25;
      break;
    case 5:
      multiplier = 60;
      latMultiplier = 0.125;
      lonMultiplier = 0.15;
      break;      
    case 6:
      multiplier = 40;
      latMultiplier = 0.06;
      lonMultiplier = 0.075;
      break;
    case 7:
      multiplier = 20;
       latMultiplier = 0.02;
       lonMultiplier = 0.02;
      break;
    case 8:
      multiplier = 10;
      latMultiplier = 0.0;
      break;
    case 9:
      multiplier = 6;
      latMultiplier = -0.007;
      lonMultiplier = -0.0105;
      break;
    case 10:
      multiplier = 3;
      latMultiplier = -0.014;
      lonMultiplier = -0.02;
      break;
    case 11:
      multiplier = 1.5;
      latMultiplier = -0.017;
      lonMultiplier = -0.0232;
      break;
    case 12:
      multiplier = 1;
      latMultiplier = -0.0178;
      lonMultiplier = -0.0245;
      break;
    case 13:
      multiplier = 0.5;
      latMultiplier = -0.021;
      lonMultiplier = -0.0289;
      break;
    default:
      multiplier = 0.3;
      latMultiplier = -0.0207;
      lonMultiplier = -0.0265;

  }
  console.log('zoom, radius, lat, lot, isOnline', zoomLevel, radius, latMultiplier, lonMultiplier, isOnline);

  const offlinePin = (
    <>
      <Rectangle
        bounds={[[(latitude - 0.02) - latMultiplier, (longtitude - 0.0275) - lonMultiplier], 
                  [(latitude + 0.02) + latMultiplier, (longtitude + 0.0275) + lonMultiplier]]}
        //color={pinColor}
        color="#979997"
        weight={1}
        fillColor="#979997"
        fillOpacity={0.9}
        eventHandlers={{
          click: onDeviceOpen,
          //add: (ev) => console.log("add: ", ev.target.options),
        }}
        zIndexOffset="700"
        ></Rectangle>
    </>
  );

  if(!indexes.length && device.uiCategories === "1") {
    pinColor = "#444444";//"#16133F";
  }

  const onlinePin = (
    <>
      <Circle
        id={id}
        center={[latitude, longtitude]}
        pathOptions={{
          fillColor: pinColor,
          fillOpacity: isDeviceOpen ? 1 : .9,
          color: /*isExternal ? '#FFFFFF' :*/ pinColor
        }}
        radius={radius * multiplier}
        stroke={false}
        //stroke={isExternal}
        className="senstate-point"
        eventHandlers={{
          click: onDeviceOpen,
          //mouseover: (e) => {  
            /*
            e.target.bindTooltip(          
                `<p>${translator.pinTexts[lang].category}: ${device.category}</p>
                <p>${translator.pinTexts[lang].custName}: ${device.customName}</p>
                <p>${translator.pinTexts[lang].country}: ${device.location.country}</p>
                <p>${translator.pinTexts[lang].city}: ${device.location.city}</p>
                <p>${translator.pinTexts[lang].address}: ${device.location.address}</p>
                <p>${translator.pinTexts[lang].coords}: ${device.location.latitude}, ${device.location.longtitude}</p>`,            
              {direction: "top",
              className: "device-circle-tooltip",
              offset: [0, -20]
            },
            )
            .openTooltip();
            */
          //},
          //mouseout: (e) => {
              //e.target.closeTooltip().unbindTooltip();
          //}
          mouseover: (e) => handleMouseOver(e),
          mouseout: (e) => handleMouseOut(e),
        }}
        zIndexOffset="600"
      >
        {/*
        <Tooltip 
          direction="top"
          className="device-circle-tooltip"
          offset={[0, -20]}
        >
          <p>{translator.pinTexts[lang].category}: {device.category}</p>
          <p>{translator.pinTexts[lang].custName}: {device.customName}</p>
          <p>{translator.pinTexts[lang].country}: {device.location.country}</p>
          <p>{translator.pinTexts[lang].city}: {device.location.city}</p>
          <p>{translator.pinTexts[lang].address}: {device.location.address}</p>
          <p>{translator.pinTexts[lang].coords}: {device.location.latitude}, {device.location.longtitude}</p>
        </Tooltip>
        */}
        {/*<Popup>Circle popup</Popup>*/}
      </Circle>
    </>
  );


  return (
    <div>
      <LayerGroup>        
        {
          isDeviceOpen && getAllSvg(latitude, longtitude, pinColor, radius, zoomLevel)
        }
        {isOnline ? onlinePin : offlinePin}
        {/*isDeviceOpen && (
          <>
            <Polygon
              positions={polygon1}
              pathOptions={{
                fillOpacity: "1",
                color: "#16123F",
                fillColor: "#16123F",
              }}
            />
            <Polygon
              positions={polygon2}
              pathOptions={{
                fillOpacity: "1",
                color: "#16123F",
                fillColor: "#16123F",
              }}
            />
            <Polygon
              positions={polygon3}
              pathOptions={{
                fillOpacity: "1",
                color: "#16123F",
                fillColor: "#16123F",
              }}
            />
          </>
            )*/}
      </LayerGroup>
    </div>
  );
};

export default Pin;



 


