/**
 * Senstate Component to get or set cookies
 * 
 * function getCookie
 * @param {*} cname - cookie name which we want to read(get)
 * @returns cookie value if exists, otherwise null
 */
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

/**
 * * Senstate Component to get or set cookies
 * 
 * function setCookie
 * @param {*} cName - name of the cookie we want to set
 * @param {*} cValue - value of the cookie we want to set
 * @param {*} expDays - (optional) days of cookie to be active / if undefined: 3650 (10 years)
 */
function setCookie(cName, cValue, expDays) {
  if(typeof expDays === 'undefined'){
    expDays = 3650;
  }
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/;SameSite=Lax";
}

export {getCookie, setCookie};