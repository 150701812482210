const noData = (
  <svg width="98" height="72" viewBox="0 0 98 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4961 4.30455L25.5437 15.9499L23.0239 16.5448C22.2649 16.7211 21.531 16.8784 20.8191 17.0295H20.7845C19.3293 17.3443 17.9498 17.6338 16.671 18.0461C13.1526 19.1436 9.93768 21.0433 7.28 23.5952C4.62233 26.1472 2.59468 29.2815 1.3571 32.7508C0.144358 36.1428 -0.263553 39.7698 0.16551 43.3462C0.594573 46.9226 1.84888 50.3505 3.82962 53.36C8.55418 60.6273 15.5245 64.313 24.5704 64.313H39.4087C49.8878 64.313 60.7102 64.3129 71.3499 64.2909C72.0312 64.2641 72.7102 64.385 73.3404 64.6452C73.9705 64.9054 74.5368 65.2989 75.0004 65.7985C76.6918 67.5705 78.4367 69.3236 80.125 71.0232L81.0951 71.9957L84.3204 68.6879L16.7056 1.12253L13.4961 4.30455ZM28.7438 20.2745C29.5186 20.3028 31.8148 22.72 32.5864 23.488C42.1112 32.9553 51.6894 42.536 60.9527 51.8146L64.5434 55.4058C64.6757 55.538 64.7985 55.6765 64.9843 55.8873L65.3497 56.2996L67.2868 58.4588L64.389 58.6571L63.6677 58.7106C63.252 58.7452 62.9276 58.7735 62.578 58.7735H52.754H39.9158C33.9629 58.7735 29.0588 58.7735 24.4822 58.7515C16.0851 58.7231 9.24082 54.0587 6.62026 46.5773C5.65136 43.8604 5.34934 40.9506 5.73958 38.0928C6.12982 35.2349 7.20093 32.5124 8.86285 30.1542C12.1385 25.4551 17.6127 22.6728 23.0586 21.2911C23.5184 21.1746 28.2776 20.372 28.7281 20.2745H28.7438Z" fill="#16123F"/>
    <path d="M80.9944 25.3324C79.5361 25.0585 78.7203 24.3032 78.3455 22.89C74.0493 6.70289 59.0031 -2.64805 42.5711 0.663022C39.6619 1.20847 36.8829 2.30109 34.3818 3.88281L34.6055 4.10626C36.0039 5.50056 37.3237 6.81619 38.678 8.09404C47.2074 3.89225 55.5888 4.73259 63.5954 10.5931C70.1594 15.3991 73.4729 22.1315 73.4414 30.6011C73.4414 30.6735 73.4414 30.7365 73.4414 30.7868H73.5642C75.5524 30.7026 77.5436 30.7225 79.5298 30.8466C82.3854 31.0115 85.1158 32.0744 87.3305 33.8833C89.5452 35.6923 91.1309 38.1546 91.8609 40.9183C92.6381 43.6162 92.5673 46.4876 91.6581 49.144C90.7489 51.8004 89.0451 54.1137 86.7772 55.7709L90.8246 59.9192C95.7067 56.2902 98.8217 48.5885 97.8012 42.4983C96.2453 33.182 90.286 27.0855 80.9944 25.3324Z" fill="#16123F"/>
  </svg>

);

const noDataCircle = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#B8B8B8"/>
  </svg>
);

const clock = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2068 10.5895L9.69709 8.70719V4.87298C9.69709 4.48745 9.38547 4.17583 8.99994 4.17583C8.61442 4.17583 8.3028 4.48745 8.3028 4.87298V9.0558C8.3028 9.27538 8.40598 9.48245 8.58165 9.61351L11.3702 11.7049C11.4957 11.799 11.6421 11.8444 11.7878 11.8444C12.0004 11.8444 12.2095 11.7488 12.3462 11.5648C12.5777 11.2573 12.5149 10.8202 12.2068 10.5895Z"
      fill="white"
    />
    <path
      d="M9 0C4.0371 0 0 4.0371 0 9C0 13.9629 4.0371 18 9 18C13.9629 18 18 13.9629 18 9C18 4.0371 13.9629 0 9 0ZM9 16.6057C4.80674 16.6057 1.39426 13.1933 1.39426 9C1.39426 4.80674 4.80674 1.39426 9 1.39426C13.194 1.39426 16.6057 4.80674 16.6057 9C16.6057 13.1933 13.1933 16.6057 9 16.6057Z"
      fill="white"
    />
  </svg>
);

const facebook = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 17.5C35 7.83398 27.166 0 17.5 0C7.83398 0 0 7.83398 0 17.5C0 27.166 7.83398 35 17.5 35C17.6025 35 17.7051 35 17.8076 34.9932V21.376H14.0479V16.9941H17.8076V13.7676C17.8076 10.0283 20.0908 7.99121 23.4268 7.99121C25.0264 7.99121 26.4004 8.10742 26.7969 8.16211V12.0723H24.5C22.6885 12.0723 22.333 12.9336 22.333 14.1982V16.9873H26.6738L26.1064 21.3691H22.333V34.3232C29.6475 32.2246 35 25.4912 35 17.5Z"
      fill="#16123F"
    />
  </svg>
);

const linkedin = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 0C7.83649 0 0 7.83649 0 17.5C0 27.1635 7.83649 35 17.5 35C27.1635 35 35 27.1635 35 17.5C35 7.83649 27.1635 0 17.5 0ZM12.4147 26.4551H8.15266V13.6326H12.4147V26.4551ZM10.2838 11.8817H10.256C8.82584 11.8817 7.90085 10.8972 7.90085 9.66671C7.90085 8.40847 8.85414 7.45117 10.3121 7.45117C11.7701 7.45117 12.6673 8.40847 12.6951 9.66671C12.6951 10.8972 11.7701 11.8817 10.2838 11.8817ZM27.7827 26.4551H23.5212V19.5954C23.5212 17.8714 22.9041 16.6957 21.362 16.6957C20.1847 16.6957 19.4835 17.4888 19.1753 18.2544C19.0627 18.5283 19.0351 18.9112 19.0351 19.2944V26.4551H14.7734C14.7734 26.4551 14.8292 14.8356 14.7734 13.6326H19.0351V15.4482C19.6015 14.5744 20.6149 13.3317 22.8761 13.3317C25.6802 13.3317 27.7827 15.1643 27.7827 19.1027V26.4551Z"
      fill="#16103F" />
  </svg>
);

const indexVeryLow = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5733 56.4302C64.7579 55.6998 63.505 55.7711 62.7787 56.5845C58.6501 61.198 52.6968 63.8422 46.4406 63.8422C40.1865 63.8422 34.2311 61.198 30.1026 56.5845C29.3723 55.7711 28.1234 55.6998 27.308 56.4302C26.4926 57.1585 26.4253 58.4093 27.1536 59.2247C32.0323 64.6754 39.0623 67.8005 46.4406 67.8005C53.821 67.8005 60.851 64.6734 65.7276 59.2247C66.456 58.4093 66.3867 57.1585 65.5733 56.4302Z"
      fill="#16123F"
    />
    <path
      d="M46.4408 0.508728C21.341 0.508728 0.919922 20.9298 0.919922 46.0296C0.919922 71.1295 21.341 91.5505 46.4408 91.5505C71.5407 91.5505 91.9617 71.1295 91.9617 46.0296C91.9617 20.9298 71.5407 0.508728 46.4408 0.508728ZM46.4408 87.5922C23.522 87.5922 4.87826 68.9484 4.87826 46.0296C4.87826 23.1108 23.522 4.46707 46.4408 4.46707C69.3596 4.46707 88.0034 23.1108 88.0034 46.0296C88.0034 68.9484 69.3596 87.5922 46.4408 87.5922Z"
      fill="#16123F"
    />
    <path
      d="M62.2743 32.1754C56.8177 32.1754 52.3784 36.6147 52.3784 42.0713C52.3784 43.1638 53.2651 44.0505 54.3576 44.0505C55.4501 44.0505 56.3368 43.1638 56.3368 42.0713C56.3368 38.7977 59.0007 36.1338 62.2743 36.1338C65.5478 36.1338 68.2118 38.7977 68.2118 42.0713C68.2118 43.1638 69.0984 44.0505 70.1909 44.0505C71.2835 44.0505 72.1701 43.1638 72.1701 42.0713C72.1701 36.6147 67.7308 32.1754 62.2743 32.1754Z"
      fill="#16123F"
    />
    <path
      d="M36.5448 42.0713C36.5448 43.1638 37.4314 44.0505 38.524 44.0505C39.6165 44.0505 40.5031 43.1638 40.5031 42.0713C40.5031 36.6147 36.0638 32.1754 30.6073 32.1754C25.1507 32.1754 20.7114 36.6147 20.7114 42.0713C20.7114 43.1638 21.5981 44.0505 22.6906 44.0505C23.7831 44.0505 24.6698 43.1638 24.6698 42.0713C24.6698 38.7977 27.3337 36.1338 30.6073 36.1338C33.8808 36.1338 36.5448 38.7977 36.5448 42.0713Z"
      fill="#16123F"
    />
  </svg>
);

const indexLow = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.52 0C20.4207 0 0 20.4207 0 45.52C0 70.6193 20.4207 91.04 45.52 91.04C70.6193 91.04 91.04 70.6193 91.04 45.52C91.04 20.4207 70.6193 0 45.52 0ZM45.52 87.0817C22.6017 87.0817 3.95826 68.4383 3.95826 45.52C3.95826 22.6017 22.6017 3.95826 45.52 3.95826C68.4383 3.95826 87.0817 22.6017 87.0817 45.52C87.0817 68.4383 68.4383 87.0817 45.52 87.0817Z"
      fill="#16123F"
    />
    <path
      d="M35.6244 37.6035C35.6244 34.33 32.9604 31.6661 29.687 31.6661C26.4135 31.6661 23.7496 34.33 23.7496 37.6035C23.7496 40.877 26.4135 43.5409 29.687 43.5409C32.9604 43.5409 35.6244 40.877 35.6244 37.6035ZM27.7078 37.6035C27.7078 36.513 28.5965 35.6243 29.687 35.6243C30.7775 35.6243 31.6661 36.513 31.6661 37.6035C31.6661 38.694 30.7775 39.5826 29.687 39.5826C28.5965 39.5826 27.7078 38.694 27.7078 37.6035Z"
      fill="#16123F"
    />
    <path
      d="M61.353 31.6661C58.0796 31.6661 55.4156 34.33 55.4156 37.6035C55.4156 40.877 58.0796 43.5409 61.353 43.5409C64.6265 43.5409 67.2904 40.877 67.2904 37.6035C67.2904 34.33 64.6265 31.6661 61.353 31.6661ZM61.353 39.5826C60.2625 39.5826 59.3739 38.694 59.3739 37.6035C59.3739 36.513 60.2625 35.6243 61.353 35.6243C62.4435 35.6243 63.3322 36.513 63.3322 37.6035C63.3322 38.694 62.4435 39.5826 61.353 39.5826Z"
      fill="#16123F"
    />
    <path
      d="M64.6523 55.9203C63.8369 55.19 62.5841 55.2613 61.8577 56.0747C57.7293 60.6881 51.776 63.3322 45.52 63.3322C39.266 63.3322 33.3108 60.6881 29.1823 56.0747C28.452 55.2613 27.2032 55.19 26.3878 55.9203C25.5724 56.6486 25.5051 57.8995 26.2334 58.7149C31.1119 64.1654 38.1418 67.2904 45.52 67.2904C52.9002 67.2904 59.9301 64.1634 64.8066 58.7149C65.535 57.8995 65.4657 56.6486 64.6523 55.9203Z"
      fill="#16123F"
    />
  </svg>
);

const indexMedium = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.52 0C20.4207 0 0 20.4207 0 45.52C0 70.6193 20.4207 91.04 45.52 91.04C70.6193 91.04 91.04 70.6193 91.04 45.52C91.04 20.4207 70.6193 0 45.52 0ZM45.52 87.0817C22.6017 87.0817 3.95826 68.4383 3.95826 45.52C3.95826 22.6017 22.6017 3.95826 45.52 3.95826C68.4383 3.95826 87.0817 22.6017 87.0817 45.52C87.0817 68.4383 68.4383 87.0817 45.52 87.0817Z"
      fill="#16123F"
    />
    <path
      d="M29.687 43.5409C32.9604 43.5409 35.6244 40.877 35.6244 37.6035C35.6244 34.33 32.9604 31.6661 29.687 31.6661C26.4135 31.6661 23.7496 34.33 23.7496 37.6035C23.7496 40.877 26.4135 43.5409 29.687 43.5409ZM29.687 35.6244C30.7775 35.6244 31.6661 36.513 31.6661 37.6035C31.6661 38.694 30.7775 39.5827 29.687 39.5827C28.5965 39.5827 27.7078 38.694 27.7078 37.6035C27.7078 36.513 28.5965 35.6244 29.687 35.6244Z"
      fill="#16123F"
    />
    <path
      d="M61.353 31.6661C58.0796 31.6661 55.4156 34.33 55.4156 37.6035C55.4156 40.877 58.0796 43.5409 61.353 43.5409C64.6265 43.5409 67.2904 40.877 67.2904 37.6035C67.2904 34.33 64.6265 31.6661 61.353 31.6661ZM61.353 39.5827C60.2625 39.5827 59.3739 38.694 59.3739 37.6035C59.3739 36.513 60.2625 35.6244 61.353 35.6244C62.4435 35.6244 63.3322 36.513 63.3322 37.6035C63.3322 38.694 62.4435 39.5827 61.353 39.5827Z"
      fill="#16123F"
    />
    <path
      d="M65.3113 55.4156H25.7287C24.6362 55.4156 23.7496 56.3023 23.7496 57.3948C23.7496 58.4873 24.6362 59.3739 25.7287 59.3739H65.3113C66.4038 59.3739 67.2904 58.4873 67.2904 57.3948C67.2904 56.3023 66.4038 55.4156 65.3113 55.4156Z"
      fill="#16123F"
    />
  </svg>
);

const indexHigh = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.52 0C20.4207 0 0 20.4207 0 45.52C0 70.6193 20.4207 91.04 45.52 91.04C70.6213 91.04 91.04 70.6193 91.04 45.52C91.04 20.4207 70.6213 0 45.52 0ZM45.52 87.0817C22.6017 87.0817 3.95826 68.4383 3.95826 45.52C3.95826 22.6017 22.6017 3.95826 45.52 3.95826C68.4364 3.95826 87.0817 22.6017 87.0817 45.52C87.0817 68.4383 68.4364 87.0817 45.52 87.0817Z"
      fill="#16123F"
    />
    <path
      d="M35.6243 37.6035C35.6243 34.33 32.9604 31.6661 29.6869 31.6661C26.4134 31.6661 23.7495 34.33 23.7495 37.6035C23.7495 40.877 26.4134 43.5409 29.6869 43.5409C32.9604 43.5409 35.6243 40.877 35.6243 37.6035ZM27.7078 37.6035C27.7078 36.513 28.5964 35.6243 29.6869 35.6243C30.7774 35.6243 31.666 36.513 31.666 37.6035C31.666 38.694 30.7774 39.5826 29.6869 39.5826C28.5964 39.5826 27.7078 38.694 27.7078 37.6035Z"
      fill="#16123F"
    />
    <path
      d="M61.353 31.6661C58.0796 31.6661 55.4156 34.33 55.4156 37.6035C55.4156 40.877 58.0796 43.5409 61.353 43.5409C64.6265 43.5409 67.2904 40.877 67.2904 37.6035C67.2904 34.33 64.6265 31.6661 61.353 31.6661ZM61.353 39.5826C60.2606 39.5826 59.3739 38.694 59.3739 37.6035C59.3739 36.513 60.2606 35.6243 61.353 35.6243C62.4455 35.6243 63.3322 36.513 63.3322 37.6035C63.3322 38.694 62.4455 39.5826 61.353 39.5826Z"
      fill="#16123F"
    />
    <path
      d="M45.52 55.4156C38.1418 55.4156 31.1119 58.5407 26.2334 63.9912C25.5051 64.8066 25.5743 66.0574 26.3877 66.7858C27.2032 67.5161 28.454 67.4448 29.1823 66.6314C33.3107 62.018 39.266 59.3739 45.52 59.3739C51.7741 59.3739 57.7293 62.018 61.8577 66.6314C62.2476 67.0688 62.7899 67.2904 63.3322 67.2904C63.8012 67.2904 64.2762 67.1242 64.6523 66.7858C65.4657 66.0574 65.5349 64.8066 64.8066 63.9912C59.9281 58.5427 52.9002 55.4156 45.52 55.4156Z"
      fill="#16123F"
    />
  </svg>
);

const indexVeryHigh = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.52 0C20.4187 0 0 20.4207 0 45.52C0 70.6193 20.4187 91.04 45.52 91.04C70.6213 91.04 91.04 70.6193 91.04 45.52C91.04 20.4207 70.6213 0 45.52 0ZM45.52 87.0817C22.6036 87.0817 3.95826 68.4383 3.95826 45.52C3.95826 22.6017 22.6036 3.95826 45.52 3.95826C68.4364 3.95826 87.0817 22.6017 87.0817 45.52C87.0817 68.4383 68.4364 87.0817 45.52 87.0817Z"
      fill="#16123F"
    />
    <path
      d="M36.2023 46.9192C36.5882 47.3052 37.0949 47.4991 37.6015 47.4991C38.1082 47.4991 38.6149 47.3052 39.0008 46.9192C39.7746 46.1454 39.7746 44.8946 39.0008 44.1207L32.4815 37.6035L38.9988 31.0882C39.7726 30.3143 39.7726 29.0635 38.9988 28.2897C38.225 27.5158 36.9742 27.5158 36.2003 28.2897L29.683 34.8069L23.1658 28.2897C22.3919 27.5158 21.1411 27.5158 20.3673 28.2897C19.5934 29.0635 19.5934 30.3143 20.3673 31.0882L26.8845 37.6035L20.3653 44.1207C19.5915 44.8946 19.5915 46.1454 20.3653 46.9192C20.7512 47.3052 21.2579 47.4991 21.7645 47.4991C22.2712 47.4991 22.7779 47.3052 23.1638 46.9192L29.683 40.4L36.2023 46.9192Z"
      fill="#16123F"
    />
    <path
      d="M64.1516 37.6035L70.6688 31.0882C71.4427 30.3143 71.4427 29.0635 70.6688 28.2897C69.895 27.5158 68.6442 27.5158 67.8704 28.2897L61.3531 34.8069L54.8358 28.2897C54.062 27.5158 52.8112 27.5158 52.0373 28.2897C51.2635 29.0635 51.2635 30.3143 52.0373 31.0882L58.5546 37.6035L52.0353 44.1207C51.2615 44.8946 51.2615 46.1454 52.0353 46.9192C52.4213 47.3052 52.9279 47.4991 53.4346 47.4991C53.9412 47.4991 54.4479 47.3052 54.8338 46.9192L61.3531 40.4L67.8723 46.9192C68.2583 47.3052 68.7649 47.4991 69.2716 47.4991C69.7782 47.4991 70.2849 47.3052 70.6708 46.9192C71.4447 46.1454 71.4447 44.8946 70.6708 44.1207L64.1516 37.6035Z"
      fill="#16123F"
    />
    <path
      d="M61.351 59.3739H29.6849C28.5905 59.3739 27.7058 60.2606 27.7058 61.353C27.7058 62.4455 28.5905 63.3322 29.6849 63.3322H61.351C62.4455 63.3322 63.3302 62.4455 63.3302 61.353C63.3302 60.2606 62.4455 59.3739 61.351 59.3739Z"
      fill="#16123F"
    />
  </svg>
);

export const icons = {
  noData,
  noDataCircle,
  indexVeryLow,
  indexLow,
  indexMedium,
  indexHigh,
  indexVeryHigh,
  clock,
  linkedin,
  facebook,
};
