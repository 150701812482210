import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../../context/Cookies";

const getLang = () =>{
  return getCookie('lang');
}

const fetchDefinedIndexes = createAsyncThunk(
  "indexes/fetchDefinedIndexes",
  async (rejectWithValue) => {
    try{
      const lang = getLang();
      const res = await fetch(`https://open-data.senstate.cloud/assets/indexes?lang=${lang}`)

      if (!res.ok) {
        throw new Error("Something went wrong.");
      }

      const data = await res.json();
      //console.log('idxs', data);
      return data;
    } catch(err){
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('definedStandardsSlice: ', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = [];

export const definedIndexesSlice = createSlice({
  name: "indexes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefinedIndexes.pending, (state, action) => {})
      .addCase(fetchDefinedIndexes.fulfilled, (state, action) => {
        state.push(...action.payload);
      })
      .addCase(fetchDefinedIndexes.rejected, (state, action) => {
        console.log(action.error.message);
      });
  },
});

export { fetchDefinedIndexes };

export default definedIndexesSlice.reducer;
