import React, { useContext, useEffect, useState } from "react";
import SideMenuNav from "./SideMenuNav";
import SideMenuContent from "./SideMenuContent";
import { isMobile } from 'react-device-detect';

/*
import CAQI from "../../assets/CAQI.png";
import EAQI from "../../assets/EAQI.png";
import SBAQI from "../../assets/SBAQI.png";
*/

import styles from "./sideMenu.module.scss";
import LangContext from "../../context/lang-context";
import { translator } from "../../utils/translator";
import { closeDevice } from "../../redux/CurrentDevice/currentDeviceSlice";
import { openAboutFaq, closeAboutFaq } from "../../redux/AboutFaq/aboutFaqSlice";
//import { setSelectedFilter } from "../../redux/CurrentFilter/currentFilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCookie, setCookie } from "../../context/Cookies";
import ThemeContext from "../../context/theme-context";
import { selectedDeviceType } from "../../functions/functions";

/**
 * 
 * @returns screen height
 */
 const getHeight = () => {
  const html = document?.documentElement
  return Math.max(window?.innerHeight, html?.clientHeight, html?.offsetHeight)
}

/**
 * 
 * @returns screen width
 */
const getWidth = () => {
  const html = document?.documentElement
  return Math.max(window?.innerWidth, html?.clientWidth, html?.offsetWidth)
}



const SideMenu = (props) => {
  const langCtx = useContext(LangContext);
  const { lang } = langCtx;
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [currItem, setCurrItem] = useState("");
  const [currItemTitle, setCurrItemTitle] = useState("");

  const [isWidth, setWidth] = useState(false); // Menu is width menu or just with icons
  const [orientation, setOrientation] = useState(getWidth() > getHeight() ? 'LANDSCAPE' : 'PORTRAIT');

  const dispatch = useDispatch();
  const filterCookie = getCookie("filter");
  //const { selectedFilter } = useSelector((state) => state.currentFilter);
  const ctx = useContext(ThemeContext);
  let { isDarkTheme } = ctx;
  /**************** For mobile ********************************8*/
  const [showMenu, setShowMenu] = useState(false);

  


  function handleActiveCategory(val){
    props.handleCategory(val);
    setCookie("filter", val, 3650);
    setTags(val);
  }

  function setTags(cat){
    let titleTag = document.getElementsByTagName("title");
    let ogTagTitle = document.querySelector('[property="og:title"]');
    let ogTagUrl = document.querySelector('[property="og:url"]');
    let descTag = document.querySelector('[name="description"]');
    let keyWordTag = document.querySelector('[name="keywords"]');
    switch(cat){
      case 'water':
        titleTag[0].innerHTML = "Senstate Water Level and Quality Live Map";
        ogTagTitle.content = "Senstate Water Level and Quality Live Map";
        ogTagUrl.content = "https://senstate.com/map";
        descTag.content = 'Check the water level and quality in your area. Browse environmental sensory data on the map';      
        keyWordTag.content = 'water quality,water level,live,map,water,quality index,caqi,eaqi,european,american,asian';
        break;
      case 'radiation':
        titleTag[0].innerHTML = "Senstate Ambient Radiation Doses Live Map";
        ogTagTitle.content = "Senstate Ambient Radiation Doses Live Map";
        ogTagUrl.content = "https://senstate.com/map";
        descTag.content = 'Check the ambient radiation doses in your area. Browse environmental sensory data on the map';      
        keyWordTag.content = 'radiation,radiation doses,live,map,ambient,quality index,caqi,eaqi,european,american,asian';
        break;
      case 'noise':
        titleTag[0].innerHTML = "Senstate Background Noise Levels Live Map";
        ogTagTitle.content = "Senstate Background Noise Levels Live Map";
        ogTagUrl.content = "https://senstate.com/map";
        descTag.content = 'Check the background noise levels in your area. Browse environmental sensory data on the map';      
        keyWordTag.content = 'noise,background,level,live,map,water,quality index,caqi,eaqi,european,american,asian';
        break;
      default:
        titleTag[0].innerHTML = "Senstate Air Quality and Environmental Live Map";
        ogTagTitle.content = "Senstate Air Quality and Environmental Live Map";
        ogTagUrl.content = "https://senstate.com/map";
        descTag.content = 'Check the air quality and air pollution in your area. Browse environmental sensory data on the map';      
        keyWordTag.content = 'air quality,air pollution,live,map,environment,quality index,caqi,eaqi,european';
    }
  }
  

  useEffect(() => {
    //const FAQ = lang === "bg" ? FAQbg : FAQen;
    const FAQ = translator.leftMenuJsx[lang].faq.accordionData;
    //const About = lang === "bg" ? aboutBG : aboutEN;
    const About = translator.leftMenuJsx[lang].about.accordionData;
    setCurrItem(currItemTitle === "About" ? About : FAQ);

  }, [lang, dispatch]);

  /*
  useEffect(() => {
    if(props.activeCategory > 0 && !props.urlParsed){
      //handleFilterSelected(props.activeFilter);
      //dispatch(setSelectedFilter(props.activeFilter));
      //handleActiveCategory(props.activeCategory);
    }
  }, [lang, currItemTitle, props.urlParsed])
  */


  useEffect(() => {
    function handleResize() {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
      if (getWidth() < getHeight()) {
        setOrientation("PORTRAIT");
      } else {
        setOrientation("LANDSCAPE");    
      }    
    }

    if(filterCookie && props.activeCategory === 0){
      //dispatch(setSelectedFilter(parseInt(filterCookie)));
      if(!isNaN(filterCookie)){
        handleActiveCategory(selectedDeviceType(parseInt(filterCookie)));
        //filterCookie = filters(parseInt(filterCookie));
      } else {
        handleActiveCategory(filterCookie);
      }
    }

    if(!filterCookie && props.activeCategory === 0){
      handleActiveCategory('air');
    }
    

    window.addEventListener('resize', handleResize)
  });

  /**
   * Change filter type
   * @param {int} filterType  - 1,2,3 or 4
   */
  /*
  const handleFilterSelected = (filterType) => {
    if(props.activeCategory !== filterType){
      handleActiveCategory(filterType);
      //dispatch(setSelectedFilter(filterType));
      //handleActiveFilter(parseInt(filterType));
      dispatch(closeDevice());
      setCookie("filter", filterType, 3650);
    }
  };
  */


  /**
   * Open About or Faq info main function - open or close
   * @param {string} itemTitle  - About or FAQ
   */
  const handleItemSelection = (itemTitle) => {
    //if (currItemTitle !== itemTitle) {
      setIsItemSelected(true);
      //const FAQ = lang === "bg" ? FAQbg : FAQen;
      //const FAQ = translator.leftMenuJsx[lang].faq;
      const FAQ = translator.leftMenuJsx[lang].faq.accordionData;
      //<Accordion accordData = {translator.leftMenuJsx[lang].faq} />
      //const About = lang === "bg" ? aboutBG : aboutEN;
      //{accordionData.map(({ title, content }) => (
        //<Accordion title="TEST" content={FAQ} />
      //))}
      const About = translator.leftMenuJsx[lang].about.accordionData;
      setCurrItem(itemTitle === "About" ? About : FAQ);
      setCurrItemTitle(itemTitle);
      if(!props.isAboutFaqOpen){
        handleAboutFaqOpen(true);
        dispatch(closeDevice());
      }
    //} else {
    //  closeContent();
    //}
  };

  /**
   * Changes About or Faq open or close state, needed for SideBar, When open closes sidebar
   * @param {state} state - true (open) or false (closed)
   */
  const handleAboutFaqOpen = (state) => {
    //props.onChange(state);
    if(!state){
      //closeContent();
      dispatch(closeAboutFaq());
    } else {
      dispatch(openAboutFaq());
    }
  }

  /**
   * Closes content of About or Faq and changes open state
   */
  const closeContent = () => {
    if(isItemSelected){
      setIsItemSelected(false);
      setCurrItem("");
      setCurrItemTitle("");
      //dispatch(closeAboutFaq());
      handleAboutFaqOpen(false);
    }
  };

  const handleSetWidth = (getValue) => {
    setWidth(getValue);
  };

  //const actCat = props.activeCategory === 0 ? 'air' : props.activeCategory;

  
  return (
    <>
    {(isMobile && orientation === 'PORTRAIT') && (
      <div className={showMenu ? 
        (isWidth ? `${styles["translated-width"]} ${styles["mobile-slide"]}` : `${styles["translated"]} ${styles["mobile-slide"]}`) : styles["mobile-slide"]} 
        onClick={showMenu => !showMenu}>
        <button 
          onClick={() => {
            setShowMenu(showMenu => !showMenu); 
            !showMenu && setWidth(false)
          }}
          className={isDarkTheme ? styles["mobile-dark-button"] : ''}
        >
          { !showMenu ? (
            isDarkTheme ? (
              <svg viewBox="0 0 100 80" width="20" height="40">
                <rect width="100" height="20" fill="#FFFFFF"></rect>
                <rect y="30" width="100" height="20" fill="#FFFFFF"></rect>
                <rect y="60" width="100" height="20" fill="#FFFFFF"></rect>
              </svg>
            ) : (
              <svg viewBox="0 0 100 80" width="20" height="40">
                <rect width="100" height="20" fill="#000000"></rect>
                <rect y="30" width="100" height="20" fill="#000000"></rect>
                <rect y="60" width="100" height="20" fill="#000000"></rect>
              </svg>
            )
          ) : (
            isDarkTheme ? (
              <svg width="20" height="40" viewport="0 0 100 80">
                <line x1="1" y1="30" x2="20" y2="10" stroke="#FFFFFF" strokeWidth="3"></line>
                <line x1="1" y1="10" x2="20" y2="30" stroke="#FFFFFF" strokeWidth="3"></line>
              </svg>
            ) : (
              <svg width="20" height="40" viewport="0 0 100 80">
                <line x1="1" y1="30" x2="20" y2="10" stroke="#000000" strokeWidth="3"></line>
                <line x1="1" y1="10" x2="20" y2="30" stroke="#000000" strokeWidth="3"></line>
              </svg>
            )
          )}
        </button>
      </div>
    )}
    <div className={(isMobile && orientation === 'PORTRAIT') ? (!showMenu ? `${styles["hide-menu"]} ${styles["container"]}` : styles["container"]) : styles["container"]}>    
      <SideMenuNav
        selectItem={handleItemSelection}
        isItemSelected={isItemSelected}
        //selectFilter={handleFilterSelected}
        activeCategory={props.activeCategory}
        isWidth={isWidth}
        setWidth={handleSetWidth}
        handleCategory={handleActiveCategory}
      />
      {isItemSelected && props.isAboutFaqOpen && (
        <SideMenuContent 
          item={currItem} 
          closeContent={closeContent} 
          isWidth={isWidth} 
          mainTitle={currItemTitle === 'About' ? 
            translator.leftMenuJsx[lang].about.mainTitle : 
            translator.leftMenuJsx[lang].faq.mainTitle
          }            
        />
      )}
    </div>        
    </>
  );
}

export default SideMenu;