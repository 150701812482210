export const sideMenuIcons = {
  faqCircle: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="25" cy="25" r="25" fill="white" />
      </g>
      <path
        transform="translate(-13, -14)"
        d="M40.05 42.325H37.225V40.8C37.225 40.1333 37.3 39.6417 37.45 39.325C37.6167 38.9917 37.9667 38.5583 38.5 38.025L40.325 36.2C40.7083 35.7833 40.9 35.2667 40.9 34.65C40.9 34.0333 40.7 33.525 40.3 33.125C39.9 32.7083 39.3833 32.5 38.75 32.5C38.1167 32.5 37.5833 32.7 37.15 33.1C36.7333 33.4833 36.4917 34 36.425 34.65H33.4C33.5667 33.1667 34.1417 32.0083 35.125 31.175C36.125 30.325 37.3667 29.9 38.85 29.9C40.3333 29.9 41.5417 30.3083 42.475 31.125C43.4083 31.925 43.875 33.05 43.875 34.5C43.875 35.5 43.6 36.3333 43.05 37C42.7333 37.4 42.4917 37.6833 42.325 37.85C42.1583 38.0167 41.9333 38.2333 41.65 38.5C41.3833 38.75 41.15 38.975 40.95 39.175C40.7667 39.3583 40.6167 39.5167 40.5 39.65C40.2 40.0167 40.05 40.5333 40.05 41.2V42.325ZM38.675 47.875C38.1583 47.875 37.7083 47.7 37.325 47.35C36.9417 46.9833 36.75 46.55 36.75 46.05C36.75 45.5333 36.9333 45.0917 37.3 44.725C37.6833 44.3583 38.1333 44.175 38.65 44.175C39.1833 44.175 39.6417 44.3583 40.025 44.725C40.4083 45.075 40.6 45.5083 40.6 46.025C40.6 46.525 40.4083 46.9583 40.025 47.325C39.6583 47.6917 39.2083 47.875 38.675 47.875Z"
        fill="#16123F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  faq: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-13, -14)"
        d="M40.05 42.325H37.225V40.8C37.225 40.1333 37.3 39.6417 37.45 39.325C37.6167 38.9917 37.9667 38.5583 38.5 38.025L40.325 36.2C40.7083 35.7833 40.9 35.2667 40.9 34.65C40.9 34.0333 40.7 33.525 40.3 33.125C39.9 32.7083 39.3833 32.5 38.75 32.5C38.1167 32.5 37.5833 32.7 37.15 33.1C36.7333 33.4833 36.4917 34 36.425 34.65H33.4C33.5667 33.1667 34.1417 32.0083 35.125 31.175C36.125 30.325 37.3667 29.9 38.85 29.9C40.3333 29.9 41.5417 30.3083 42.475 31.125C43.4083 31.925 43.875 33.05 43.875 34.5C43.875 35.5 43.6 36.3333 43.05 37C42.7333 37.4 42.4917 37.6833 42.325 37.85C42.1583 38.0167 41.9333 38.2333 41.65 38.5C41.3833 38.75 41.15 38.975 40.95 39.175C40.7667 39.3583 40.6167 39.5167 40.5 39.65C40.2 40.0167 40.05 40.5333 40.05 41.2V42.325ZM38.675 47.875C38.1583 47.875 37.7083 47.7 37.325 47.35C36.9417 46.9833 36.75 46.55 36.75 46.05C36.75 45.5333 36.9333 45.0917 37.3 44.725C37.6833 44.3583 38.1333 44.175 38.65 44.175C39.1833 44.175 39.6417 44.3583 40.025 44.725C40.4083 45.075 40.6 45.5083 40.6 46.025C40.6 46.525 40.4083 46.9583 40.025 47.325C39.6583 47.6917 39.2083 47.875 38.675 47.875Z"
        fill="#16123F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  aboutCircle: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="25" cy="25" r="25" fill="white" />
      </g>
      <path
        transform="translate(-15, -15)"
        d="M44.6819 48.7544L44.3459 50.0753C43.3379 50.4579 42.5327 50.7491 41.9331 50.9493C41.3329 51.1501 40.6355 51.25 39.8408 51.25C38.6206 51.25 37.6714 50.9625 36.9944 50.3915C36.3175 49.8183 35.9789 49.0918 35.9789 48.2106C35.9789 47.8694 36.0035 47.5186 36.0546 47.161C36.1061 46.8031 36.188 46.4 36.3 45.9494L37.5598 41.6617C37.6718 41.2511 37.7671 40.8621 37.8434 40.4942C37.9208 40.1286 37.958 39.7923 37.958 39.4895C37.958 38.9419 37.8402 38.5589 37.6055 38.3435C37.3707 38.1288 36.9229 38.0198 36.258 38.0198C35.9324 38.0198 35.5977 38.07 35.2565 38.167C34.9138 38.2644 34.6209 38.3585 34.375 38.4459L34.7119 37.1241C35.5371 36.8009 36.3259 36.5243 37.0804 36.2946C37.835 36.0644 38.5479 35.9491 39.2223 35.9491C40.4342 35.9491 41.3693 36.2308 42.026 36.7941C42.6827 37.3578 43.011 38.0886 43.011 38.9892C43.011 39.1755 42.9894 39.5038 42.9431 39.973C42.8978 40.4433 42.8135 40.8741 42.6907 41.2661L41.4363 45.5366C41.3335 45.8796 41.2411 46.2717 41.1607 46.713C41.0777 47.1513 41.0381 47.4862 41.0381 47.7109C41.0381 48.2779 41.1695 48.6651 41.4331 48.8709C41.6986 49.0767 42.156 49.1791 42.8056 49.1791C43.1105 49.1791 43.4575 49.1271 43.8431 49.0249C44.2276 48.9227 44.508 48.833 44.6819 48.7544ZM45 30.8255C45 31.5696 44.7084 32.205 44.1224 32.7275C43.5379 33.2519 42.8336 33.5143 42.0096 33.5143C41.1831 33.5143 40.4771 33.2519 39.8859 32.7275C39.2958 32.2048 39.0001 31.5696 39.0001 30.8255C39.0001 30.0829 39.2958 29.4465 39.8859 28.9173C40.476 28.389 41.1833 28.125 42.0096 28.125C42.8334 28.125 43.5379 28.3897 44.1224 28.9173C44.7088 29.4465 45 30.0832 45 30.8255Z"
        fill="#16123F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  about: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-15, -15)"
        d="M44.6819 48.7544L44.3459 50.0753C43.3379 50.4579 42.5327 50.7491 41.9331 50.9493C41.3329 51.1501 40.6355 51.25 39.8408 51.25C38.6206 51.25 37.6714 50.9625 36.9944 50.3915C36.3175 49.8183 35.9789 49.0918 35.9789 48.2106C35.9789 47.8694 36.0035 47.5186 36.0546 47.161C36.1061 46.8031 36.188 46.4 36.3 45.9494L37.5598 41.6617C37.6718 41.2511 37.7671 40.8621 37.8434 40.4942C37.9208 40.1286 37.958 39.7923 37.958 39.4895C37.958 38.9419 37.8402 38.5589 37.6055 38.3435C37.3707 38.1288 36.9229 38.0198 36.258 38.0198C35.9324 38.0198 35.5977 38.07 35.2565 38.167C34.9138 38.2644 34.6209 38.3585 34.375 38.4459L34.7119 37.1241C35.5371 36.8009 36.3259 36.5243 37.0804 36.2946C37.835 36.0644 38.5479 35.9491 39.2223 35.9491C40.4342 35.9491 41.3693 36.2308 42.026 36.7941C42.6827 37.3578 43.011 38.0886 43.011 38.9892C43.011 39.1755 42.9894 39.5038 42.9431 39.973C42.8978 40.4433 42.8135 40.8741 42.6907 41.2661L41.4363 45.5366C41.3335 45.8796 41.2411 46.2717 41.1607 46.713C41.0777 47.1513 41.0381 47.4862 41.0381 47.7109C41.0381 48.2779 41.1695 48.6651 41.4331 48.8709C41.6986 49.0767 42.156 49.1791 42.8056 49.1791C43.1105 49.1791 43.4575 49.1271 43.8431 49.0249C44.2276 48.9227 44.508 48.833 44.6819 48.7544ZM45 30.8255C45 31.5696 44.7084 32.205 44.1224 32.7275C43.5379 33.2519 42.8336 33.5143 42.0096 33.5143C41.1831 33.5143 40.4771 33.2519 39.8859 32.7275C39.2958 32.2048 39.0001 31.5696 39.0001 30.8255C39.0001 30.0829 39.2958 29.4465 39.8859 28.9173C40.476 28.389 41.1833 28.125 42.0096 28.125C42.8334 28.125 43.5379 28.3897 44.1224 28.9173C44.7088 29.4465 45 30.0832 45 30.8255Z"
        fill="#16123F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  darkCircle: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="25" cy="25" r="25" fill="white" />
      </g>
      <path
        transform="translate(-15, -15)"
        d="M50.012 48.3733C46.7791 49.3587 43.2709 48.7642 40.5434 46.7686C37.8158 44.7729 36.1874 41.6094 36.1485 38.23C36.1384 34.1806 38.3713 30.4588 41.9486 28.5612C42.3133 28.3711 42.4973 27.953 42.3913 27.5557C42.2979 27.1551 41.9396 26.8724 41.5283 26.8751H41.5254C37.8408 26.8622 34.3144 28.3721 31.7812 31.0477C29.248 33.7236 27.9331 37.327 28.1477 41.0054C28.2968 44.5928 29.8981 47.9655 32.5837 50.3488C35.0713 52.5352 38.2683 53.7438 41.5802 53.75C41.8462 53.75 42.1133 53.742 42.3792 53.726C44.3181 53.604 46.208 53.0643 47.919 52.1441C49.0169 51.5338 50.0202 50.7669 50.8969 49.8674C51.1874 49.5782 51.2446 49.1287 51.0356 48.7759C50.8267 48.4233 50.405 48.2576 50.012 48.3733ZM47.4942 51.3545C45.8976 52.2136 44.1345 52.7174 42.3252 52.8314C38.9799 53.022 35.6939 51.8893 33.1771 49.6776C30.6731 47.4535 29.1807 44.3068 29.0429 40.9606C28.8408 37.5268 30.0675 34.1622 32.4321 31.6637C34.7969 29.1655 38.0892 27.7561 41.5291 27.7692C37.656 29.8252 35.2397 33.8565 35.252 38.2413C35.2947 41.9007 37.0574 45.3267 40.0103 47.4888C42.963 49.651 46.7614 50.2973 50.263 49.2333C49.448 50.0711 48.5153 50.7857 47.4942 51.3545Z"
        fill="#16103F"
        stroke="#16103F"
      />
      <path
        transform="translate(-15, -15)"
        d="M42.8555 34.725C44.1514 34.725 45.2059 35.9242 45.2059 37.3987C45.2059 37.6449 45.4163 37.8444 45.6761 37.8444C45.9356 37.8444 46.1461 37.6449 46.1461 37.3987C46.1461 35.9242 47.2008 34.725 48.4967 34.725C48.7564 34.725 48.9669 34.5255 48.9669 34.2792C48.9669 34.0332 48.7564 33.8337 48.4967 33.8337C47.2008 33.8337 46.1461 32.6343 46.1461 31.16C46.1461 30.9138 45.9356 30.7143 45.6761 30.7143C45.4163 30.7143 45.2059 30.9138 45.2059 31.16C45.2059 32.6343 44.1514 33.8337 42.8555 33.8337C42.5957 33.8337 42.3853 34.0332 42.3853 34.2792C42.3853 34.5255 42.5957 34.725 42.8555 34.725ZM45.6761 32.9947C45.9608 33.5169 46.3843 33.9596 46.9049 34.2792C46.3843 34.5991 45.9608 35.0418 45.6761 35.564C45.3911 35.0418 44.9676 34.5991 44.4473 34.2792C44.9676 33.9596 45.3911 33.5169 45.6761 32.9947Z"
        fill="#16103F"
      />
      <path
        transform="translate(-15, -15)"
        d="M51.8258 38.7515C50.9412 38.7515 50.2215 37.8146 50.2215 36.6632C50.2215 36.4067 50.0282 36.199 49.7897 36.199C49.5511 36.199 49.3577 36.4067 49.3577 36.6632C49.3577 37.8146 48.6383 38.7515 47.7535 38.7515C47.5151 38.7515 47.3215 38.9592 47.3215 39.2157C47.3215 39.4719 47.5151 39.6796 47.7535 39.6796C48.6383 39.6796 49.3577 40.6165 49.3577 41.7682C49.3577 42.0244 49.5511 42.2321 49.7897 42.2321C50.0282 42.2321 50.2215 42.0244 50.2215 41.7682C50.2215 40.6165 50.9412 39.6796 51.8258 39.6796C52.0644 39.6796 52.2578 39.4719 52.2578 39.2157C52.2578 38.9592 52.0644 38.7515 51.8258 38.7515ZM49.7897 40.0648C49.6049 39.7323 49.3593 39.4434 49.0675 39.2157C49.3593 38.9879 49.6049 38.699 49.7897 38.3663C49.9744 38.699 50.2202 38.9879 50.5118 39.2157C50.2202 39.4434 49.9744 39.7323 49.7897 40.0648Z"
        fill="#16103F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  dark: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-15, -15)"
        d="M50.012 48.3733C46.7791 49.3587 43.2709 48.7642 40.5434 46.7686C37.8158 44.7729 36.1874 41.6094 36.1485 38.23C36.1384 34.1806 38.3713 30.4588 41.9486 28.5612C42.3133 28.3711 42.4973 27.953 42.3913 27.5557C42.2979 27.1551 41.9396 26.8724 41.5283 26.8751H41.5254C37.8408 26.8622 34.3144 28.3721 31.7812 31.0477C29.248 33.7236 27.9331 37.327 28.1477 41.0054C28.2968 44.5928 29.8981 47.9655 32.5837 50.3488C35.0713 52.5352 38.2683 53.7438 41.5802 53.75C41.8462 53.75 42.1133 53.742 42.3792 53.726C44.3181 53.604 46.208 53.0643 47.919 52.1441C49.0169 51.5338 50.0202 50.7669 50.8969 49.8674C51.1874 49.5782 51.2446 49.1287 51.0356 48.7759C50.8267 48.4233 50.405 48.2576 50.012 48.3733ZM47.4942 51.3545C45.8976 52.2136 44.1345 52.7174 42.3252 52.8314C38.9799 53.022 35.6939 51.8893 33.1771 49.6776C30.6731 47.4535 29.1807 44.3068 29.0429 40.9606C28.8408 37.5268 30.0675 34.1622 32.4321 31.6637C34.7969 29.1655 38.0892 27.7561 41.5291 27.7692C37.656 29.8252 35.2397 33.8565 35.252 38.2413C35.2947 41.9007 37.0574 45.3267 40.0103 47.4888C42.963 49.651 46.7614 50.2973 50.263 49.2333C49.448 50.0711 48.5153 50.7857 47.4942 51.3545Z"
        fill="#16103F"
        stroke="#16103F"
      />
      <path
        transform="translate(-15, -15)"
        d="M42.8555 34.725C44.1514 34.725 45.2059 35.9242 45.2059 37.3987C45.2059 37.6449 45.4163 37.8444 45.6761 37.8444C45.9356 37.8444 46.1461 37.6449 46.1461 37.3987C46.1461 35.9242 47.2008 34.725 48.4967 34.725C48.7564 34.725 48.9669 34.5255 48.9669 34.2792C48.9669 34.0332 48.7564 33.8337 48.4967 33.8337C47.2008 33.8337 46.1461 32.6343 46.1461 31.16C46.1461 30.9138 45.9356 30.7143 45.6761 30.7143C45.4163 30.7143 45.2059 30.9138 45.2059 31.16C45.2059 32.6343 44.1514 33.8337 42.8555 33.8337C42.5957 33.8337 42.3853 34.0332 42.3853 34.2792C42.3853 34.5255 42.5957 34.725 42.8555 34.725ZM45.6761 32.9947C45.9608 33.5169 46.3843 33.9596 46.9049 34.2792C46.3843 34.5991 45.9608 35.0418 45.6761 35.564C45.3911 35.0418 44.9676 34.5991 44.4473 34.2792C44.9676 33.9596 45.3911 33.5169 45.6761 32.9947Z"
        fill="#16103F"
      />
      <path
        transform="translate(-15, -15)"
        d="M51.8258 38.7515C50.9412 38.7515 50.2215 37.8146 50.2215 36.6632C50.2215 36.4067 50.0282 36.199 49.7897 36.199C49.5511 36.199 49.3577 36.4067 49.3577 36.6632C49.3577 37.8146 48.6383 38.7515 47.7535 38.7515C47.5151 38.7515 47.3215 38.9592 47.3215 39.2157C47.3215 39.4719 47.5151 39.6796 47.7535 39.6796C48.6383 39.6796 49.3577 40.6165 49.3577 41.7682C49.3577 42.0244 49.5511 42.2321 49.7897 42.2321C50.0282 42.2321 50.2215 42.0244 50.2215 41.7682C50.2215 40.6165 50.9412 39.6796 51.8258 39.6796C52.0644 39.6796 52.2578 39.4719 52.2578 39.2157C52.2578 38.9592 52.0644 38.7515 51.8258 38.7515ZM49.7897 40.0648C49.6049 39.7323 49.3593 39.4434 49.0675 39.2157C49.3593 38.9879 49.6049 38.699 49.7897 38.3663C49.9744 38.699 50.2202 38.9879 50.5118 39.2157C50.2202 39.4434 49.9744 39.7323 49.7897 40.0648Z"
        fill="#16103F"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  lightCircle: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle cx="25" cy="25" r="25" fill="#2C2C2C" />
      </g>
      <path
        transform="translate(-15, -15)"
        d="M39.6893 49.581C39.1598 49.581 38.7305 50.0241 38.7305 50.5708V53.3851C38.7305 53.9318 39.1598 54.375 39.6893 54.375C40.2189 54.375 40.6481 53.9318 40.6481 53.3851V50.5708C40.6481 50.024 40.2188 49.581 39.6893 49.581Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M39.6893 31.044C40.2189 31.044 40.6481 30.6009 40.6481 30.0542V27.2398C40.6481 26.6932 40.2189 26.25 39.6893 26.25C39.1598 26.25 38.7305 26.6932 38.7305 27.2398V30.0542C38.7305 30.6009 39.1598 31.044 39.6893 31.044Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M31.6612 46.9845L29.7382 48.907C29.3647 49.2804 29.3646 49.886 29.7379 50.2595C29.9246 50.4463 30.1694 50.5398 30.4142 50.5398C30.6588 50.5398 30.9033 50.4464 31.0901 50.2599L33.013 48.3373C33.3865 47.964 33.3866 47.3584 33.0134 46.9848C32.6399 46.6112 32.0346 46.6112 31.6612 46.9845Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M47.0352 33.9204C47.2799 33.9204 47.5245 33.8271 47.7113 33.6404L49.6342 31.718C50.0076 31.3445 50.0077 30.7391 49.6344 30.3655C49.261 29.9919 48.6557 29.9918 48.2821 30.3654L46.3593 32.2878C45.9858 32.6611 45.9857 33.2667 46.359 33.6402C46.5456 33.8271 46.7904 33.9204 47.0352 33.9204Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M30.419 40.3125C30.419 39.783 29.9758 39.3537 29.4292 39.3537H26.6148C26.0682 39.3537 25.625 39.783 25.625 40.3125C25.625 40.842 26.0682 41.2713 26.6148 41.2713H29.4292C29.9758 41.2713 30.419 40.842 30.419 40.3125Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M52.7595 39.3537H49.9447C49.398 39.3537 48.9551 39.783 48.9551 40.3125C48.9551 40.842 49.398 41.2713 49.9447 41.2713H52.7595C53.3062 41.2713 53.7491 40.842 53.7491 40.3125C53.7491 39.7831 53.3062 39.3537 52.7595 39.3537Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M31.6606 33.6404C31.8473 33.8271 32.0922 33.9204 32.3369 33.9204C32.5816 33.9204 32.8264 33.8271 33.0131 33.6404C33.3866 33.2669 33.3866 32.6613 33.0131 32.2878L31.0906 30.3653C30.7171 29.9918 30.1115 29.9918 29.7381 30.3653C29.3646 30.7389 29.3646 31.3444 29.7381 31.7178L31.6606 33.6404Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M47.712 46.9848C47.3385 46.6112 46.7329 46.6111 46.3593 46.9846C45.9857 47.3581 45.9857 47.9637 46.3592 48.3371L48.2815 50.2596C48.4683 50.4464 48.713 50.5398 48.9578 50.5398C49.2026 50.5398 49.4474 50.4464 49.6341 50.2598C50.0077 49.8862 50.0077 49.2806 49.6342 48.9073L47.712 46.9848Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M39.6894 32.9617C35.6363 32.9617 32.3389 36.2593 32.3389 40.3126C32.3389 44.3658 35.6363 47.6634 39.6894 47.6634C43.7429 47.6634 47.0406 44.3658 47.0406 40.3126C47.0406 36.2593 43.7428 32.9617 39.6894 32.9617ZM39.6894 45.7745C36.6778 45.7745 34.2277 43.3243 34.2277 40.3126C34.2277 37.3008 36.6778 34.8505 39.6894 34.8505C42.7014 34.8505 45.1517 37.3008 45.1517 40.3126C45.1517 43.3243 42.7014 45.7745 39.6894 45.7745Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  light: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-15, -15)"
        d="M39.6893 49.581C39.1598 49.581 38.7305 50.0241 38.7305 50.5708V53.3851C38.7305 53.9318 39.1598 54.375 39.6893 54.375C40.2189 54.375 40.6481 53.9318 40.6481 53.3851V50.5708C40.6481 50.024 40.2188 49.581 39.6893 49.581Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M39.6893 31.044C40.2189 31.044 40.6481 30.6009 40.6481 30.0542V27.2398C40.6481 26.6932 40.2189 26.25 39.6893 26.25C39.1598 26.25 38.7305 26.6932 38.7305 27.2398V30.0542C38.7305 30.6009 39.1598 31.044 39.6893 31.044Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M31.6612 46.9845L29.7382 48.907C29.3647 49.2804 29.3646 49.886 29.7379 50.2595C29.9246 50.4463 30.1694 50.5398 30.4142 50.5398C30.6588 50.5398 30.9033 50.4464 31.0901 50.2599L33.013 48.3373C33.3865 47.964 33.3866 47.3584 33.0134 46.9848C32.6399 46.6112 32.0346 46.6112 31.6612 46.9845Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M47.0352 33.9204C47.2799 33.9204 47.5245 33.8271 47.7113 33.6404L49.6342 31.718C50.0076 31.3445 50.0077 30.7391 49.6344 30.3655C49.261 29.9919 48.6557 29.9918 48.2821 30.3654L46.3593 32.2878C45.9858 32.6611 45.9857 33.2667 46.359 33.6402C46.5456 33.8271 46.7904 33.9204 47.0352 33.9204Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M30.419 40.3125C30.419 39.783 29.9758 39.3537 29.4292 39.3537H26.6148C26.0682 39.3537 25.625 39.783 25.625 40.3125C25.625 40.842 26.0682 41.2713 26.6148 41.2713H29.4292C29.9758 41.2713 30.419 40.842 30.419 40.3125Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M52.7595 39.3537H49.9447C49.398 39.3537 48.9551 39.783 48.9551 40.3125C48.9551 40.842 49.398 41.2713 49.9447 41.2713H52.7595C53.3062 41.2713 53.7491 40.842 53.7491 40.3125C53.7491 39.7831 53.3062 39.3537 52.7595 39.3537Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M31.6606 33.6404C31.8473 33.8271 32.0922 33.9204 32.3369 33.9204C32.5816 33.9204 32.8264 33.8271 33.0131 33.6404C33.3866 33.2669 33.3866 32.6613 33.0131 32.2878L31.0906 30.3653C30.7171 29.9918 30.1115 29.9918 29.7381 30.3653C29.3646 30.7389 29.3646 31.3444 29.7381 31.7178L31.6606 33.6404Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M47.712 46.9848C47.3385 46.6112 46.7329 46.6111 46.3593 46.9846C45.9857 47.3581 45.9857 47.9637 46.3592 48.3371L48.2815 50.2596C48.4683 50.4464 48.713 50.5398 48.9578 50.5398C49.2026 50.5398 49.4474 50.4464 49.6341 50.2598C50.0077 49.8862 50.0077 49.2806 49.6342 48.9073L47.712 46.9848Z"
        fill="white"
      />
      <path
        transform="translate(-15, -15)"
        d="M39.6894 32.9617C35.6363 32.9617 32.3389 36.2593 32.3389 40.3126C32.3389 44.3658 35.6363 47.6634 39.6894 47.6634C43.7429 47.6634 47.0406 44.3658 47.0406 40.3126C47.0406 36.2593 43.7428 32.9617 39.6894 32.9617ZM39.6894 45.7745C36.6778 45.7745 34.2277 43.3243 34.2277 40.3126C34.2277 37.3008 36.6778 34.8505 39.6894 34.8505C42.7014 34.8505 45.1517 37.3008 45.1517 40.3126C45.1517 43.3243 42.7014 45.7745 39.6894 45.7745Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  emptyCircle: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="25" cy="25" r="25" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  empty: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  showWidthMenu: (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="5" transform="matrix(-1 0 0 1 34 0)" fill="#D5EAEB"/>
      <path d="M23.7071 16.2929C24.0976 16.6834 24.0976 17.3166 23.7071 17.7071L17.3431 24.0711C16.9526 24.4616 16.3195 24.4616 15.9289 24.0711C15.5384 23.6805 15.5384 23.0474 15.9289 22.6569L21.5858 17L15.9289 11.3431C15.5384 10.9526 15.5384 10.3195 15.9289 9.92893C16.3195 9.53841 16.9526 9.53841 17.3431 9.92893L23.7071 16.2929ZM11 16H23V18H11V16Z" fill="#16123F"/>
    </svg>
  ),
};
