import { useContext } from "react";

import styles from "./sideMenuNav.module.scss";

import ThemeContext from "../../context/theme-context";
import { sideMenuIcons } from "./Assets/icons";
import LangContext from "../../context/lang-context";
import { icons } from "../../assets/appIcons";
import { translator } from "../../utils/translator";



//import { getSelectedFilter, setSelectedFilter } from "../../redux/CurrentFilter/currentFilterSlice";

const SideMenuNav = ({ selectItem, isItemSelected, handleCategory, activeCategory, isWidth, setWidth }) => {
  const langCtx = useContext(LangContext);
  const { lang } = langCtx;
  const themeCtx = useContext(ThemeContext);
  const { isDarkTheme, toggleDarkTheme } = themeCtx;

  const { switchLanguage } = langCtx;

  const themeIcon = isDarkTheme ? sideMenuIcons.light : sideMenuIcons.dark;
  const themeBtnText = isDarkTheme
    ? translator.leftNavMenu[lang].lightMode : translator.leftNavMenu[lang].darkMode;

  let containerClasses = [styles["container"]];
  if (isDarkTheme) {
    containerClasses.push(styles["dark-theme"]); 
  }
  if(isWidth){
    containerClasses.push(styles["container-width"]);
  }


  function handleActiveCategory(val){
    handleCategory(val);
  }

  return (
    <>
    <section className={containerClasses.join(" ")}>      
      {/* begin filters */}
      <div className={styles["senstate-logo-container"]}>
        <a href="https://senstate.com" target="_blank" rel="noreferrer" className={styles["senstate-logo-link"]}>
          <div className={styles["left-menu-icon"]}>{icons.senstateLogo}</div>
          {isWidth && (<div className={styles["logo-text"]}>{icons.senstateText}</div>)}
        </a>
        {isWidth && (
          <div className={styles["close-sidebar"]}>
            <button className={styles["close-menu-btn"]} onClick={() => setWidth(false)}>{icons.leftArrow}</button>
          </div>
        )}
      </div>
      <div className={styles["filter-container"]}>
        <button onClick={() => handleActiveCategory('air')} // air
          className={!isWidth ? 
            [styles['filter-btn'], styles['air-qty'], (activeCategory === 'air' || activeCategory === '1' ? styles['selected'] : '')].join(' ') : 
            [styles['btn-width'], styles['air-qty'], (activeCategory === 'air' || activeCategory === '1' ? styles['selected'] : '')].join(' ')
          }
        >
          <div className={styles["left-menu-icon"]}>
            {icons.airQuality}
          </div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>
                {translator.leftNavMenu[lang].airQuality}
              </span>
            </div>
          )}
          {!isWidth && (
            <span className={styles["tooltiptext"]}>{translator.leftNavMenu[lang].airQuality}</span>
          )}
        </button>        
      </div>
      <div className={ styles["filter-container"] }>
        <button onClick={() => handleActiveCategory('water')} // water
          className={!isWidth ? 
            [styles['filter-btn'], styles['water-qty'], (activeCategory === 'water' || activeCategory === '2' ? styles['selected'] : '')].join(' ') : 
            [styles['btn-width'], styles['water-qty'], (activeCategory === 'water' || activeCategory === '2' ? styles['selected'] : '')].join(' ')
          }
        >
          <div className={styles["left-menu-icon"]}>{icons.waterQuality}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>{translator.leftNavMenu[lang].waterQuality}</span>
            </div>
          )}
          {!isWidth && (
            <span className={styles["tooltiptext"]}>{translator.leftNavMenu[lang].waterQuality}</span>
          )}
        </button>        
      </div>
      <div className={ styles["filter-container"] }>
        <button onClick={() => handleActiveCategory('radiation')} // radiation
          className={!isWidth ? 
            [styles['filter-btn'], styles['radiation-qty'], (activeCategory === 'radiation' || activeCategory === '3' ? styles['selected'] : '')].join(' ') : 
            [styles['btn-width'], styles['radiation-qty'], (activeCategory === 'radiation' || activeCategory === '3' ? styles['selected'] : '')].join(' ')
          }
        >
          <div className={styles["left-menu-icon"]}>{icons.radiationMonitoring}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
            <span className={styles["btn-width-text"]}>{translator.leftNavMenu[lang].radiationMonitoring}</span></div>
          )}
          {!isWidth && (
            <span className={styles["tooltiptext"]}>{translator.leftNavMenu[lang].radiationMonitoring}</span>
          )}
        </button>        
      </div>
      <div className={ styles["filter-container"] }>
        <button onClick={() => handleActiveCategory('noise')}  // noise
          className={!isWidth ? 
            [styles['filter-btn'], styles['noise-qty'], (activeCategory === 'noise' || activeCategory === '4' ? styles['selected'] : '')].join(' ') : 
            [styles['btn-width'], styles['noise-qty'], (activeCategory === 'noise' || activeCategory === '4' ? styles['selected'] : '')].join(' ')
          }
          //disabled={true}
        >
          <div className={styles["left-menu-icon"]}>{icons.backgroundNoise}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>{translator.leftNavMenu[lang].backgroundNoise}</span></div>
          )}
          {!isWidth && (
            <span className={styles["tooltiptext"]}>{translator.leftNavMenu[lang].backgroundNoise}</span>
          )}
        </button>        
      </div>
      {/*end filters */}
      <hr className={isWidth ? "hr-width" : ""} />
      <div className={styles["theme-btn-container"]}>
        <button onClick={toggleDarkTheme} 
          className={!isWidth ? 
            styles[''] : 
            [styles['btn-width'], styles['btn-circle']].join(' ')}
          >
          <div className={styles["left-menu-icon"]}>{themeIcon}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>{themeBtnText}</span>
            </div>
          )}
        </button>
        {!isWidth && !isItemSelected && (
          <span className={styles["tooltiptext"]}>{themeBtnText}</span>
        )}
      </div>
      <div className={styles["about-btn-container"]}>
        <button onClick={() => selectItem("About")}
          className={!isWidth ? 
            styles[''] : 
            [styles['btn-width'], styles['btn-circle']].join(' ')}
        >
          <div className={styles["left-menu-icon"]}>{sideMenuIcons.about}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>{translator.leftNavMenu[lang].about}</span>
            </div>
          )}
        </button>
        {!isWidth && !isItemSelected && (
          <span className={styles["tooltiptext"]}>
            {translator.leftNavMenu[lang].about}
          </span>
        )}
      </div>
      <div className={styles["faq-btn-container"]}>
        <button onClick={() => selectItem("Faq")}
          className={!isWidth ? 
            styles[''] : 
            [styles['btn-width'], styles['btn-circle']].join(' ')}
        >
          <div className={styles["left-menu-icon"]}>{sideMenuIcons.faq}</div>
          {isWidth && (
            <div className={styles["left-menu-text"]}>
              <span className={styles["btn-width-text"]}>{translator.leftNavMenu[lang].faq}</span>
            </div>
          )}
        </button>
        {!isWidth && !isItemSelected && (
          <span className={styles["tooltiptext"]}>
            {translator.leftNavMenu[lang].faq}
          </span>
        )}
      </div>
      <div className={`${styles["select-container"]} ${isWidth ? styles["select-container-width"]: ""}`}>
          {!isWidth ? (
            <div>
              <select onChange={switchLanguage} 
                value={langCtx.lang} 
                id="changeLang" 
                name="switchLanguage"
              >
                <option value="en">EN</option>
                <option value="bg">BG</option>              
              </select>
            </div>
          ) : (
            <div>
              <select onChange={switchLanguage} 
                value={langCtx.lang} id="changeLang"
                className={styles["width-select"]}
              >
                <option value="en">EN English</option>
                <option value="bg">BG Български</option>              
              </select>
            </div>
          )}
        
      </div>
    </section>
    {!isWidth && (
      <div className={styles["show-width-menu"]}>
        <button className={styles["show-menu-btn"]} onClick={() => setWidth(true)}>{sideMenuIcons.showWidthMenu}</button>
      </div>
    )}
    </>
  );
};

export default SideMenuNav;
