import { configureStore } from "@reduxjs/toolkit";
import currentDeviceReducer from "./CurrentDevice/currentDeviceSlice";
import definedStandardsReducer from "./DefinedStandards/definedStandardsSlice";
import definedIndexesReducer from "./DefinedIndexes/definedIndexesSlice";
import aboutFaqReducer from "./AboutFaq/aboutFaqSlice";
import currentFilterReducer from "./CurrentFilter/currentFilterSlice";

const store = configureStore({
  reducer: {
    currentDevice: currentDeviceReducer,
    definedStandards: definedStandardsReducer,
    definedIndexes: definedIndexesReducer,
    aboutFaq: aboutFaqReducer,
    currentFilter: currentFilterReducer,
  },
});

export default store;
