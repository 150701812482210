import React, { useState, useEffect, useRef } from "react";
import { translator } from "../../../utils/translator";
import styles from "./dataReliability.module.scss";
import { icons } from "../../SideBar/Main/Assets/icons";
import "./baloon.css";

const setInfoText = (lang, device) => {

  return { 
    deviceInfoType: translator.dataReliability[lang].infoType + ': ' + device.category,
    deviceInfoModel: translator.dataReliability[lang].infoModel + ': ' + device.deviceType,
    dataSource: translator.dataReliability[lang].dataSourceTitle + ': ' + device.dataSource.acronym,
    dataProvider: translator.dataReliability[lang].dataProviderTitle + ': ' + device.dataProvider.acronym,
  }
};

const DataReliability = ({ device, lang, isDarkTheme }) => {

  const { reliable } = device.dataSource;

  const icon = reliable ? (!isDarkTheme ? (device.onlineStatus.isOnline ? icons.reliable : icons.reliableOffline) : 
    (device.onlineStatus.isOnline ? icons.reliableDark : icons.reliableDarkOffline)) : 
    (!isDarkTheme ? (device.onlineStatus.isOnline ? icons.notReliable : icons.notReliableOffline) : (device.onlineStatus.isOnline ? icons.notReliableDark : icons.notReliableDarkOffline));

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const verified = reliable ? translator.dataReliability[lang].dataVerified : translator.dataReliability[lang].dataNotVerified;
  // info divs references
  const infoDivs_0 = useRef(null);
  const infoDivs_1 = useRef(null);

  const toggleCollapse = () => {
    setIsCollapseOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if(infoDivs_0.current && infoDivs_1.current){
      if(isCollapseOpen){
        let div0classList = infoDivs_0.current.className;
        infoDivs_0.current.className = div0classList + ' animate';
        let div1classList = infoDivs_1.current.className;
        infoDivs_1.current.className = div1classList + ' animate';
      }
    }
  }, [isCollapseOpen]);
  
  return (
    <div className={styles['reliability-container']}>
      <div className={!isDarkTheme ? styles["item-container"] : styles["item-container-dark"]}>
        <div className={styles["item_main-container"]}>
          <div className={isDarkTheme ? styles["item_icon-container-dark"] : styles["item_icon-container"]}>
            {icon}
          </div>
          <div className={styles["item_verified-container"]}>
            <p className={styles["item_dataSource_key"]}>{translator.dataReliability[lang].dataVerifiedTitle}</p>
            <p className={`${styles["item_dataSource_value"]} ${styles["p-dark"]} `}>{verified}</p>
          </div>
          <button onClick={toggleCollapse}>
            {isCollapseOpen ? icons.upArrow : icons.downArrow}
          </button>
        </div>
        {isCollapseOpen && (
          <>
            <div className={!isDarkTheme ? styles["item-description-container"] : styles["item-description-container-dark"]} ref={infoDivs_0}>
              <div className={!isDarkTheme ? styles["item_icon_description-container"] : styles["item_icon_description-container-dark"] }>
                {icons.deviceInfo}
              </div>
              <div className={styles["item_verified-container"]}>
                <p className={styles["item_dataSource_key"]}>{translator.dataReliability[lang].deviceInfoTitle}</p>
                <p className={`${styles["panel-description"]} ${styles["p-dark"]}`}>
                  {setInfoText(lang, device).deviceInfoType}
                </p>
                {/*<p className={`${styles["panel-description"]} ${styles["p-dark"]}`}>{setInfoText(lang, device).deviceInfoModel}</p>*/}
              </div>
            </div>
            <div className={!isDarkTheme ? 
                  styles["item-description-container"] : 
                  styles["item-description-container-dark"]} 
                  ref={infoDivs_1}>
              <div className={isDarkTheme ? styles["item_icon_description-container-dark"] : styles["item_icon_description-container"]}>
                {icons.dataSource}
              </div>
              <div className={styles["item_verified-container"]}>
                <p className={styles["item_dataSource_key"]}>{translator.dataReliability[lang].dataSourceTitle}</p>
                <a className={!isDarkTheme ? 
                            `${styles["panel-description"]} ${styles["p-dark"]}`:
                            `${styles["panel-description"]} ${styles["a-dark"]}`
                            } 
                  data-balloon-length="large"
                  aria-label={`${device.dataSource.name}`} 
                  href={`${device.dataSource.url}`} 
                  rel="noreferrer" 
                  target="_blank" 
                  data-balloon-pos="up">
                  {setInfoText(lang, device).dataSource}
                </a>
                <a className={!isDarkTheme ? 
                            `${styles["panel-description"]} ${styles["p-dark"]}`:
                            `${styles["panel-description"]} ${styles["a-dark"]}`
                            }
                  data-balloon-length="large"                  
                  aria-label={`${device.dataProvider.name}`}
                  href={`${device.dataProvider.url}`} 
                  rel="noreferrer"
                  target="_blank"
                  data-balloon-pos="up">
                  {setInfoText(lang, device).dataProvider}
                </a>
              </div>
            </div>
          </>
        )}       
      </div>      
    </div>
  );
}

export default DataReliability;