import { icons } from "../Assets/icons";
import styles from "./channelsNav.module.scss";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme-context";
import { translator } from "../../../../utils/translator";

const ChannelsNav = ({
  channels,
  selectedChannelType,
  setSelectedChannelType,
  lang,
}) => {
  // Particle size cannot be selected
  if(selectedChannelType === "ParticleSize"){
    selectedChannelType = "PM25";
  }
  //channels = sortArrByNum(channels, 'sort');  
  const ctx = useContext(ThemeContext);
  let { isDarkTheme } = ctx;
  const createBtns = () => {
    const btns = [];
    let typeIdx = 0, particalIdx = 0, chemIdx = 0;

    channels.forEach((channel) => {
      var classes =
        selectedChannelType === channel.type
          ? styles["active-btn"]
          : isDarkTheme
          ? styles["button_dark"]
          : null;

      let btnIcon = "";
      let index = "";
      let btnTitle = channel.name;

      switch (channel.type) {
        case "Temperature":
          btnTitle = translator.textWidgets[lang].temperatureTitle;
          btnIcon = icons.temperature;
          index = typeIdx;
          typeIdx++;
          break;
        case "Pressure":
          btnTitle = translator.textWidgets[lang].pressureTitle;
          btnIcon = icons.pressure;
          index = typeIdx;
          typeIdx++;
          break;
        case "Humidity":
          btnTitle = translator.textWidgets[lang].humidityTitle;
          btnIcon = icons.humidity;
          index = typeIdx;
          typeIdx++;
          break;
        case "WindSpeed":
          btnTitle = translator.textWidgets[lang].windSpeed;
          btnIcon = icons.windSpeed ?? icons.undef;
          index = typeIdx;
          typeIdx++;
          break;
        case "WindDirection":
          btnTitle = translator.textWidgets[lang].windDirection;
          btnIcon = icons.windDirection ?? icons.undef;
          index = typeIdx;
          typeIdx++;
          break;
        case "PM10":
          btnIcon = icons.pm1;
          index = particalIdx;
          particalIdx++;
          break;
        case "PM25":
          btnIcon = icons.pm2;
          index = particalIdx;
          particalIdx++;
          break;
        case "PM40":
          btnIcon = icons.pm4;
          index = particalIdx;
          particalIdx++;
          break;
        case "PM100":
          btnIcon = icons.pm10;
          index = particalIdx;
          particalIdx++;
          break;
        case "NO2":
          btnIcon = icons.no2;
          index = chemIdx;
          chemIdx++;
          break;

        case "CO":
          btnIcon = icons.co;
          index = chemIdx;
          chemIdx++;
          break;
        case "O3":
          btnIcon = icons.o3;
          index = chemIdx;
          chemIdx++;
          break;
        case "SO2":
          btnIcon = icons.so2;
          index = chemIdx;
          chemIdx++;
          break;
        case "HCHO":
          btnIcon = icons.hcho;
          index = chemIdx;
          chemIdx++;
          break;
        default:
          break;
      }

      btns[index] = (
        <button
          key={index}
          className={classes}
          onClick={() => setSelectedChannelType(channel.type)}
        >
          {btnIcon} {btnTitle}
        </button>
      );
    });

    return btns;
  };

  const buttons = createBtns();

  //channels = sortArrByNum(channels, 'sort');

  return (
    <div className={styles["container"]}>
      <div
        className={
          isDarkTheme ? styles["btns-container-dark"] : styles["btns-container"]
        }
      >
        {buttons}
      </div>
    </div>
  );
};

export default ChannelsNav;
