import React, { useState } from 'react';
import {icons} from '../../assets/appIcons'
import { useContext } from "react";
import ThemeContext from "../../context/theme-context";
import Accordion from "../../context/Accordion";

import styles from "./sideMenuContent.module.scss";


const SideMenuContent = ({ item, closeContent, isWidth, mainTitle }) => {
  const ctx = useContext(ThemeContext);
  const [isActive, setActive] = useState(true);
  // Accordion states
  const [accordionOpen, setAccordionOpen] = useState(0);
  let { isDarkTheme } = ctx;

  const toggleClass = () => {
    setActive(false);
    setTimeout(() => {
      closeContent();
    }, 1000);
  };

  
  // Accordion set isOpen
  const onAccordionClick = (i) => {
    setAccordionOpen(accordionOpen === i ? 0 : i);
  };



  return (
    <div className={isDarkTheme ? 
        isWidth ? isActive ? styles["container-dark"] : styles["container-dark-inactive"] : 
                  isActive ? styles["container-dark-thin"] : styles["container-dark-thin-inactive"] : 
        isWidth ? isActive ? styles.container : styles["container-inactive"] : 
                  isActive ? styles["container-thin"] : styles["container-thin-inactive"]}>
      <div className={styles.closeBtnContainer}>
        <button onClick={toggleClass}>
          {icons.close}
        </button>
      </div>
      <div>
      {React.isValidElement(item) ? item :
        <><h1>{mainTitle}</h1>
        <div className={styles["senstate-accordion"]}>
        {
          item?.map(({ title, content }, index) => {
            
            return <Accordion 
              key={index} 
              title={title} 
              content={content} 
              isActive={accordionOpen > (item.length - 1) ? 0 === index : accordionOpen === index} 
              isDarkTheme={isDarkTheme}
              toggle={() => onAccordionClick(index)} 
            />
          })
        }
        </div>
        </>
      }
      </div>
    </div>
  );
};

export default SideMenuContent;
