import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAboutFaqOpen: false,
};

export const aboutFaqSlice = createSlice({
  name: "aboutFaq",
  initialState,
  reducers: {
    openAboutFaq: (faqState) => {
      faqState.isAboutFaqOpen = true;
    },
    closeAboutFaq: (faqState) => {
      faqState.isAboutFaqOpen = false;
    },
  },
});

export const { openAboutFaq, closeAboutFaq, isAboutFaqOpen } = aboutFaqSlice.actions;

export default aboutFaqSlice.reducer;
