import React, { lazy, Suspense, useEffect, useState, useRef } from "react";
import SideMenu from "./components/SideMenu/SideMenu";
import SideBar from "./components/SideBar/SideBar";
import Map from "./components/Map/Map";
import { useDispatch, useSelector, useStore } from "react-redux";
import { fetchDefinedStandards } from "./redux/DefinedStandards/definedStandardsSlice";
import { fetchDefinedIndexes } from "./redux/DefinedIndexes/definedIndexesSlice";
import { ThemeContextProvider } from "./context/theme-context";
//import Header from "./components/Header/Header";
import { LangContextProvider } from "./context/lang-context";
import { closeDevice } from "./redux/CurrentDevice/currentDeviceSlice";
import { closeAboutFaq } from "./redux/AboutFaq/aboutFaqSlice";
import { mapConfig } from "./components/map.config"; // configuration for active map
import { selectedDeviceType } from "./functions/functions";
const MapBox = lazy(() => import("./components/MapBox/MapBox"));

function App() {
  const store = useStore();
  const { definedStandards, definedIndexes } = store.getState();
  const isDeviceOpen = useSelector((state) => state.currentDevice.isDeviceOpen);
  const isAboutFaqOpen = useSelector((faqState) => faqState.aboutFaq.isAboutFaqOpen);
  // get active map
  const mapIdx = mapConfig.active;
  //const [isSideOpen, setSideState] = useState(false);
  const [urlParsed, setParsed] = useState(false);
  const activeCategory = useRef(0);
  const [categoryChanged, setCategoryChanged] = useState(false);

  let sharedUrl = null; // for mapbox
  let currURL = decodeURI(window.location.href).toString(); 
  if(currURL.search(/#/g) > -1 && currURL.search(/,/g) > -1){
    let splittedUrl = currURL.split('#');
    let uriParams = splittedUrl[1];
    sharedUrl = uriParams.split(','); // [0] - latitude, [1] - longitude, [2] - token, [3] - selected category

    // if opening shared url with device change menu to parsed url category
    if(typeof sharedUrl[3] !== "undefined" && !categoryChanged){
      activeCategory.current = selectedDeviceType(parseInt(sharedUrl[3]));
    }
  }


  const dispatch = useDispatch();
  
  useEffect(() => {

    if (!definedStandards.length) {
      dispatch(fetchDefinedStandards());
    }

    if (!definedIndexes.length) {
      dispatch(fetchDefinedIndexes());
    }

    //if(!urlParsed && sharedUrl !== null){
    //  handleActiveCategory(isNaN(parseInt(sharedUrl[3])) ? 0 : parseInt(sharedUrl[3]));  
    //}

  }, [dispatch, definedIndexes.length, definedStandards.length]);

  const handleAboutFaqOpen = (state) => {
    //setIsOpen(state);
    if(state){
      dispatch(closeDevice());
    }
    setCategoryChanged(false);
  };

  const handleSideBarOpen = (state) => {
    if(state){
      dispatch(closeAboutFaq());
    }
    setCategoryChanged(false);
  }

  // Change urlParsed - get value from Mapbox
  function handleChangeParsed(val) {
    setParsed(val);
    setCategoryChanged(false);
  }


  function handleActiveCategory(val){
    activeCategory.current = val;
    //setCategoryChanged(() => !categoryChanged);
    setCategoryChanged(true);
  }

  return (
    <Suspense fallback={<div className="map-loading"><div className="spinner"></div></div>}>
    <ThemeContextProvider>
      <LangContextProvider>
        <SideMenu onChange={handleAboutFaqOpen} 
                  isAboutFaqOpen={isAboutFaqOpen}
                  activeCategory={activeCategory.current}
                  urlParsed={urlParsed}
                  handleCategory={handleActiveCategory}
         />
        {isDeviceOpen /*&& !isAboutFaq*/ && <SideBar mapIdx={mapIdx} />}
        {mapIdx === 0 && ( // MapTiler
          <Map onChange={handleSideBarOpen} onChangeFaqAbout={handleAboutFaqOpen} />
          )
         }
         {mapIdx === 1 && activeCategory.current !== 0 && ( //Mapbox
          
            <MapBox isDeviceOpen={isDeviceOpen}
                    sharedUrl={sharedUrl}
                    parsed={handleChangeParsed}
                    urlParsed={urlParsed}
                    activeCategory={activeCategory.current}
            />
          )
        }
      </LangContextProvider>
    </ThemeContextProvider>
    </Suspense>
  );
}

export default App;
