import { useContext, useEffect, useState } from "react";
import LineChart from "../Charts/LineChart";
import styles from "./historicalData.module.scss";
import CustomSwitch from "../../CustomSwitch";
import CustomSwitchDark from "../../CustomSwitchDark";
import ThemeContext from "../../../context/theme-context";
import { translator } from "../../../utils/translator";
import { useStore } from "react-redux";

const HistoricalData = ({ channel, hasMinMax, limitArr, lang }) => {

  const darkCtx = useContext(ThemeContext);
  let { isDarkTheme } = darkCtx;

  const [selectedPeriod, setSelectedPeriod] = useState("24 hours");

  const [state, setState] = useState({
    highLow: false,
  });

  const [data, setData] = useState([])

  const [history, setHistory] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handlePeriodSelection = (event) => {
    setSelectedPeriod((prevState) =>
      prevState === "24 hours" ? "30 days" : "24 hours"
    );
  };

  const store = useStore();

  useEffect(() => {
    setHistory(devHasHistory);
  },[channel]);

  const devHasHistory = async () => {
    let isSubscribed = true;
    let channelToken = channel.token;
    const { channelDataURLTemplate } = store.getState().currentDevice.device;
    const channelDataURL = channelDataURLTemplate.replace(
      "{channel}",
      channelToken
    );
    //console.log("History: ", channelDataURL);
    try {
      const response = await fetch(channelDataURL, {
        headers: {
          timeFrame: selectedPeriod === "30 days" ? "lastMonth" : "last24h",
        },
      });

      if (!response.ok) {
        console.log("History data: ", response);
        setHistory(false);
      }

      const data = await response.json();
      //console.log("History data: ", data);
      if(data.length){
        setData(data);
        setHistory(true);
      } else {
        setHistory(false);
      }
    } catch {
      setHistory(false);
    }    

    if(!isSubscribed){
      setHistory(false);
    }    
  }

  if(!history) {
    return null;
  } else {

  return (
    <div className={styles["container"]}>
      {hasMinMax && (
        <div className={styles["btns-container"]}><span>{translator.historicalData[lang][channel.name.toLowerCase()]}</span></div>
      )} 
      <h3 className={isDarkTheme ? styles["h3-dark"] : null}>
        {translator.historicalData[lang].data}
      </h3>      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <div>
          <label className={isDarkTheme ? styles["label-dark"] : null}>
            {translator.historicalData[lang].highLow}
          </label>
          {isDarkTheme ? (
            <CustomSwitchDark
              checked={state.highLow}
              onChange={handleChange}
              name="highLow"
            />
          ) : (
            <CustomSwitch
              checked={state.highLow}
              onChange={handleChange}
              name="highLow"
            />
          )}
        </div>
        <select
          className={isDarkTheme ? styles["select-dark"] : null}
          onChange={handlePeriodSelection}
        >
          <option>{`24 ${translator.textMessages[lang].hours}`}</option>
          <option>{`30 ${translator.textMessages[lang].days}`}</option>
        </select>
      </div>

      <div>
        <LineChart
          channel={channel}
          highLow={state.highLow}
          period={selectedPeriod}
          data={data}
          hasMinMax={hasMinMax}
          limitArr={limitArr}
          lang={lang}
        />
      </div>
    </div>
  );
          }
};

export default HistoricalData;
