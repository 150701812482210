import { useContext, useRef } from "react";
import ThemeContext from "../../../../context/theme-context";
import { translator } from "../../../../utils/translator";
//import { isDataRecent } from "../../../../utils/utils";
import ChannelItem from "../ChannelItem/ChannelItem";
import styles from "./channelItemsList.module.scss";
import { sortAlphaNum, channelsByFilter } from "../../../../functions/functions";

//const recentDataLimitMinutes = 5;
//const recentDataLimitMinutes = 60;

const ChannelItemsList = ({ 
  categoryName, 
  channels,
  lastSubmition,
  isOnline,
  uiCategories,
  lang }) => {

  // Remove channels where type is null (sometimes names are same => like "O3")
  /*
  channels = channels.filter((ch) => {
    return ch.type !== null;
  });
  */

  const themeCtx = useContext(ThemeContext);
  const { isDarkTheme } = themeCtx;
  //console.log("LastTick: ", channels[0]);
  //const { lastTick } = channels[0];

  //const isDataNotRecent = !isDataRecent(lastSubmition, recentDataLimitMinutes);

  const dataNotRecentDiv = useRef(null);

  //const name = translator.textWidgets[lang][categoryName.toLowerCase() + "Title"];
  //const name = categoryName;

  let channelsArr = channelsByFilter(uiCategories);
  let filteredChannels = channels.filter((ch) => {
    return ch.type !== null ? channelsArr.includes(ch.type) : channelsArr.includes(ch.name);
  });

  // let sortedChannels = sortAlphaNum(filteredChannels, lang, 'name');

  const removeOverlay = () => {
    setTimeout(() => {
      if(dataNotRecentDiv.current){
        dataNotRecentDiv.current.innerHTML = '';
      }
    }, 5000);
  };

  return (
    <div className={styles["container"]}>
      <p
        className={
          isDarkTheme ? styles["category-name-dark"] : styles["category-name"]
        }
      >
        {categoryName}:
      </p>
      <div className={styles["items-container"]}>
        {!isOnline && (
          <div ref={dataNotRecentDiv}>  {/* this div is overlay while device is not online (dissapears in 5 sec) */ } 
            <div id="data-not-recent-div" className={styles["no-recent-data-overlay"]}>
              <p>{translator.textMessages[lang].noRecentData}</p>
            </div>
          </div>        
        )}
        {!isOnline && removeOverlay() }
        {filteredChannels.map((channel) => {
          return <ChannelItem 
            key={channel.token} 
            channel={channel}
            isOnline={isOnline}
            lastTick={lastSubmition}
            lang={lang} />;            
        })}
      </div>
    </div>
  );
};

export default ChannelItemsList;
