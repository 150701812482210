import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../../context/Cookies";
import { translator } from "../../utils/translator";

const getLang = () =>{
  //debugger;
  return getCookie('lang');
}

const fetchDefinedStandards = createAsyncThunk(
  "standards/fetchDefinedStandards",
  async (rejectWithValue) => {
    const lang = getLang();
    try{
      const res = await fetch(
        `https://open-data.senstate.cloud/assets/standards?lang=${lang}`
      );

      if (!res.ok) {
        //console.log('definedStandardsSlice: ', res.error);
        throw new Error(translator.textMessages[lang].somethingWrong);
      }

      const data = await res.json();
      //console.log('standarts', data);
      return data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('definedStandardsSlice: ', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = [];

export const definedStandardsSlice = createSlice({
  name: "standards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefinedStandards.pending, (state, action) => {
        
      })
      .addCase(fetchDefinedStandards.fulfilled, (state, action) => {
        state.push(...action.payload);
      })
      .addCase(fetchDefinedStandards.rejected, (state, action) => {
        console.log(action.error.message);
      });
  },
});

export { fetchDefinedStandards };

export default definedStandardsSlice.reducer;
