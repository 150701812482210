export const formatTime = (timeStamp) => {
  let ts = timeStamp;
  if (ts[ts.length - 1] !== "Z") {
    ts += "Z";
  }

  const localDate = new Date(ts);

  let hours = localDate.getHours();
  let minutes = localDate.getMinutes();

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return `${hours}:${minutes}`;
};

export const getLastNumOfDates = (numberOfDates) => {
  let result = [];
  for (let i = 0; i < numberOfDates; i++) {
    let d = new Date();
    d.setDate(d.getDate() - i);
    result.push(formatDate(d));
  }

  return result.reverse();
};

export const formatDate = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  //   let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = dd + "/" + mm;
  return date;
};

/**
 * converts date and time to locale date time format
 * @param {*} dateStr - datetime string in UTC
 * @param {*} lang - lang (for example: en, bg ...)
 * @returns Formatted date time in locale format
 */
export const dateToLocale = (dateStr, lang) => {
  if(dateStr[dateStr.length-1] !== 'Z'){
    dateStr += 'Z';
  }
  const date = new Date(dateStr);

  // "Sat, June 01, 2019"
  return date.toLocaleString(lang === 'bg' ? 'bg-BG' : navigator.language, {
    hour: '2-digit', // "14"
    minute: '2-digit', // "16"
    month: '2-digit', // "03"
    day: '2-digit', // "15"
    year: 'numeric', // "2022"
  });
};