import React, {useState, useEffect, useContext} from "react";
import ThemeContext from "../context/theme-context";
import DataTable from "react-data-table-component";
import { getDataTableDataFromUrl } from "../functions/functions";

const SenstateDataTable = (props) => {

  const [data, setData] = useState(null);
  const { url, lang } = props;

  const themeCtx = useContext(ThemeContext);
  const { isDarkTheme } = themeCtx;

  //const style = !isDarkTheme ? {color: "#121212"} : {color: "#FFFFFF"};

  const columns = [
    {
      name: lang === "en" ? "Data sources" : "Източници на данни",
      selector: row => row.name,
      cell:(row) => <a href={row.url} target="_blank" rel="noreferrer" >{row.name}</a>
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: lang==='en' ? 'Rows per page' : 'Реда на страница',
    rangeSeparatorText: lang==='en' ? 'of' : 'от',
    selectAllRowsItem: false,
  };

  useEffect(() => {
    getDataTableDataFromUrl( url, lang ).then((res) => {
      let data = [];
      res.dataSources.forEach((item, i) => {
            data.push( { 
              id: i,
              name: item.name,
              url: item.url
            });
      });
      // for test
      /*
      data.push(
        {
          id: 6,
            name: 'test',
            url: 'https://senstate.com/map'
        },
        {
          id: 7,
            name: 'test2',
            url: 'https://senstate.com/map'
        },
      )
      */
      setData(data);
    });
  }, []);


  return (
    data !== null ? (
      <DataTable
        columns={ columns }
        data={ data }
        striped={ isDarkTheme ? false : true }
        noTableHead={true}
        pagination={true}
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        responsive={true}
        theme={ isDarkTheme ? 'dark' : 'default' } />
    ) : (
      <div>{lang === 'en' ? 'Loading...' : 'Зарежда се...'}</div>
    )
  );
}

export default SenstateDataTable;