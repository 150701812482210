import styles from "./main.module.scss";
import CategoriesNav from "./CategoriesNav/CategoriesNav";
import React, { useEffect, useMemo, useState } from "react";
import ChannelsNav from "./ChannelsNav/ChannelsNav";
import HistoricalData from "../HistoricalData/HistoricalData";
import ChannelItemsList from "./ChannelItemsList/ChannelItemsList";
import { calcWaterLevel, categoriesByFilter, addSortToCategories, addSortToChannel, sortArrByNum } from "../../../functions/functions";
import { translator } from "../../../utils/translator";


const channelByCategory = (selectedCat, channels) => {
  switch(selectedCat){
    case 'Particulates':
      let hasPm25 = channels.find((ch) => ch.type === 'PM25');
      if(hasPm25){
        return hasPm25;
      } else {
        channels = removeUnnesessary(channels, 'ParticleSize');
        return channels[0];
      }
    case 'Weather':
      return channels.find((ch) => ch.type === 'Temperature');
    case 'Noise':
      removeUnnesessary(channels, 'Radiation');
      return channels.find((ch) => ch.type === 'SoundAvg');
    case 'Gases':
      return channels.find((ch) => ch.type === 'CO');
    default:
      return channels[0];
  }
}

/**
 * Removes object from array by given key
 * @param {*} arr - array
 * @param {*} key - key
 * @returns 
 */
const removeUnnesessary = (arr, key) => {
  arr = arr.filter( (obj) => {
    return obj.type !== key;
  });
  return arr;
}

const Main = ({ categories, lastSubmition, isOnline, limits, uiCategories, isDarkTheme, lang }) => {

  // add sort to category
  categories = addSortToCategories(categories, uiCategories);
  // sort categories by sort num
  categories = sortArrByNum(categories, 'sort', 'asc');

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  //const selectedCategory = sortAlphaNum(categories[0], lang);

  let channels = addSortToChannel(selectedCategory.channels, selectedCategory.type);
  channels = sortArrByNum(channels, 'sort', 'asc');

  const {/*channelType, hasLimitRecord,*/ limitRecord} = limits;
  // Remove channels where type is null (sometimes names are same => like "O3")
  /*
  channels = channels.filter((ch) => {
    return ch.type !== null;
  });
  */
  // console.log("Main js categories: ", categories);
  // Filter channels for each category type
  categories = categoriesByFilter(uiCategories, categories);
  const limitRecordArr = JSON.parse(limitRecord);
  //console.log('limitRecArr', limitRecordArr);

  channels = channels.filter((v,i,a)=>
    a.findIndex(t=>(t.name===v.name))===i
  );

  // Remove unneeded channel from Noise
  if(uiCategories === '4'){
    channels = removeUnnesessary(channels, 'Radiation');
  }

  const [selectedChannel, setSelectedChannel] = useState(channelByCategory(selectedCategory.type, sortArrByNum(selectedCategory.channels, 'sort', 'asc')));  

  useEffect(() => {


    const prevCategory = categories.find(
      (cat) => cat.type === selectedCategory.type
    );

    if (prevCategory) {
      let hasCat = categories.find((cat) => cat.type === prevCategory.type);
      if(hasCat){   
        setSelectedCategory(prevCategory);
      } else {
        setSelectedCategory(categories[0]);
      }

      let prevChannel;
      // ************ For Noise ***************** //
      if(uiCategories !== '4' && selectedChannel){
        let hasChan = selectedCategory.channels.find((ch) => ch.type === selectedChannel.type);
        if(hasChan){
          prevChannel = hasChan;
        } else {
          prevChannel = selectedCategory.channels[0];
        }
      } else {
        prevChannel = selectedCategory.channels[0];
      }
      // Particles set 2.5
      if(prevChannel.type === 'ParticleSize'){
        let pm25 = prevCategory.channels.find(c => c.type === 'PM25');
        if(pm25){
          prevChannel = pm25;
        } else {
          prevChannel = prevCategory.channels[0];
        }
      }
      // Weather set temperature
      /*
      if(prevCategory.type === 'Weather'){
        prevChannel = prevCategory.channels.find(c => c.type === 'Temperature');
      }
      */
      
      if(prevChannel !== undefined){
        if(uiCategories !== '4'){
          setSelectedChannel(prevChannel);
        } else {
          setSelectedChannel(channelByCategory(selectedCategory.type, channels));
        }
      } else {
        setSelectedChannel(channelByCategory(selectedCategory.type, channels) /*selectedCategory.channels[0]*/);
      }
    } else {
      //debugger;
      // set selected category by ui type

      setSelectedCategory(categories[0]);
        
        let selectedChan;
        if(uiCategories === '2' && categories[0].type === 'Particulates'){
          selectedChan = categories[0].channels.find(c => c.type === 'PM25');
        } else if (uiCategories === '2' && categories[0].type === 'Weather'){
          selectedChan = categories[0].channels.find(c => c.type === 'Temperature');
        } else {
          selectedChan = categories[0].channels[0];
        }
        setSelectedChannel(selectedChan);        
      //}
    }
  }, [selectedCategory.type, channels, lang]);

  const handleCategorySelection = (type) => {
    let currCategory = categories.find((category) => category.type === type);
    if (currCategory.type === "Particulates") {
      const newChannels = currCategory.channels.filter(
        (channel) => channel.type !== "ParticleSize"
      );
      currCategory = { ...currCategory, channels: newChannels };
    }
    setSelectedCategory(currCategory);
    if(currCategory.channels.length){
      let chls = addSortToChannel(currCategory.channels, currCategory.type);
      chls = sortArrByNum(chls, 'sort', 'asc');
      setSelectedChannel(channelByCategory(currCategory.type, chls));
    }  
  };

  const handleChannelSelection = (type) => {
    const currChannel = channels.find((channel) => channel.type === type);
    setSelectedChannel(currChannel);
  };

  const historicalData = useMemo(() => {

    // remove radiation from Noise
    
    if(selectedChannel === undefined){      
      if(!selectedCategory.channels.length){        
        setSelectedChannel(channelByCategory(selectedCategory.type, sortArrByNum(selectedCategory.channels, 'sort', 'asc')));
      }
      /*
      if(selectedCategory.type === 'Particulates'){
        // jump over Particle size
        if(selectedCategory.channels[0].type === 'ParticleSize' && selectedCategory.channels.length > 1){
          let pm25 = selectedCategory.channels.find(ch => ch.type === 'PM25');
          if(pm25){
            setSelectedChannel(pm25);
          } else {
            setSelectedChannel(selectedCategory.channels[0]);
          }
        }
      }
      if(selectedCategory.type === 'Weather'){
        // jump over Particle size
        if(selectedCategory.channels.length > 1){
          setSelectedChannel(selectedCategory.channels.find(ch => ch.type === 'Temperature'));
        }
      }
      if(selectedCategory.type === 'Noise'){
        // jump over Particle size
        if(selectedCategory.channels.length > 1){
          setSelectedChannel(selectedCategory.channels.find(ch => ch.type === 'SoundAvg'));
        }
      }
      if(selectedChannel === undefined){
        setSelectedChannel(selectedCategory.channels[0]);
      }
      */
    }
    
    const currChannel = channels.find(
      (channel) => selectedChannel !== undefined ? channel.type === selectedChannel.type : null
    );
    // if has selected channel -> return Historical data
    if(currChannel !== undefined){
      return <HistoricalData 
                channel={currChannel} 
                hasMinMax={uiCategories === "2" ? true : false} 
                limitArr={limitRecordArr} 
                lang={lang}                  
              />;
    }
    return "";
  }, [channels, categories, lang]);

  let categoryTypes = categories?.map(({ type }) => type);

  // Remove channels where type is null (sometimes names are same => like "O3")
  /*
  channels = channels.filter((ch) => {
    return ch.type !== null;
  });
  
  channels = channels.filter((v,i,a)=>
    a.findIndex(t=>(t.name===v.name))===i
  );
  /*
  let channelsNames = channels.map(({ name }) => name);

  channelsNames = sortAlphaNum(channelsNames, lang);

  let channelsTypes = channels.map(({ type }) => type);
  */
  //let allChannels = channels;

  //console.log('channels', categories);
  // set selected channel to curr category channel 0 if undefined
  /*
  if(selectedChannel === null || typeof(selectedChannel) === "undefined"){
    setSelectedChannel(selectedCategory.channels[0]);
  }
  */

  /*********** WATER CLASSES ***************/
  let barClass = "water-level-bar-short";
  let moreBar = -1; // generate one more bar at left or right (-1 - no risk, 0 - left risk, 1 right risk)
  /*********** END WATER CLASSES ***************/

  /**
   * Returns JSX for water dept bar
   * @param {*} low - low value
   * @param {*} high - high value
   * @returns 
   */
  const barHtml = (low, high, moreBar, val) => {
    let bars = [];
    let barValues = [];
    let strLength = high.length + 1; // 1 for decimal
    if(low === null){
      low = 0;
    }
    //high = 500;
    //low = 0;
    // calculate high value length as string
    if(strLength % 2 === 0){ // if odd, make even
      strLength += 1;
    }
    let intermed = ((high - low) / 8);
    if((high - low) === 0){
      intermed = high / 2;
    }
    for(let i=0, j=low+intermed; i<7; i++){
      if(i%2 === 0){
        bars.push(<span key={i+1} className={styles["bar-short"]}>|</span>);
        barValues.push(<span key={i+10}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);
      } else {
        bars.push(<span key={i+1}>|</span>);
        barValues.push(<span key={i+10}>{j.toFixed(1)}</span>);
      }
      j = j + intermed;
    }
    let html = (
      <>
        <div key={0} className={`${styles["bar-separator-div"]} ${isDarkTheme ? styles["dark"] : ""}`}>
          {moreBar === 0 && <span key={100}>|</span>}
          <span key={0}>|</span>
          {bars}
          <span key={8}>|</span>
          {moreBar === 1 && <span key={101}>|</span>}
        </div>
        <div key={1} className={`${styles["bar-separator-div"]} ${isDarkTheme ? styles["dark"] : ""}`}>
          {moreBar === 0 && <span key={200}>0.00</span>}
          <span key={9}>{calculateWhiteSpace(low, high)}{low.toFixed(1)}{calculateWhiteSpace(low, high)}</span>
          {barValues}
          <span key={19}>{high.toFixed(1)}</span>
          {moreBar === 1 && <span key={100}>{val.toFixed(1)}</span>}
        </div>
      </>
    );
    return html;
  }

  const calculateWhiteSpace = (val, high) => {
    if (val === null) {
      val = 0;
    }
    let valLength = val.toString().length; // .0 (decimal)
    let length = high.toString().length;
    let whiteSpace = '';
    for(let li=0; li<Math.floor(length-valLength)/2; li++){
      whiteSpace += "\u00a0";
    }
    return whiteSpace;
  }

  let barObj = null; // if uiCategories === 2 (Water) generates bar at the bottom
  if(uiCategories === "2"){    
    if(selectedChannel !== undefined){
      /******************* FOR TEST ******************************/
      // selectedChannel.value = -20 // hardcoded value
      // limitRecordArr.LowerLimitEnabled = true;
      /******************* END TEST **********************************/
      if(selectedCategory.type === "General" && limitRecordArr.UpperLimitEnabled){
        
        // assign color class
        if(limitRecordArr.LowerLimitEnabled) {  // generate 9 colors
          barClass = "water-level-bar-long";
          if(selectedChannel.value < limitRecordArr.LowerLimitValue){
            barClass = "water-level-bar-long-left-risk";
            moreBar = 0;
          }
          if(selectedChannel.value > limitRecordArr.UpperLimitValue){
            barClass = "water-level-bar-long-right-risk";
            moreBar = 1;
          }         
        } else {  // generate 5 colors
          barClass = "water-level-bar-short";
          if(selectedChannel.value > limitRecordArr.UpperLimitValue){
            barClass = "water-level-bar-short-right-risk";
            moreBar = 1;
          } 
        }
        barObj = calcWaterLevel(selectedChannel.value, limitRecordArr.UpperLimitValue, limitRecordArr.LowerLimitEnabled ?     limitRecordArr.LowerLimitValue : null, moreBar < 0 ? false : true);
      }
    }
  } else {
    //console.log('Main js selectedCategory: ', selectedCategory);
  }

  /**
   * Returns keywords for SEO
   * @param {*} device 
   * @returns 
   */
  const getKeywords = (channels) => {
    let allParams = '';
    if(uiCategories === '3'){
      allParams = channels.filter(a => a.type.toLowerCase() === 'radiation');
      allParams = allParams.map(a => a.name.toLowerCase()).join(',').trim();
    } else {
      allParams = channels ? (channels.map(a => a.name.toLowerCase()).join(',').trim()) : '';
    }
    if(allParams.endsWith(',')){
      allParams = allParams.substring(-1);
    }
    return allParams;
  }

  // console.log("newArr:", Filter(uiCategories, selectedCategory.channels));
  channels = sortArrByNum(channels, 'sort', 'asc');

  /**
   * Add device parameters to keywords tag
   */
  let keyWordTag = document.querySelector('[name="keywords"]');
  let newKeywords = getKeywords(channels);
  let already = keyWordTag.content.includes(newKeywords);
  keyWordTag.content = `${keyWordTag.content.trim()}${!already ? newKeywords.trim() : ''}`;

  return (
    <div className={styles["container"]}>
      <CategoriesNav
        categoryTypes={categoryTypes}
        categories={categories}
        selectedCategoryType={selectedCategory.type}
        setSelectedCategoryType={handleCategorySelection}
        lang={lang}
      />
      {selectedChannel && (
      <ChannelsNav
        channels= {channels}
        selectedChannelType={selectedChannel.type}
        setSelectedChannelType={handleChannelSelection}
        lang={lang}
      />
      )}      
      {historicalData}
      {selectedChannel && (
      <ChannelItemsList
        categoryName={selectedCategory.name}
        channels={selectedCategory.channels}
        selectedChannelType={selectedChannel.type}
        lastSubmition={lastSubmition}
        isOnline={isOnline}
        uiCategories={uiCategories}
        lang={lang}
      />
      )}
      {barObj !== null &&
      <>
        <div className={`${styles["water-level-container"]} ${isDarkTheme ? styles["dark"]: ""}`}>
          <p className={styles["category-name"]}>
            {translator.textMessages[lang].levelIndicator}:
          </p>
          <div className={styles[barClass]}>
            <span className={styles["level-indicator"]} 
              style={{ left: barObj.percent }}
              title={barObj.tooltip}
            >
            </span>
          </div>
          { barHtml(limitRecordArr.LowerLimitEnabled ? 
              limitRecordArr.LowerLimitValue : 
              null, limitRecordArr.UpperLimitValue, 
              moreBar,
              selectedChannel.value) }
        </div>
        { /* show risk level percent */ }
        <div className={styles["water-level-container"]}>
          <div className={`${styles["percentage-container"]} ${isDarkTheme ? styles["dark"] : ""}`} style={{ background: barObj.backColor }}>
            <div className={styles["percentage-items"]}>
              <div className={styles["fill-text"]}>            
                <p
                  className={
                    isDarkTheme
                      ? styles["level-text-dark"]
                      : styles["level-text"]
                  }
                >
                  {translator.waterLevelTexts[lang][barObj.levelIndicator]}
                </p>
              </div>
              <div className={styles["fill-percentage"]}>
                <span
                  className={styles[""]}
                  //style={{ width: `$` }}
                >{barObj.tooltip}</span>
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </div>
  );
};

export default Main;
