import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import { useStore } from "react-redux";
import { formatDate, formatTime } from "../../../utils/timeAndDate";
import ThemeContext from "../../../context/theme-context";
import { translator } from "../../../utils/translator";

import styles from "./lineChart.module.scss";
import Loader from "react-loader-spinner";

const LineChart = (props) => {
  //console.log("History props: ", props.data);
  const darkCtx = useContext(ThemeContext);
  let { isDarkTheme } = darkCtx;

  const lang = props.lang;

  const { period } = props;
  const { token: channelToken, name: channelName, suffix } = props.channel;

  const [chartState, setChartState] = useState({ labels: [], data: [] });

  const store = useStore();
  const { channelDataURLTemplate } = store.getState().currentDevice.device;
  const channelDataURL = channelDataURLTemplate.replace(
    "{channel}",
    channelToken
  );

  //console.log("channelDataUrl: ", channelDataURL);

  const chartData = () => {
    const { labels, data } = chartState;
    const datasets = [];
    if (props.highLow) {
      datasets.push({
        label: translator.statesText[lang].high,
        data,
        parsing: {
          yAxisKey: "x",
        },
        borderColor: "rgba(79,196,202, .2)", // "#4FC4CA",
        backgroundColor: "rgba(56,182,188,.2)", // "#38b6bc",
        pointRadius: 3,
        fill: "-1",
        order: 10,
      });
      datasets.push({
        label: translator.statesText[lang].low,
        data,
        parsing: {
          yAxisKey: "y",
        },
        borderColor: "rgba(79,196,202, .2)", //"#4FC4CA",
        backgroundColor: "rgba(56,182,188,.2)",
        pointRadius: 3,
        fill: "-1",
        order: 10,
      });
    }

    datasets.push({
      label: translator.statesText[lang].avarage,
      data,
      parsing: {
        yAxisKey: "z",
      },
      borderColor: isDarkTheme && !props.highLow ? "#4FC4CA" : "#16123F",
      backgroundColor: isDarkTheme && !props.highLow ? "#4FC4CA" : "#16123F",
      borderLine: [5, 5],
      //pointRadius: 0,
      pointRadius: 2.5,
      borderWidth: 2,
      order: 1,
    });

    return {
      labels: labels,
      datasets,
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    const getData = async () => {
      setIsLoading(true);
      const response = await fetch(channelDataURL, {
        headers: {
          timeFrame: period === "30 days" ? "lastMonth" : "last24h",
        },
      });

      if (!response.ok) {
        throw new Error(translator.textMessages[lang].somethingWrong);
      }

      const data = await response.json();
      const loadedLabels = [];

      const currentValues = data?.map(({ high, low, average, timeStamp }) => {
        if (period === "30 days") {
          const currentDate = new Date(timeStamp);
          const day = formatDate(currentDate);
          loadedLabels.push(day);
        } else if (period === "24 hours") {
          const hour = formatTime(timeStamp);
          loadedLabels.push(hour);
        }

        return { x: high, y: low, z: average };
      });

      if(isSubscribed){
        setIsLoading(false);
        setError(false);
        setChartState({ labels: loadedLabels, data: currentValues });
      }
    };

    getData().catch((e) => {
      if (isSubscribed) {
        setIsLoading(false);
        setError(true);
      }
    });

    return () => (isSubscribed = false);

    /*
    const [state, setState] = useState(initialState);

    useEffect(() => {
    // clean up controller
    let isSubscribed = true;

    // Try to communicate with sever API
    fetch(SERVER_URI)
      .then(response => response.json())
      .then(data => isSubscribed ? setState(prevState => ({
        ...prevState, user: data
      })) : null)
      .catch(error => {
        if (isSubscribed) {
          setState(prevState => ({
            ...prevState,
            error
          }));
        }
      })

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, []);
  */
  }, [period, channelDataURL, lang]);

  //console.log('lineChart', suffix);
  const noRecentOverflow = () => {
    return(
      <div className={styles["no-recent-history-overlay"]}>
        <p>{translator.textMessages[lang].noHistoricalData}</p>  
      </div>
    );
  }

  return (
    <div className={styles["chart-container"]}>
      {isLoading && (
        <div>
          <Loader
            className={styles.loader}
            type="Oval"
            color="rgba(22, 18, 63, 1)"
            height={30}
            width={30}
          />
        </div>
      )}
      {!isLoading && /*!error &&*/ (
        <Line
          data={chartData()}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (item) =>
                   `${item.formattedValue} ${suffix}`,
                },
              },
              title: {
                display: false,
                text: `${channelName} ${translator.textMessages[lang].last} ${period} ${translator.textMessages[lang].in} ${suffix}`,
              },
            },
            scales: {
              yAxes: {
                ticks: {
                  callback: function (value, index, values) {
                    return `${value.toFixed(2)} ${suffix}`;
                  },
                  beginAtZero: true,
                  color: isDarkTheme ? "#FFFFFF" : "#16123F",
                  fontWeight: 700,
                  
                },
                min: props.hasMinMax ? 0 : null,
                max: props.hasMinMax ? props.limitArr.UpperLimitValue : null,
              },
              xAxes: {
                ticks: {
                  beginAtZero: true,
                  color: isDarkTheme ? "#FFFFFF" : "#16123F",
                  fontWeight: 700,
                },
              },
            },
          }}
        />
      )}
      {error && noRecentOverflow()}  
    </div>
  )
};

export default LineChart;
