/* eslint import/no-webpack-loader-syntax: off */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { latLng /*, latLngBounds*/} from 'leaflet';
/* import mapbox */
//import mapboxgl from '!mapbox-gl';
//import MapGL from "react-map-gl"
import './map.css';
/* end mapbox */
import Pin from "./Pin";

import styles from "./map.module.scss";
import ThemeContext from "../../context/theme-context";
import Loader from "react-loader-spinner";
import ErrorModal from "../ErrorModal/ErrorModal";
import LangContext from "../../context/lang-context";
import { translator } from "../../utils/translator";
import { getCookie, setCookie } from "../../context/Cookies";
import { useSelector } from "react-redux";
import { mapConfig } from "../map.config"; // configuration for active map
//import { map } from "lodash";


const mapIdx = mapConfig.active;
const mapURL = mapConfig[mapIdx].url;
const attrib = mapConfig[mapIdx].attrib;

const updateMapFrequencySeconds = 60;
// mapbox-gl
//mapboxgl.accessToken = 'pk.eyJ1IjoiemhyaXN0b3YiLCJhIjoiY2t4cThqdTloMWVobzJ2cDdpZ3BudDJjciJ9.se1zi0wwIgMhNuM90JJ4Nw';
//const TOKEN = 'sk.eyJ1IjoiemhyaXN0b3YiLCJhIjoiY2t4cnM5NHd1MHdtNDJwbXZ3cjlxdXZqYSJ9.p-9UlJs7aL88FAoZW2USng';
//const TOKEN = 'sk.eyJ1Ijoic2Vuc3RhdGUiLCJhIjoiY2t4dm05ZWM0NDhoNzJxdWI4cGhjMW5kMSJ9.VLmXgm3psUfZ97lspSfs5A';

const Map = (props) => {
  const langCtx = useContext(LangContext);
  const { lang } = langCtx;
  const themeCtx = useContext(ThemeContext);
  const { isDarkTheme } = themeCtx;
  const tileLayerRef = useRef();
  //const [map, setMap] = useState(null);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedFilter, filterName } = useSelector((state) => state.currentFilter);
  //const [selectedMap, setSelectedMap] = useState('https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=a2uJClaYtUvdcFdoXNJZ');


  /********************* Is there GET from Facebook or LinkedIn ***************************/
  //console.log("filter: " , selectedFilter);
  //console.log("filters: ", filterName);
  const [urlParsed, setParsed] = useState(false);
  let getUri = null;
  let currURL = decodeURI(window.location.href).toString();
  if(!urlParsed){
    if(currURL.search(/#/g) > -1 && currURL.search(/,/g) > -1){
      let splittedUrl = currURL.split('#');
      let uriParams = splittedUrl[1];
      getUri = uriParams.split(','); // [0] - latitude, [1] - longitude, [2] - token
      currURL = `${window.location.origin}/map`;      
    }
  }

  // mapbox-gl
  //const map = useRef(null);
  //const mapContainerRef = useRef(null);
  //const [lng, setLng] = useState(25.291);
  //const [lat, setLat] = useState(42.753);
  //const [zoom, setZoom] = useState(8);
  
  // End mapbox-gl

  //debugger;
  let mapCenterCookie = getCookie('coords');
  if(mapCenterCookie === null){
    mapCenterCookie = [42.753, 25.291];
  } else {
    let splitLatLng = mapCenterCookie.split(',');
    let lat = parseFloat(splitLatLng[0]);
    let lng = parseFloat(splitLatLng[1]);
    mapCenterCookie = [lat, lng];
  }
  const [mapCenter, setMapCenter] = useState(mapCenterCookie);
  let zoomCookie = getCookie('zoom');
  const [zoomLevel, setZoomLevel] = useState(zoomCookie === null ? 8 : parseInt(zoomCookie));

  // coordinates array to fit bounds
  const [m, setMap] = useState(null);
  const changeBounds = useRef(false);

  function hasClass(ele,cls) {
    return !!ele.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
  }
  
  function addClass(ele,cls) {
    if (!hasClass(ele,cls)) ele.className += " "+cls;
  }
  
  function removeClass(ele,cls) {
    if (hasClass(ele,cls)) {
      var reg = new RegExp('(\\s|^)'+cls+'(\\s|$)');
      ele.className=ele.className.replace(reg,' ');
    }
  }

  const handleFaqAboutOpen = (state) => {
    props.onChangeFaqAbout(state);
  }

  const setMapStyle = () => {
    let tilesImg = document.getElementsByClassName('leaflet-tile');
    let mapdark = document.getElementById('map');
    if(tilesImg.length){
      if(isDarkTheme){
        for(let i = 0; i < tilesImg.length; i++) {
          let currStyle = hasClass(tilesImg[i], 'darkTile');
          if(!currStyle){
            removeClass(tilesImg[i], 'liteTile')
            addClass(tilesImg[i], "darkTile");
          }
          if(mapdark && !hasClass(mapdark, 'mapDark')){
            addClass(mapdark, 'mapDark');
          }
        }
      } else {
        for(let j = 0; j < tilesImg.length; j++) {
          let currStyle = hasClass(tilesImg[j], 'liteTile');
          if(!currStyle){
            removeClass(tilesImg[j], 'darkTile');
            addClass(tilesImg[j], 'liteTile');
          }
          if(mapdark && hasClass(mapdark, 'mapDark')){
            removeClass(mapdark, 'mapDark');
          }
        }
        //setCurrTheme(false);
      }
    }
  };
  

  useEffect(() => {    

    /*
    setSelectedMap(
      !isDarkTheme ?
      'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=a2uJClaYtUvdcFdoXNJZ':
        `https://api.mapbox.com/styles/v1/senstate/ckxvm2cwy0bpp15t7767izh3g/tiles/{z}/{x}/{y}?access_token=${TOKEN}`
    );
    */
    
    setMapStyle();

    const fetchDevices = async () => {
      setIsLoading(true);
      //const response = await fetch("https://open-data.senstate.cloud/devices");
      //const response = await fetch("https://open-data.senstate.cloud/mapdata/");
      //console.log("categories: ", "https://open-data.senstate.cloud/mapdata-v2/?category=" + filterName + "");
      const response = await fetch("https://open-data.senstate.cloud/mapdata-v2/?category=" + filterName + "");
      
      if (!response.ok) {
        setIsLoading(false);
        throw new Error(translator.textMessages[lang].somethingWrong);
      }

      const data = await response.json();

      const loadedDevices = [];
      for (const key in data) {
        loadedDevices.push(data[key]);        
      }

      // set map url
      //console.log('in MAP - device:', loadedDevices)
      if(changeBounds.current) {
        handleBoundChange(loadedDevices);
      }

      setError(false);
      setDevices(loadedDevices);
      setIsLoading(false);      
    };

    fetchDevices().catch((e) => setError(true));

    const intervalId = setInterval(() => {
      fetchDevices().catch((e) => setError(true));
    }, 1000 * updateMapFrequencySeconds);

    return () => {
      clearInterval(intervalId);  
    };

  }, [lang, zoomLevel, selectedFilter]);

  useEffect(() => {
    setMapStyle();    
  },[isDarkTheme]);

  /**
   * Change map coordinates if devices not visible
   * @param {*} devArr - array of devices (markers)
   * @returns - if no devices returns to previous, else nothing, just flyTo
   */
  const handleBoundChange = (devArr) => {
    if(m && devArr.length){
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let center = m.getCenter();
      //let lat = 9999999.00, lng = 9999999.00;
      //setTimeout(() => {
      //let markerBounds = [];
      try {
        
        let closestDevice = devArr.reduce((b, a) => {
            var distanceB = latLng(center).distanceTo(latLng(b.location.latitude, b.location.longtitude))/1000;
            var distanceA = latLng(center).distanceTo(latLng(a.location.latitude, a.location.longtitude))/1000;
            
            //distances.push(distance);
            return Math.abs(distanceB) < Math.abs(distanceA) ? b : a;

        });
        
        let currentBounds = m.getBounds();
        let inBounds = currentBounds.contains(latLng(closestDevice.location.latitude, closestDevice.location.longtitude));
                              
        if(!inBounds) {
          let markerBounds = latLng(closestDevice.location.latitude, closestDevice.location.longtitude);
          //let bounds = latLngBounds(markerBounds);
          //m.flyToBounds(bounds);
          m.flyTo(markerBounds, zoomLevel,{
            animate: true,
            duration: 3
          });
        }
      } catch {
        return;
      }
    }
  };

  useEffect(() => {
    changeBounds.current = true;
  }, [selectedFilter]);

  const pins = devices.map((device) => {
    let showInfo = ''; 
    if(getUri && getUri[2] === device.token){
      showInfo = getUri[2];
      getUri = null; // clean facebook redirect
    }
    //markerBounds.push(latLng(device.location.latitude, device.location.longtitude));
    return <Pin 
      key={device.token} 
      id={device.token}
      device={device}
      lang={lang}
      zoomLevel={zoomLevel}
      getUrlDevice = {showInfo}
      onChangeFaqAbout={handleFaqAboutOpen}     
    >
    </Pin>;
    
  });

  let tileLayerClasses = tileLayerRef.current && (isDarkTheme ? styles.darkMode : styles.lightMode);

  const tileLayerKey = useMemo(() => {
    return Math.random().toString();
  }, []);

  //set zoom cookie
  const setNewZoom = (ev) => {
    //debugger;
    let curr_zoom = ev.target._zoom;

    if(isNaN(curr_zoom)){
      curr_zoom = 8;
    }
    setCookie('zoom', curr_zoom);
    setZoomLevel(curr_zoom);
    // map center cookie
    let { lat, lng } = ev.target.getCenter();
    let coord = [lat, lng].toString();
    setCookie('coords', coord);
    changeBounds.current = false;
    //setMapStyle();
    //let circle = L.circle
    //console.log('Zoom event:', ev);
  }

  // set map center cookie on dragend
  const setDraggedPlace = (ev) => {    
    let { lat, lng } = ev.target.getCenter();
    let coord = [lat, lng].toString();
    setCookie('coords', coord);
    setMapCenter(coord);
    // set zoom cookie
    let curr_zoom = ev.target._zoom;

    if(isNaN(curr_zoom)){
      curr_zoom = 8;
    }
    changeBounds.current = false;
    setCookie('zoom', curr_zoom);
 
  }

  //let mapURL = `https://api.mapbox.com/styles/v1/${selectedMap}/tiles/{z}/{x}/{y}?access_token=${TOKEN}`; // mapbox (paied)
  //let mapURL = 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=a2uJClaYtUvdcFdoXNJZ';
  
  
  /*
  let mapURL = 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}@2x.png?key=a2uJClaYtUvdcFdoXNJZ'; // HDPI
  let attrib = '© <a target="_blank" href="https://www.maptiler.com/copyright/">MapTiler</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  */

  if(isDarkTheme) {
    setMapStyle();
    //mapURL = `https://api.mapbox.com/styles/v1/senstate/ckxvm2cwy0bpp15t7767izh3g/tiles/{z}/{x}/{y}?access_token=${TOKEN}`;
    //mapURL = 'https://api.maptiler.com/maps/SenstateDark/{z}/{x}/{y}.png?key=a2uJClaYtUvdcFdoXNJZ';
  }

  return (
    <>
      {error && <ErrorModal />}

      <div className="map-wrap">
      { /* map container */}
      <div className={styles.container} id="map" >      
        <MapContainer
          className="map"
          //center={[42.753, 25.291]}
          center={mapCenter}
          maxZoom={17}
          maxNativeZoom={17}
          minZoom={3}
          zoom={zoomLevel} 
          scrollWheelZoom={true}
          zoomControl={false}
          id="map-container"
          whenCreated={ map => setMap(map) }
          whenReady={(map) => {
            // check if get from Facebook or LinkedIn
            if(getUri !== null && getUri.length > 1){
              setTimeout( () => {
                map.target.flyTo([getUri[0], getUri[1]], 14,{
                  animate: true,
                  duration: 3
                });
              }, 1000);              
              if (!urlParsed) {
                //prevents browser from storing history with each change:
                setTimeout(() => {
                  //window.history.pushState({}, null, currURL);
                  getUri = null;
                  setParsed(true);
                }, 2000);
              }
            }
            else if ('geolocation' in navigator && mapCenter === [42.753, 25.291]) {
              navigator.geolocation.getCurrentPosition(function (location) {
                console.log(location);
                map.target.flyTo([location.coords.latitude, location.coords.longtitude], zoomLevel, {
                  animate: true,
                  duration: 3
                });
  
                //var marker = L.marker([location.coords.latitude, location.coords.longtitude], {icon: L.icon({
                //  iconUrl: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                //  iconSize:     [38, 38], // size of the icon
                //  iconAnchor:   [42.753, 25.291], // point of the icon which will correspond to marker's location
                //  popupAnchor:  [0, -50],
                  
                //})} ).addTo(map.target);
                //marker.bindPopup("Your current location");
                //marker.addEventListener('mouseover', handleMouseOver());
                //marker.openPopup()
              });
            }
            else {
              
            }
  
            // set coords Cookie
            map.target.on("dragend", function (e) {
              setDraggedPlace(map);
            });
  
            // set zoom Cookie
            map.target.on("zoomend", function (e) {
              setNewZoom(map);
            });
            
          }}

        >          
          <TileLayer
            ref={tileLayerRef}
            key={tileLayerKey}
            className={tileLayerClasses}
            url={mapURL}
            attribution={attrib}
            zoomOffset={-1}
            tileSize={512}
            crossOrigin={true}
            reuseTiles={zoomCookie !== null ? true : false}
          />
          {isLoading ? (
            <Loader
              className={styles.loader}
              type="Oval"
              color="rgb(0 120 255)"
              height={30}
              width={30}
            />
          ) : (            
            pins
          )}          
        </MapContainer>
          {selectedFilter === 1 && 
            <div      
              className={`senstate-caqi ${styles["index-colors-container"]} ${
                isDarkTheme && styles["i-c-c-dark-theme"]
              }`}
            >
            <div className={styles["index-name-container"]}>Senstate CAQI</div>
            <div
              className={styles["index-very-low-container"]}
              style={{ backgroundColor: "#79BC6A" }}
            >
                  <span className={styles["tooltiptext"]}>
                    {translator.statesText[lang].veryLow}
                  </span>
            </div>
            <div
              className={styles["index-low-container"]}
              style={{ backgroundColor: "#BBCF4C" }}
                    >
                  <span className={styles["tooltiptext"]}>
                      {translator.statesText[lang].low}
                  </span>
            </div>
            <div
              className={styles["index-medium-container"]}
              style={{ backgroundColor: "#EEC20B" }}
            >
              <span className={styles["tooltiptext"]}>
                {translator.statesText[lang].medium}
              </span>
            </div>
            <div
              className={styles["index-high-container"]}
              style={{ backgroundColor: "#F29305" }}
                    >
              <span className={styles["tooltiptext"]}>
                {translator.statesText[lang].high}
              </span>
            </div>
            <div
              className={styles["index-very-high-container"]}
              style={{ backgroundColor: "#E8416F" }}
                    >
              <span className={styles["tooltiptext"]}>
                {translator.statesText[lang].veryHigh}
              </span>
            </div>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default Map;
