import { icons } from "../Assets/icons";
import styles from "./categoriesNav.module.scss";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme-context";
//import { translator } from "../../../../utils/translator";

const CategoriesNav = ({
  categoryTypes,
  categories,
  selectedCategoryType,
  setSelectedCategoryType,
  lang
}) => {
  const themeCtx = useContext(ThemeContext);
  const { isDarkTheme } = themeCtx;
  const buttons = [];

  //categoryTypes = sortAlphaNum(categoryTypes, lang, 'type');
  //categories = sortAlphaNum(categories, lang, 'name');

  // !!!!!!!!!!!!!!!1 Change forEach with categories    !!!!!!!!!!!!!!!!!!!!!!!! //

  let idx = 0;
  categories.forEach((cat) => {
    //let name = cat.name;
    let btnIcon = "";
    let index = "";
    let btnTitle = "";
    /*
    if (cat.type === "Weather") {
      btnTitle = cat.name;
      btnIcon = icons.weather;
      index = idx;
    } else if (cat.type === "Particulates") {
      btnTitle = cat.name;
      btnIcon = icons.particulates;
      index = idx;
    } else if (cat.type === "Gases") {
      btnTitle = cat.name;
      btnIcon = icons.gases;
      index = idx;
    }
    */
    btnTitle = cat.name;
    btnIcon = icons[cat.type.toLowerCase()] || icons.undef; // if there is no assigned icon -> show general icon (gases)
    index = idx;

    buttons[index] = (
      <button
        key={index}
        className={
          cat.type === selectedCategoryType
            ? isDarkTheme
              ? styles["active-btn-dark"]
              : styles["active-btn"]
            : isDarkTheme
              ? styles.button_dark
              : null
        }
        onClick={() => setSelectedCategoryType(cat.type)}
      >
        {btnIcon}
        {btnTitle}
      </button>
    );
    idx++;
  });

  return <div className={styles["container"]}>{buttons}</div>;
};

export default CategoriesNav;
