import React from "react";

export const icons = {
  close: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.102539"
        y="26.7596"
        width="36.7537"
        height="3.16843"
        rx="1.58421"
        transform="rotate(-45 0.102539 26.7596)"
        fill="#16123F"
      />
      <rect
        width="36.7537"
        height="3.16843"
        rx="1.58421"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 28.332 26.5643)"
        fill="#16123F"
      />
    </svg>
  ),
  senstateLogo: (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.5357 0H4.46429C1.99873 0 0 1.99873 0 4.46429V45.5357C0 48.0013 1.99873 50 4.46429 50H45.5357C48.0013 50 50 48.0013 50 45.5357V4.46429C50 1.99873 48.0013 0 45.5357 0Z" fill="#4FC4CA"/>
      <path d="M15.9888 35.9449H13.4901C9.99592 35.9449 7.14307 33.0948 7.14307 29.5944C7.14307 26.094 9.99592 23.2534 13.4901 23.2534C13.6529 23.2534 13.8252 23.263 13.9879 23.2725C14.6198 20.4129 17.2141 18.2992 20.1914 18.2992C21.0339 18.2992 21.8475 18.4618 22.623 18.7774C23.7048 15.5831 26.7203 13.3929 30.138 13.3929C34.5033 13.3929 38.0646 16.9412 38.0646 21.3119C38.0646 21.6084 38.0455 21.9145 38.0167 22.2109C39.5676 22.6509 40.927 23.6169 41.8557 24.9654C41.9897 25.1663 41.9418 25.434 41.7407 25.568C41.5397 25.7018 41.2716 25.654 41.1376 25.4532C40.2569 24.1812 38.9454 23.2917 37.4328 22.957L37.0307 22.8708L37.0977 22.4596C37.1648 22.0771 37.1934 21.6849 37.1934 21.3023C37.1934 17.4193 34.0343 14.2632 30.1475 14.2632C26.9501 14.2632 24.1547 16.4151 23.3314 19.4948L23.1878 20.0207L22.7091 19.7721C21.9241 19.3704 21.0817 19.1696 20.2106 19.1696C17.5205 19.1696 15.1941 21.1685 14.7921 23.8177L14.725 24.2481L14.2943 24.1812C14.0262 24.1429 13.7582 24.1238 13.4997 24.1238C10.4841 24.1238 8.02381 26.5817 8.02381 29.5944C8.02381 32.6071 10.4841 35.065 13.4997 35.065H15.9983C16.2376 35.065 16.4387 35.2564 16.4387 35.505C16.4291 35.7441 16.2376 35.9449 15.9888 35.9449Z" fill="white"/>
      <path d="M23.5036 26.2565C23.3792 26.0461 23.1207 25.9792 22.9101 26.094C22.6994 26.2182 22.6325 26.4765 22.7474 26.6869L24.2599 29.3266L19.7893 33.4679C19.3105 33.2192 18.7075 33.2765 18.2862 33.6591C17.7501 34.1469 17.7214 34.979 18.2096 35.5146C18.6978 36.0501 19.5308 36.0789 20.0668 35.5911C20.4881 35.199 20.5934 34.606 20.3827 34.1086L24.9301 29.9005C25.1885 29.6614 25.246 29.2788 25.0736 28.9823L23.5036 26.2565Z" fill="white"/>
      <path d="M29.8024 33.3435L24.4223 23.9994C24.2979 23.789 24.0394 23.7221 23.8288 23.8368C23.6182 23.9612 23.5512 24.2194 23.666 24.4298L29.0462 33.7738C28.692 34.1851 28.6154 34.7877 28.9026 35.285C29.2664 35.9163 30.0705 36.1266 30.6928 35.7728C31.3246 35.4093 31.5352 34.606 31.181 33.9843C30.8938 33.4774 30.329 33.2479 29.8024 33.3435Z" fill="white"/>
      <path d="M39.7114 32.4445C39.1753 32.234 38.5913 32.4062 38.2371 32.8175L29.8509 29.5753C29.8318 29.5562 29.8127 29.5274 29.7935 29.4987L26.8354 24.3341C26.7109 24.1237 26.4524 24.0568 26.2418 24.1715C26.0313 24.2959 25.9642 24.5541 26.0791 24.7645L29.0373 29.9291C29.133 30.0917 29.3149 30.2926 29.4967 30.3691L37.9212 33.6208C37.9021 34.1564 38.2275 34.6825 38.7636 34.8833C39.4433 35.1415 40.1996 34.8068 40.4581 34.1373C40.7262 33.4582 40.3911 32.7027 39.7114 32.4445Z" fill="white"/>
      <path d="M41.7593 27.0025C41.185 27.0025 40.7063 27.366 40.5244 27.8824H31.2384L29.5439 24.9558C29.4194 24.7454 29.161 24.6785 28.9503 24.7932C28.7397 24.9175 28.6727 25.1758 28.7877 25.3862L30.5108 28.3798C30.6544 28.6189 30.9128 28.7623 31.1905 28.7528H40.5148C40.6968 29.2597 41.185 29.6326 41.7498 29.6326C42.4774 29.6326 43.0613 29.0492 43.0613 28.3223C43.0709 27.5859 42.4773 27.0025 41.7593 27.0025Z" fill="white"/>
      <path d="M39.7114 32.4445C39.1753 32.234 38.5913 32.4062 38.2371 32.8175L29.8509 29.5753C29.8318 29.5562 29.8127 29.5274 29.7935 29.4987L26.8354 24.3341C26.7109 24.1237 26.4524 24.0568 26.2418 24.1715C26.0313 24.2959 25.9642 24.5541 26.0791 24.7645L29.0373 29.9291C29.133 30.0917 29.3149 30.2926 29.4967 30.3691L37.9212 33.6208C37.9021 34.1564 38.2275 34.6825 38.7636 34.8833C39.4433 35.1415 40.1996 34.8068 40.4581 34.1373C40.7262 33.4582 40.3911 32.7027 39.7114 32.4445Z" fill="white"/>
    </svg>
  ),
  senstateLogoAndText: (
    <svg
      width="190"
      height="30"
      viewBox="0 0 198 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4603 36.8666H10.3758C4.66361 36.8666 0 32.2075 0 26.4852C0 20.7629 4.66361 16.1194 10.3758 16.1194C10.6418 16.1194 10.9235 16.135 11.1895 16.1507C12.2224 11.4759 16.4635 8.02061 21.3305 8.02061C22.7077 8.02061 24.0379 8.2864 25.3056 8.80234C27.074 3.58035 32.0036 0 37.5906 0C44.7268 0 50.5485 5.80048 50.5485 12.9455C50.5485 13.4302 50.5172 13.9305 50.4703 14.4152C53.0055 15.1344 55.2278 16.7135 56.7458 18.918C56.9649 19.2463 56.8867 19.6841 56.558 19.903C56.2294 20.1219 55.7912 20.0437 55.5721 19.7154C54.1323 17.636 51.9883 16.1819 49.5157 15.6347L48.8584 15.494L48.9679 14.8217C49.0775 14.1963 49.1244 13.5553 49.1244 12.9299C49.1244 6.58221 43.96 1.42276 37.6062 1.42276C32.3792 1.42276 27.8095 4.94057 26.4636 9.97494L26.2289 10.8349L25.4464 10.4284C24.1631 9.77169 22.786 9.44336 21.3618 9.44336C16.9643 9.44336 13.1614 12.711 12.5041 17.0418L12.3946 17.7454L11.6903 17.636C11.2521 17.5734 10.8139 17.5421 10.3914 17.5421C5.46175 17.5421 1.43977 21.5603 1.43977 26.4852C1.43977 31.4101 5.46175 35.4282 10.3914 35.4282H14.476C14.8672 35.4282 15.1959 35.7409 15.1959 36.1474C15.1802 36.5383 14.8672 36.8666 14.4603 36.8666Z"
        fill="#4FC4CA"
      />
      <path
        d="M26.7452 21.0287C26.5418 20.6847 26.1192 20.5753 25.7749 20.7629C25.4306 20.9661 25.3211 21.3883 25.5089 21.7322L27.9815 26.0474L20.6731 32.8172C19.8906 32.4107 18.9047 32.5045 18.2161 33.1299C17.3397 33.9273 17.2928 35.2875 18.0909 36.1631C18.8891 37.0386 20.2506 37.0855 21.127 36.2881C21.8156 35.6471 21.9877 34.6778 21.6434 33.8648L29.077 26.9855C29.4996 26.5946 29.5935 25.9692 29.3118 25.4846L26.7452 21.0287Z"
        fill="#4FC4CA"
      />
      <path
        d="M37.0429 32.614L28.2478 17.3389C28.0443 16.9949 27.6218 16.8855 27.2775 17.0731C26.9332 17.2763 26.8236 17.6985 27.0114 18.0424L35.8066 33.3176C35.2275 33.9898 35.1023 34.9748 35.5718 35.7878C36.1665 36.8197 37.4811 37.1637 38.4983 36.5852C39.5312 35.9911 39.8755 34.6778 39.2965 33.6615C38.827 32.8329 37.9036 32.4576 37.0429 32.614Z"
        fill="#4FC4CA"
      />
      <path
        d="M53.2405 31.1444C52.3641 30.8004 51.4094 31.0818 50.8304 31.7541L37.1213 26.4539C37.09 26.4227 37.0587 26.3758 37.0274 26.3289L32.1916 17.8861C31.9882 17.5422 31.5656 17.4327 31.2213 17.6203C30.877 17.8236 30.7675 18.2457 30.9553 18.5897L35.791 27.0324C35.9475 27.2982 36.2449 27.6265 36.5422 27.7516L50.314 33.0674C50.2827 33.943 50.8148 34.8029 51.6911 35.1312C52.8023 35.5533 54.0386 35.0061 54.4611 33.9117C54.8993 32.8016 54.3516 31.5665 53.2405 31.1444Z"
        fill="#4FC4CA"
      />
      <path
        d="M56.5893 22.2482C55.6504 22.2482 54.8679 22.8423 54.5705 23.6866H39.3903L36.6203 18.9024C36.4169 18.5584 35.9943 18.449 35.65 18.6366C35.3058 18.8398 35.1962 19.262 35.384 19.6059L38.2009 24.4996C38.4357 24.8905 38.8582 25.125 39.3121 25.1093H54.5549C54.8522 25.938 55.6504 26.5477 56.5737 26.5477C57.7631 26.5477 58.7177 25.594 58.7177 24.4058C58.7334 23.2019 57.7631 22.2482 56.5893 22.2482Z"
        fill="#4FC4CA"
      />
      <path
        d="M53.2405 31.1444C52.3641 30.8004 51.4094 31.0818 50.8304 31.7541L37.1213 26.4539C37.09 26.4227 37.0587 26.3758 37.0274 26.3289L32.1916 17.8861C31.9882 17.5422 31.5656 17.4327 31.2213 17.6203C30.877 17.8236 30.7675 18.2457 30.9553 18.5897L35.791 27.0324C35.9475 27.2982 36.2449 27.6265 36.5422 27.7516L50.314 33.0674C50.2827 33.943 50.8148 34.8029 51.6911 35.1312C52.8023 35.5533 54.0386 35.0061 54.4611 33.9117C54.8993 32.8016 54.3516 31.5665 53.2405 31.1444Z"
        fill="#4FC4CA"
      />
      <path
        d="M71.5504 30.1124C72.2546 30.1124 72.8493 30.0499 73.3188 29.9404C73.7883 29.8154 74.1483 29.659 74.4299 29.4558C74.7116 29.2525 74.8994 29.0024 75.009 28.7209C75.1185 28.4395 75.1655 28.1268 75.1655 27.7829C75.1655 27.048 74.8212 26.4383 74.1326 25.9536C73.444 25.4689 72.2546 24.953 70.5801 24.3901C69.8446 24.1243 69.1091 23.8429 68.3892 23.4989C67.6536 23.1706 66.9963 22.7485 66.4173 22.2325C65.8383 21.7322 65.3688 21.1068 64.9932 20.3876C64.6332 19.6684 64.4454 18.7929 64.4454 17.761C64.4454 16.7291 64.6332 15.791 65.0245 14.9624C65.4157 14.1338 65.9635 13.4302 66.6677 12.8517C67.3719 12.2732 68.2483 11.8198 69.2499 11.5071C70.2671 11.1944 71.4096 11.0381 72.6772 11.0381C74.1795 11.0381 75.4941 11.1944 76.5896 11.5228C77.6851 11.8511 78.5928 12.1951 79.3127 12.5859L77.8572 16.5571C77.2312 16.2288 76.5427 15.9474 75.7758 15.6972C75.009 15.4471 74.0857 15.322 73.0058 15.322C71.8008 15.322 70.9244 15.494 70.408 15.8223C69.8759 16.1506 69.6098 16.6666 69.6098 17.3545C69.6098 17.761 69.7037 18.105 69.9072 18.3864C70.1106 18.6678 70.3767 18.918 70.7366 19.1525C71.0966 19.3714 71.5035 19.5903 71.9729 19.7623C72.4424 19.9499 72.9432 20.1375 73.5066 20.3251C74.6647 20.7629 75.6819 21.185 76.5427 21.6071C77.4034 22.0293 78.1233 22.514 78.6867 23.0768C79.2501 23.6397 79.6882 24.2963 79.9699 25.0468C80.2516 25.7972 80.3925 26.7197 80.3925 27.7985C80.3925 29.8935 79.6569 31.5039 78.1859 32.6609C76.7148 33.8179 74.5082 34.3807 71.5504 34.3807C70.5645 34.3807 69.6568 34.3182 68.8587 34.2087C68.0605 34.0836 67.3563 33.9429 66.7459 33.771C66.1356 33.599 65.6035 33.4114 65.1653 33.2237C64.7271 33.0361 64.3515 32.8485 64.0542 32.6765L65.4783 28.674C66.1513 29.0336 66.965 29.362 67.951 29.659C68.9213 29.9717 70.1263 30.1124 71.5504 30.1124Z"
        fill="#16103F"
      />
      <path
        d="M83.1313 25.594C83.1313 24.0931 83.3661 22.7641 83.8199 21.6384C84.2894 20.5127 84.8998 19.559 85.6509 18.8085C86.4021 18.0581 87.2785 17.4796 88.2488 17.0887C89.2347 16.6978 90.2363 16.5102 91.2692 16.5102C93.6792 16.5102 95.5885 17.2451 96.997 18.7304C98.4055 20.2 99.094 22.3732 99.094 25.2344C99.094 25.5158 99.0784 25.8285 99.0627 26.1568C99.0471 26.4852 99.0158 26.7822 99.0001 27.048H88.0767C88.1862 28.033 88.64 28.8304 89.4695 29.4089C90.2833 29.9873 91.3944 30.2844 92.7716 30.2844C93.6479 30.2844 94.5243 30.2062 95.3694 30.0342C96.2145 29.8779 96.9187 29.6746 97.4508 29.4401L98.0925 33.3488C97.8264 33.4739 97.4821 33.6146 97.0596 33.7397C96.6214 33.8647 96.1519 33.9898 95.6198 34.0836C95.0877 34.1774 94.5243 34.2556 93.914 34.3338C93.3036 34.3963 92.709 34.4276 92.0986 34.4276C90.565 34.4276 89.2347 34.2087 88.108 33.7553C86.9812 33.3019 86.0422 32.6765 85.291 31.8948C84.5398 31.113 83.9921 30.175 83.6478 29.0962C83.3035 28.0017 83.1313 26.8448 83.1313 25.594ZM94.4461 23.7491C94.4304 23.3426 94.3522 22.9361 94.2426 22.5609C94.1174 22.17 93.9453 21.826 93.6949 21.529C93.4445 21.2319 93.1315 20.9817 92.7559 20.7785C92.3803 20.5909 91.9108 20.4814 91.3474 20.4814C90.8153 20.4814 90.3459 20.5752 89.9546 20.7629C89.5634 20.9505 89.2504 21.185 88.9843 21.4821C88.7183 21.7791 88.5305 22.1387 88.3896 22.5296C88.2488 22.9205 88.1392 23.327 88.0767 23.7335H94.4461V23.7491Z"
        fill="#16103F"
      />
      <path
        d="M102.818 17.5578C103.632 17.3232 104.696 17.1044 105.995 16.9011C107.294 16.6979 108.64 16.5884 110.064 16.5884C111.504 16.5884 112.709 16.776 113.664 17.1513C114.618 17.5265 115.385 18.0581 115.948 18.746C116.512 19.4339 116.903 20.2469 117.138 21.2006C117.373 22.1544 117.498 23.2019 117.498 24.3745V33.9742H112.678V24.9686C112.678 23.4208 112.474 22.3263 112.067 21.6697C111.66 21.0287 110.894 20.7003 109.767 20.7003C109.423 20.7003 109.063 20.716 108.671 20.7472C108.28 20.7785 107.936 20.8098 107.638 20.8567V33.9742H102.818V17.5578Z"
        fill="#16103F"
      />
      <path
        d="M126.747 30.5033C127.623 30.5033 128.249 30.4095 128.625 30.2375C128.985 30.0655 129.173 29.7372 129.173 29.2369C129.173 28.846 128.938 28.5177 128.469 28.2206C127.999 27.9236 127.279 27.5952 126.309 27.2356C125.558 26.9542 124.869 26.6728 124.259 26.3601C123.648 26.0474 123.116 25.7034 122.694 25.2813C122.256 24.8592 121.927 24.3588 121.692 23.7804C121.457 23.2019 121.332 22.4983 121.332 21.6853C121.332 20.0906 121.927 18.8398 123.116 17.9017C124.306 16.9793 125.933 16.5102 127.999 16.5102C129.032 16.5102 130.018 16.604 130.972 16.7916C131.927 16.9793 132.678 17.1825 133.242 17.3858L132.397 21.1381C131.833 20.9505 131.223 20.7785 130.566 20.6221C129.908 20.4658 129.173 20.4033 128.359 20.4033C126.841 20.4033 126.09 20.8254 126.09 21.6697C126.09 21.8573 126.121 22.0293 126.184 22.1856C126.246 22.342 126.371 22.4827 126.575 22.6234C126.763 22.7641 127.029 22.9205 127.373 23.0768C127.717 23.2331 128.14 23.4208 128.656 23.6084C129.72 23.9992 130.581 24.3901 131.27 24.7653C131.958 25.1406 132.506 25.5627 132.897 26.0005C133.289 26.4383 133.57 26.9386 133.742 27.4701C133.899 28.0017 133.977 28.6271 133.977 29.3463C133.977 31.0192 133.351 32.2856 132.084 33.1455C130.816 34.0055 129.032 34.4276 126.731 34.4276C125.229 34.4276 123.961 34.3025 122.96 34.0367C121.958 33.7709 121.27 33.5677 120.878 33.3957L121.692 29.487C122.506 29.8154 123.351 30.0655 124.212 30.2531C125.057 30.4095 125.902 30.5033 126.747 30.5033Z"
        fill="#16103F"
      />
      <path
        d="M137.389 12.7423L142.209 11.9605V16.9636H147.999V20.9661H142.209V26.9386C142.209 27.9548 142.381 28.7522 142.741 29.362C143.101 29.9717 143.821 30.2688 144.885 30.2688C145.401 30.2688 145.933 30.2219 146.481 30.1281C147.029 30.0343 147.53 29.8935 147.984 29.7216L148.656 33.4739C148.077 33.7084 147.42 33.9117 146.716 34.0836C146.012 34.2556 145.135 34.3494 144.102 34.3494C142.788 34.3494 141.692 34.1775 140.832 33.8179C139.971 33.4583 139.282 32.9736 138.766 32.3326C138.249 31.6915 137.889 30.9254 137.686 30.0186C137.483 29.1118 137.373 28.1112 137.373 27.0168V12.7423H137.389Z"
        fill="#16103F"
      />
      <path
        d="M157.373 16.5258C158.797 16.5258 159.987 16.6822 160.926 17.0105C161.88 17.3389 162.632 17.7923 163.211 18.402C163.774 19.0118 164.181 19.731 164.416 20.5909C164.65 21.4508 164.776 22.4045 164.776 23.4677V33.4895C164.087 33.6459 163.132 33.8178 161.896 34.0211C160.66 34.2243 159.173 34.3338 157.436 34.3338C156.34 34.3338 155.339 34.24 154.447 34.0367C153.555 33.8335 152.788 33.5208 152.131 33.083C151.489 32.6452 150.988 32.0667 150.644 31.3476C150.3 30.644 150.127 29.7684 150.127 28.7366C150.127 27.7516 150.331 26.9073 150.722 26.2194C151.113 25.5314 151.661 24.9842 152.318 24.5777C152.991 24.1712 153.758 23.8742 154.619 23.6865C155.48 23.4989 156.372 23.4051 157.311 23.4051C157.937 23.4051 158.484 23.4364 158.97 23.4833C159.455 23.5458 159.846 23.6084 160.143 23.6865V23.2331C160.143 22.4201 159.893 21.7635 159.392 21.2632C158.891 20.7629 158.031 20.5127 156.81 20.5127C155.996 20.5127 155.182 20.5752 154.384 20.6847C153.586 20.7941 152.897 20.9661 152.318 21.185L151.708 17.3076C151.99 17.2294 152.334 17.1356 152.757 17.0262C153.179 16.9324 153.633 16.8385 154.134 16.7604C154.635 16.6822 155.151 16.6197 155.699 16.5728C156.246 16.5415 156.81 16.5258 157.373 16.5258ZM157.764 30.5971C158.234 30.5971 158.688 30.5815 159.126 30.5658C159.564 30.5502 159.908 30.5189 160.159 30.472V26.8135C159.971 26.7666 159.674 26.7353 159.283 26.6884C158.891 26.6415 158.531 26.6259 158.218 26.6259C157.764 26.6259 157.342 26.6571 156.935 26.704C156.544 26.7509 156.184 26.8604 155.887 27.0167C155.589 27.1731 155.354 27.3763 155.182 27.6265C155.01 27.8923 154.916 28.205 154.916 28.5958C154.916 29.3463 155.167 29.8779 155.683 30.1593C156.184 30.4564 156.872 30.5971 157.764 30.5971Z"
        fill="#16103F"
      />
      <path
        d="M168.985 12.7423L173.805 11.9605V16.9636H179.596V20.9661H173.805V26.9386C173.805 27.9548 173.978 28.7522 174.338 29.362C174.698 29.9717 175.417 30.2688 176.482 30.2688C176.998 30.2688 177.53 30.2219 178.078 30.1281C178.626 30.0343 179.126 29.8935 179.58 29.7216L180.253 33.4739C179.674 33.7084 179.017 33.9117 178.313 34.0836C177.608 34.2556 176.732 34.3494 175.699 34.3494C174.385 34.3494 173.289 34.1775 172.428 33.8179C171.568 33.4583 170.879 32.9736 170.363 32.3326C169.846 31.6915 169.486 30.9254 169.283 30.0186C169.079 29.1118 168.97 28.1112 168.97 27.0168V12.7423H168.985Z"
        fill="#16103F"
      />
      <path
        d="M182.037 25.594C182.037 24.0931 182.272 22.7641 182.726 21.6384C183.195 20.5127 183.806 19.559 184.557 18.8085C185.308 18.0581 186.184 17.4796 187.155 17.0887C188.14 16.6978 189.142 16.5102 190.175 16.5102C192.585 16.5102 194.494 17.2451 195.903 18.7304C197.311 20.2 198 22.3732 198 25.2344C198 25.5158 197.984 25.8285 197.969 26.1568C197.953 26.4852 197.922 26.7822 197.906 27.048H186.982C187.092 28.033 187.546 28.8304 188.375 29.4089C189.189 29.9873 190.3 30.2844 191.677 30.2844C192.554 30.2844 193.43 30.2062 194.275 30.0342C195.12 29.8779 195.824 29.6746 196.357 29.4401L196.998 33.3488C196.732 33.4739 196.388 33.6146 195.965 33.7397C195.543 33.8647 195.058 33.9898 194.526 34.0836C193.993 34.1774 193.43 34.2556 192.835 34.3338C192.225 34.3963 191.63 34.4276 191.02 34.4276C189.486 34.4276 188.156 34.2087 187.029 33.7553C185.903 33.3019 184.964 32.6765 184.212 31.8948C183.461 31.113 182.913 30.175 182.569 29.0962C182.225 28.0017 182.037 26.8448 182.037 25.594ZM193.367 23.7491C193.352 23.3426 193.274 22.9361 193.164 22.5609C193.039 22.17 192.867 21.826 192.616 21.529C192.366 21.2319 192.053 20.9817 191.677 20.7785C191.302 20.5909 190.832 20.4814 190.269 20.4814C189.737 20.4814 189.267 20.5752 188.876 20.7629C188.485 20.9505 188.172 21.185 187.906 21.4821C187.64 21.7791 187.452 22.1387 187.311 22.5296C187.17 22.9205 187.076 23.327 186.998 23.7335H193.367V23.7491Z"
        fill="#16103F"
      />
    </svg>
  ),
  senstateText: (
    <svg width="111" height="20" viewBox="0 0 111 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.21206 15.8068C6.79565 15.8068 7.28846 15.755 7.67753 15.6643C8.06659 15.5606 8.36488 15.4311 8.59832 15.2626C8.83176 15.0942 8.98738 14.8869 9.07816 14.6537C9.16894 14.4205 9.20785 14.1613 9.20785 13.8763C9.20785 13.2673 8.92253 12.762 8.35191 12.3604C7.78128 11.9587 6.79565 11.5312 5.40799 11.0648C4.79845 10.8445 4.18892 10.6113 3.59236 10.3262C2.98283 10.0542 2.43813 9.70433 1.95829 9.27677C1.47844 8.86216 1.08938 8.34391 0.778126 7.74792C0.479844 7.15192 0.32422 6.42636 0.32422 5.57124C0.32422 4.71612 0.479844 3.93874 0.804064 3.25205C1.12828 2.56536 1.58219 1.98233 2.16579 1.50294C2.74938 1.02355 3.47564 0.647819 4.30564 0.388692C5.14861 0.129564 6.09533 0 7.14581 0C8.39081 0 9.48019 0.129564 10.388 0.401648C11.2958 0.673732 12.048 0.958773 12.6446 1.28268L11.4385 4.5736C10.9197 4.30152 10.3491 4.0683 9.71363 3.861C9.07816 3.6537 8.313 3.55005 7.41816 3.55005C6.41956 3.55005 5.69331 3.69257 5.26534 3.96465C4.8244 4.23674 4.60392 4.6643 4.60392 5.23438C4.60392 5.57124 4.68173 5.85628 4.85033 6.0895C5.01892 6.32271 5.2394 6.53002 5.53768 6.72436C5.83596 6.90575 6.17315 7.08714 6.56222 7.22966C6.95128 7.38514 7.36628 7.54061 7.83316 7.69609C8.79285 8.05887 9.63582 8.40869 10.3491 8.75851C11.0624 9.10834 11.659 9.50998 12.1258 9.97641C12.5927 10.4428 12.9558 10.987 13.1893 11.6089C13.4227 12.2308 13.5394 12.9953 13.5394 13.8892C13.5394 15.6254 12.9299 16.9599 11.7108 17.9187C10.4918 18.8774 8.66316 19.3439 6.21206 19.3439C5.39502 19.3439 4.64283 19.2921 3.98142 19.2014C3.32001 19.0977 2.73642 18.9811 2.23064 18.8386C1.72485 18.6961 1.28391 18.5406 0.920784 18.3851C0.557657 18.2296 0.246407 18.0742 0 17.9316L1.18016 14.6148C1.73782 14.9128 2.41219 15.1849 3.22923 15.431C4.03329 15.6902 5.0319 15.8068 6.21206 15.8068Z" fill="#16103F"/>
      <path d="M15.8091 12.0624C15.8091 10.8186 16.0036 9.71728 16.3797 8.78442C16.7688 7.85156 17.2746 7.06122 17.8971 6.43932C18.5196 5.81741 19.2458 5.33802 20.0499 5.01411C20.8669 4.69021 21.6969 4.53473 22.5529 4.53473C24.5501 4.53473 26.1322 5.14368 27.2994 6.37453C28.4666 7.59243 29.0373 9.39337 29.0373 11.7644C29.0373 11.9976 29.0243 12.2567 29.0113 12.5288C28.9984 12.8009 28.9724 13.0471 28.9594 13.2673H19.9072C19.998 14.0836 20.3741 14.7444 21.0614 15.2237C21.7358 15.7031 22.6566 15.9493 23.7979 15.9493C24.5241 15.9493 25.2504 15.8845 25.9507 15.742C26.651 15.6124 27.2346 15.444 27.6755 15.2497L28.2073 18.4887C27.9868 18.5924 27.7015 18.709 27.3513 18.8127C26.9882 18.9163 26.5991 19.02 26.1582 19.0977C25.7172 19.1754 25.2504 19.2402 24.7446 19.305C24.2388 19.3568 23.746 19.3827 23.2402 19.3827C21.9693 19.3827 20.8669 19.2013 19.9332 18.8256C18.9994 18.4499 18.2213 17.9316 17.5988 17.2838C16.9763 16.636 16.5224 15.8586 16.2371 14.9646C15.9517 14.0577 15.8091 13.0989 15.8091 12.0624ZM25.1855 10.5335C25.1726 10.1967 25.1077 9.8598 25.0169 9.54885C24.9132 9.22494 24.7705 8.9399 24.563 8.69373C24.3555 8.44756 24.0961 8.24025 23.7849 8.07182C23.4736 7.91634 23.0846 7.82565 22.6177 7.82565C22.1768 7.82565 21.7877 7.90339 21.4635 8.05886C21.1393 8.21434 20.8799 8.40869 20.6594 8.65486C20.4389 8.90103 20.2833 9.19902 20.1666 9.52293C20.0499 9.84684 19.9591 10.1837 19.9072 10.5206H25.1855V10.5335Z" fill="#16103F"/>
      <path d="M32.124 5.40281C32.7984 5.20847 33.6803 5.02708 34.7567 4.85865C35.8331 4.69021 36.9484 4.59952 38.1286 4.59952C39.3217 4.59952 40.3203 4.755 41.1114 5.06595C41.9025 5.3769 42.538 5.81742 43.0049 6.3875C43.4717 6.95758 43.796 7.63131 43.9905 8.42165C44.185 9.21199 44.2888 10.0801 44.2888 11.0518V19.007H40.2944V11.5441C40.2944 10.2615 40.1258 9.35451 39.7886 8.81034C39.4514 8.27913 38.8159 8.00705 37.8822 8.00705C37.5969 8.00705 37.2986 8.02 36.9744 8.04592C36.6502 8.07183 36.3648 8.09774 36.1184 8.13661V19.007H32.124V5.40281Z" fill="#16103F"/>
      <path d="M51.9531 16.1307C52.6794 16.1307 53.1982 16.0529 53.5094 15.9104C53.8077 15.7679 53.9633 15.4958 53.9633 15.0812C53.9633 14.7573 53.7688 14.4852 53.3797 14.2391C52.9906 13.9929 52.3941 13.7208 51.59 13.4228C50.9675 13.1896 50.3969 12.9564 49.8911 12.6972C49.3853 12.4381 48.9444 12.1531 48.5942 11.8033C48.2311 11.4534 47.9588 11.0388 47.7642 10.5594C47.5697 10.0801 47.4659 9.49702 47.4659 8.82329C47.4659 7.50174 47.9588 6.46523 48.9444 5.68785C49.93 4.92342 51.2788 4.53473 52.9907 4.53473C53.8466 4.53473 54.6636 4.61247 55.4547 4.76794C56.2458 4.92342 56.8683 5.09185 57.3352 5.26029L56.6349 8.36982C56.168 8.21434 55.6622 8.07182 55.1175 7.94226C54.5728 7.81269 53.9633 7.76087 53.2889 7.76087C52.031 7.76087 51.4085 8.11069 51.4085 8.81033C51.4085 8.96581 51.4344 9.10833 51.4863 9.23789C51.5381 9.36746 51.6419 9.48407 51.8105 9.60067C51.9661 9.71728 52.1866 9.84684 52.4719 9.97641C52.7572 10.106 53.1074 10.2614 53.5353 10.4169C54.4172 10.7408 55.1305 11.0647 55.7011 11.3757C56.2718 11.6866 56.7257 12.0365 57.0499 12.3992C57.3741 12.762 57.6075 13.1766 57.7502 13.6171C57.8799 14.0577 57.9447 14.5759 57.9447 15.1719C57.9447 16.5582 57.426 17.6077 56.3755 18.3203C55.325 19.0329 53.8466 19.3827 51.9402 19.3827C50.6952 19.3827 49.6447 19.2791 48.8147 19.0588C47.9847 18.8386 47.4141 18.6701 47.0898 18.5276L47.7642 15.2885C48.4386 15.5606 49.1389 15.7679 49.8522 15.9234C50.5525 16.0529 51.2528 16.1307 51.9531 16.1307Z" fill="#16103F"/>
      <path d="M60.7713 1.41225L64.7657 0.764435V4.91048H69.5641V8.22731H64.7657V13.1766C64.7657 14.0188 64.9083 14.6796 65.2066 15.1849C65.5049 15.6902 66.1015 15.9364 66.9833 15.9364C67.4113 15.9364 67.8522 15.8975 68.3061 15.8198C68.7601 15.742 69.1751 15.6254 69.5512 15.4829L70.1088 18.5924C69.629 18.7868 69.0843 18.9552 68.5007 19.0977C67.9171 19.2402 67.1908 19.318 66.3349 19.318C65.2455 19.318 64.3377 19.1755 63.6244 18.8775C62.9111 18.5795 62.3405 18.1778 61.9125 17.6466C61.4846 17.1154 61.1863 16.4805 61.0177 15.7291C60.8491 14.9776 60.7583 14.1484 60.7583 13.2414V1.41225H60.7713Z" fill="#16103F"/>
      <path d="M77.3327 4.54767C78.5128 4.54767 79.4985 4.67723 80.2766 4.94932C81.0677 5.2214 81.6902 5.59714 82.17 6.10244C82.6369 6.60773 82.9741 7.20373 83.1686 7.91633C83.3632 8.62893 83.4669 9.41927 83.4669 10.3003V18.6053C82.8963 18.7349 82.1052 18.8774 81.0807 19.0459C80.0561 19.2143 78.8241 19.305 77.3846 19.305C76.4767 19.305 75.6467 19.2273 74.9075 19.0588C74.1683 18.8904 73.5328 18.6313 72.9881 18.2685C72.4564 17.9057 72.0414 17.4263 71.7561 16.8303C71.4708 16.2473 71.3281 15.5217 71.3281 14.6666C71.3281 13.8504 71.4967 13.1507 71.8209 12.5806C72.1452 12.0105 72.5991 11.5571 73.1438 11.2202C73.7014 10.8833 74.3369 10.6372 75.0502 10.4817C75.7635 10.3262 76.5027 10.2485 77.2808 10.2485C77.7996 10.2485 78.2535 10.2744 78.6555 10.3133C79.0575 10.3651 79.3818 10.4169 79.6282 10.4817V10.106C79.6282 9.43222 79.4207 8.88806 79.0057 8.47345C78.5907 8.05885 77.8774 7.85155 76.8658 7.85155C76.1914 7.85155 75.5171 7.90337 74.8556 7.99407C74.1942 8.08476 73.6236 8.22728 73.1438 8.40867L72.638 5.19549C72.8714 5.13071 73.1567 5.05297 73.5069 4.96227C73.857 4.88454 74.2331 4.8068 74.6481 4.74201C75.0631 4.67723 75.4911 4.62541 75.945 4.58654C76.3989 4.56063 76.8658 4.54767 77.3327 4.54767ZM77.6569 16.2084C78.046 16.2084 78.4221 16.1955 78.7852 16.1825C79.1483 16.1695 79.4336 16.1436 79.6411 16.1048V13.073C79.4855 13.0341 79.2391 13.0082 78.9149 12.9693C78.5907 12.9304 78.2924 12.9175 78.033 12.9175C77.6569 12.9175 77.3067 12.9434 76.9696 12.9823C76.6453 13.0211 76.3471 13.1118 76.1006 13.2414C75.8542 13.371 75.6597 13.5394 75.5171 13.7467C75.3744 13.967 75.2966 14.2261 75.2966 14.55C75.2966 15.1719 75.5041 15.6124 75.9321 15.8456C76.3471 16.0918 76.9177 16.2084 77.6569 16.2084Z" fill="#16103F"/>
      <path d="M86.9563 1.41225L90.9507 0.764435V4.91048H95.7492V8.22731H90.9507V13.1766C90.9507 14.0188 91.0934 14.6796 91.3917 15.1849C91.6899 15.6902 92.2865 15.9364 93.1684 15.9364C93.5964 15.9364 94.0373 15.8975 94.4912 15.8198C94.9451 15.742 95.3601 15.6254 95.7362 15.4829L96.2939 18.5924C95.814 18.7868 95.2693 18.9552 94.6857 19.0977C94.1021 19.2402 93.3759 19.318 92.5199 19.318C91.4306 19.318 90.5228 19.1755 89.8095 18.8775C89.0962 18.5795 88.5256 18.1778 88.0976 17.6466C87.6696 17.1154 87.3713 16.4805 87.2027 15.7291C87.0341 14.9776 86.9434 14.1484 86.9434 13.2414V1.41225H86.9563Z" fill="#16103F"/>
      <path d="M97.772 12.0624C97.772 10.8186 97.9665 9.71728 98.3426 8.78442C98.7317 7.85156 99.2374 7.06122 99.86 6.43932C100.482 5.81741 101.209 5.33802 102.013 5.01411C102.83 4.69021 103.66 4.53473 104.516 4.53473C106.513 4.53473 108.095 5.14368 109.262 6.37453C110.43 7.59243 111 9.39337 111 11.7644C111 11.9976 110.987 12.2567 110.974 12.5288C110.961 12.8009 110.935 13.0471 110.922 13.2673H101.87C101.961 14.0836 102.337 14.7444 103.024 15.2237C103.699 15.7031 104.62 15.9493 105.761 15.9493C106.487 15.9493 107.213 15.8845 107.914 15.742C108.614 15.6124 109.197 15.444 109.638 15.2497L110.17 18.4887C109.95 18.5924 109.664 18.709 109.314 18.8127C108.964 18.9163 108.562 19.02 108.121 19.0977C107.68 19.1754 107.213 19.2402 106.72 19.305C106.215 19.3568 105.722 19.3827 105.216 19.3827C103.945 19.3827 102.843 19.2013 101.909 18.8256C100.975 18.4499 100.197 17.9316 99.5746 17.2838C98.9521 16.636 98.4982 15.8586 98.2129 14.9646C97.9276 14.0577 97.772 13.0989 97.772 12.0624ZM107.161 10.5335C107.148 10.1967 107.084 9.8598 106.993 9.54885C106.889 9.22494 106.746 8.9399 106.539 8.69373C106.331 8.44756 106.072 8.24025 105.761 8.07182C105.45 7.91634 105.06 7.82565 104.594 7.82565C104.153 7.82565 103.764 7.90339 103.439 8.05886C103.115 8.21434 102.856 8.40869 102.635 8.65486C102.415 8.90103 102.259 9.19902 102.142 9.52293C102.026 9.84684 101.948 10.1837 101.883 10.5206H107.161V10.5335Z" fill="#16103F"/>
    </svg>
  ),
  airQuality: (
    <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.2896 3.95487C23.4106 3.59159 22.4317 3.50491 21.4968 3.70756C20.5619 3.91021 19.7202 4.3915 19.0953 5.08077C18.3895 5.83488 18.0007 6.80834 18.0027 7.81621C18.0027 8.23595 18.2625 8.50343 18.6889 8.51448C19.1153 8.52552 19.3777 8.28724 19.416 7.85961C19.416 7.82805 19.416 7.7957 19.4218 7.76335C19.4276 7.731 19.4218 7.69787 19.4285 7.66552C19.5625 6.38419 20.4836 5.33166 21.7212 5.04526C23.0187 4.74544 24.3221 5.21648 25.0425 6.24533C25.4001 6.74418 25.583 7.33695 25.5653 7.93972C25.5477 8.5425 25.3304 9.12475 24.9442 9.60407C24.3121 10.3978 23.406 10.7828 22.1743 10.7828H22.1609C19.655 10.7773 17.1066 10.7781 14.6431 10.7828H1.0448C0.794957 10.7828 0.0212749 10.7828 0.000454383 11.4385C-0.00541191 11.5207 0.00680003 11.6033 0.0363311 11.6808C0.0658621 11.7584 0.112064 11.8292 0.17201 11.889C0.345236 12.0578 0.634223 12.1399 1.05563 12.1399H8.67843C13.1623 12.1399 17.7162 12.1399 22.2134 12.1352C22.6276 12.1377 23.041 12.0994 23.4468 12.0208C25.3623 11.6263 26.8214 10.0743 26.9855 8.24069C27.0577 7.35011 26.8374 6.46018 26.3547 5.69289C25.8721 4.92559 25.1508 4.31851 24.2896 3.95487Z" fill="#16123F"/>
      <path d="M18.075 14.5061C16.0896 14.4895 14.0716 14.4935 12.1204 14.4966C11.3286 14.4966 10.5366 14.4966 9.74433 14.4966H8.36351H0.991396C0.876337 14.4937 0.761194 14.4974 0.646608 14.5077C0.545149 14.5143 0.446208 14.5406 0.355944 14.585C0.265681 14.6294 0.186021 14.6909 0.121927 14.7657C0.0760603 14.8243 0.0436879 14.8914 0.0269035 14.9626C0.0101191 15.0338 0.00929252 15.1076 0.0244839 15.1791C0.0777844 15.4592 0.388426 15.6919 0.524176 15.7787C0.659925 15.8655 0.82982 15.8576 0.982226 15.8458C1.02553 15.8458 1.06551 15.8403 1.10798 15.8403H6.05326C9.91588 15.8403 13.7791 15.8403 17.6428 15.8403C17.9649 15.8363 18.2866 15.8608 18.6039 15.9137C19.3467 16.0532 20.0077 16.4503 20.4577 17.0273C20.9076 17.6044 21.114 18.3197 21.0365 19.0333C20.87 20.5103 19.5449 21.6528 18.0059 21.6528H17.9601C16.3777 21.6291 15.0394 20.4583 14.9211 18.9876C14.9028 18.7596 14.847 18.0731 14.2082 18.0731H14.1824C14.0946 18.0725 14.0076 18.0892 13.9269 18.1221C13.8462 18.1549 13.7735 18.2033 13.7135 18.2641C13.547 18.4329 13.4804 18.7051 13.5028 19.0736V19.0965C13.5028 19.1304 13.5028 19.1643 13.5103 19.1975C13.5696 19.7695 13.7533 20.3238 14.0498 20.8253C14.3464 21.3268 14.7494 21.7646 15.2334 22.1112C15.7348 22.4695 16.3079 22.7274 16.9179 22.869C17.5278 23.0106 18.1618 23.033 18.7812 22.9349C19.3779 22.8363 19.9469 22.6228 20.453 22.3075C20.9591 21.9923 21.3915 21.582 21.7236 21.1021C22.0639 20.6169 22.2953 20.0707 22.4036 19.4972C22.5119 18.9238 22.4948 18.3355 22.3532 17.7686C21.856 15.8348 20.1412 14.5234 18.075 14.5061Z" fill="#16123F"/>
      <path d="M0.639981 8.51997C0.76979 8.5317 0.900202 8.53645 1.03058 8.53418H6.40559V8.51523C6.89695 8.51523 7.39331 8.51918 7.87468 8.52313C9.07394 8.5326 10.3132 8.54286 11.5333 8.50419C12.2004 8.48089 12.8529 8.31216 13.4401 8.01111C14.0272 7.71007 14.5332 7.28482 14.9187 6.76841C15.3214 6.2495 15.5923 5.64957 15.7103 5.01529C15.8283 4.38101 15.7902 3.72948 15.5991 3.11141C14.9978 1.18547 13.0173 -0.147135 11.0003 0.013031C8.75164 0.189766 7.10599 1.68096 6.80701 3.81124C6.74955 4.21915 6.79702 4.51109 6.95275 4.70281C7.00716 4.76953 7.07544 4.82496 7.15337 4.86568C7.23129 4.90639 7.31721 4.93152 7.4058 4.93951C7.49062 4.95367 7.57764 4.95088 7.66125 4.93131C7.74486 4.91175 7.82324 4.87585 7.89134 4.8259C8.08206 4.68073 8.18949 4.41956 8.22447 4.00376C8.27886 3.40984 8.52694 2.84665 8.93432 2.39228C9.3417 1.93791 9.8882 1.61486 10.4981 1.46793C11.1005 1.31994 11.7362 1.34856 12.3212 1.55C12.9061 1.75144 13.4128 2.11623 13.7744 2.5962C14.1398 3.08186 14.3373 3.66285 14.3398 4.25924C14.3424 4.85563 14.1498 5.43811 13.7885 5.92654C13.1539 6.77866 12.2395 7.19367 10.9936 7.19367H10.9803C8.45099 7.18656 5.87925 7.18814 3.39245 7.19367H1.10886C1.06743 7.1944 1.026 7.19283 0.984769 7.18894C0.832363 7.17947 0.659969 7.16842 0.525885 7.256C0.359321 7.36251 0.0778282 7.58185 0.0261934 7.84854C0.00975832 7.92022 0.00949997 7.9944 0.0254309 8.06619C0.0413618 8.13798 0.0731213 8.20577 0.118635 8.26513C0.182657 8.33898 0.261919 8.39967 0.351564 8.44349C0.441209 8.48731 0.53935 8.51334 0.639981 8.51997Z" fill="#16123F"/>
    </svg>

  ),
  waterQuality: (
    <svg width="33" height="15" viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.9002 6.90183C29.8405 6.8665 28.8391 6.43688 28.1159 5.70732C27.8141 5.41484 27.5546 5.27759 27.3011 5.27475H27.2941C27.0376 5.27475 26.769 5.41295 26.4491 5.70921C24.7241 7.30883 22.309 7.3022 20.5768 5.69312C19.9733 5.13562 19.5498 5.13562 18.9473 5.69312C17.188 7.32113 14.8121 7.31545 13.0368 5.67892C12.4524 5.14035 12.0198 5.1394 11.4414 5.67892C9.66909 7.32019 7.2942 7.32775 5.53391 5.69879C5.22611 5.41484 4.95151 5.27286 4.69401 5.28044C4.4365 5.28801 4.19107 5.41863 3.90339 5.69312C3.10774 6.46832 2.18334 6.86585 1.07587 6.9075C0.934921 6.91318 0.795475 6.93707 0.661445 6.97849C0.449308 7.03895 0.267038 7.16862 0.147382 7.34419C0.027726 7.51976 -0.0214705 7.72973 0.0086316 7.93637C0.0400219 8.13429 0.141178 8.31655 0.295869 8.45387C0.45056 8.59119 0.649809 8.67562 0.861614 8.69359C1.17289 8.70639 1.48476 8.6915 1.79306 8.6491C2.83032 8.5048 3.81066 8.11169 4.6397 7.5076L4.69803 7.4669L4.75738 7.50571C7.27207 9.12899 9.70228 9.13278 12.1838 7.51707L12.2401 7.48015L12.2974 7.51707C14.795 9.12615 17.2172 9.12615 19.7027 7.51707L19.7601 7.48015L19.8174 7.51707C22.2717 9.11195 24.7673 9.11006 27.2327 7.51138L27.2891 7.47541L27.4701 7.589L27.7135 7.74139C28.6303 8.33086 29.706 8.66366 30.8156 8.70116C31.2029 8.71252 31.5198 8.61692 31.732 8.42762C31.8163 8.34963 31.8829 8.25646 31.928 8.15368C31.9731 8.0509 31.9957 7.94061 31.9945 7.82941C32.0066 7.288 31.5872 6.93306 30.9002 6.90183Z" fill="#16123F"/>
      <path d="M1.11222 3.41295C2.25677 3.39954 3.36992 3.05885 4.30387 2.43613C4.60563 2.23831 4.82089 2.23642 5.12064 2.42951C7.17666 3.74896 9.73864 3.75463 11.8067 2.44654C12.1286 2.24304 12.3529 2.24209 12.6657 2.44654C14.7197 3.7641 17.2807 3.76126 19.3508 2.43897C19.6526 2.24967 19.8658 2.24967 20.1666 2.43897C22.2789 3.77357 24.7936 3.76978 26.8949 2.42951C27.0082 2.34636 27.1458 2.29782 27.2892 2.29037C27.4286 2.29739 27.562 2.34576 27.6704 2.42856C28.5663 3.02033 29.6148 3.37439 30.7062 3.45364C30.7846 3.44133 30.8651 3.43282 30.9445 3.4243C31.118 3.41263 31.2893 3.38084 31.4545 3.32965C31.8247 3.19524 32.0581 2.78256 31.9887 2.3888C31.9082 1.94394 31.541 1.66377 31.0069 1.64105C29.8512 1.59183 28.9056 1.19808 28.118 0.438971C27.8163 0.155015 27.5517 0.0111399 27.2962 0.00924683H27.2902C27.0317 0.00924683 26.7651 0.144598 26.4543 0.434234C24.7292 2.04332 22.3151 2.03481 20.583 0.420993C20.0016 -0.121364 19.5278 -0.12231 18.9484 0.420993C17.1882 2.05753 14.8143 2.05278 13.0409 0.409624C12.4786 -0.11191 11.9998 -0.114745 11.4416 0.402055C9.64609 2.0632 7.31748 2.06036 5.50087 0.394487C5.2162 0.132301 4.95065 0.0101952 4.69616 0.0111417C4.44168 0.0120882 4.20127 0.137976 3.94478 0.38975C3.57085 0.769928 3.12049 1.07654 2.61977 1.29181C2.11906 1.50709 1.57793 1.62675 1.02773 1.64389C0.384973 1.67323 -0.0163759 2.04143 0.00575338 2.58C0.0268768 3.08923 0.464434 3.42052 1.11222 3.41295Z" fill="#16123F"/>
      <path d="M30.9744 12.1976C29.9162 12.1644 28.9119 11.7508 28.168 11.0419C27.489 10.403 27.0736 10.403 26.3906 11.0419C24.7621 12.5705 22.2997 12.5753 20.6571 11.0533C19.9449 10.3907 19.5556 10.3907 18.8465 11.0599C18.4753 11.4153 18.0328 11.6976 17.5445 11.8906C17.0563 12.0835 16.5322 12.1831 16.0026 12.1836C15.4731 12.1842 14.9488 12.0856 14.4601 11.8936C13.9714 11.7017 13.5282 11.4202 13.1562 11.0656C12.4521 10.403 12.0397 10.3973 11.3457 11.0476C10.4585 11.8739 9.40734 12.2553 8.22745 12.1834C7.18033 12.1171 6.26599 11.7016 5.51158 10.9473C5.25709 10.6936 4.97846 10.5592 4.70687 10.5582C4.58587 10.5619 4.46697 10.5891 4.35759 10.6379C4.24822 10.6868 4.15071 10.7563 4.07117 10.8422C3.16588 11.7319 2.11172 12.1806 0.8423 12.2146C0.439948 12.226 0.0657564 12.5753 0.019486 12.9946C-0.00646733 13.194 0.0424657 13.3956 0.157819 13.5647C0.273172 13.7337 0.447691 13.8595 0.65118 13.9203C0.880156 13.9799 1.11875 13.9998 1.35529 13.979C2.43027 13.9147 3.46471 13.5688 4.34174 12.9804C4.61333 12.7911 4.82256 12.7911 5.11426 12.9804C7.2598 14.3396 9.72521 14.3377 11.8788 12.9738C11.9846 12.8951 12.1134 12.8485 12.2479 12.8403C12.3766 12.8465 12.5005 12.8883 12.604 12.9605C14.8602 14.3472 17.1516 14.349 19.4128 12.9605C19.6914 12.7911 19.8816 12.792 20.1391 12.9662C21.1096 13.6225 22.2716 13.9812 23.4666 13.9934C24.6617 14.0056 25.8317 13.6707 26.8171 13.0343C27.1188 12.8337 27.3753 12.7674 27.7576 13.023C28.6508 13.6221 29.71 13.9468 30.906 13.9875C31.5316 14.0074 31.9823 13.6553 32.0054 13.1271C32.0285 12.5989 31.6041 12.2269 30.9744 12.1976Z" fill="#16123F"/>
    </svg>
  ),
  radiationMonitoring: (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7093 14.6758C15.6776 14.6276 15.6434 14.58 15.6045 14.5272C14.2059 15.2345 12.7906 15.2345 11.3935 14.5272C11.3805 14.5414 11.3686 14.5565 11.3578 14.5724L10.8022 15.4277C9.69487 17.1337 8.54945 18.8979 7.42784 20.6391C7.28972 20.8522 7.24369 21.0407 7.28973 21.1978C7.33576 21.3549 7.48898 21.5044 7.73822 21.6247C11.5483 23.4533 15.4053 23.464 19.2154 21.6477C19.4956 21.5143 19.6552 21.371 19.702 21.2101C19.7489 21.0491 19.6885 20.8269 19.5099 20.5495C18.2436 18.5901 16.9767 16.6322 15.7093 14.6758Z" fill="#16123F"/>
      <path d="M9.0992 10.7274C9.12858 10.7303 9.15826 10.7264 9.18573 10.7159C9.2034 10.681 9.21313 10.6429 9.2143 10.604C9.25029 10.2901 9.31241 9.97947 9.40005 9.67517C9.66755 8.80532 10.2073 8.09257 11.0487 7.50092L10.1518 6.11375C9.14207 4.55338 8.09747 2.93936 7.06476 1.356C6.92982 1.14907 6.7663 1.02645 6.5885 1.00269C6.41069 0.978935 6.25034 1.04561 6.08047 1.19122L5.79075 1.43801C5.07079 2.05112 4.32623 2.68722 3.71106 3.3969C2.18383 5.15959 1.27257 7.32388 1.00427 9.82615C0.970138 10.1465 1.02252 10.3925 1.15746 10.5374C1.2678 10.6561 1.44164 10.7175 1.67421 10.7182C3.1173 10.7259 4.5842 10.7259 6.00268 10.7251H6.68693C7.4897 10.7251 8.29193 10.7251 9.09364 10.7251L9.0992 10.7274Z" fill="#16123F"/>
      <path d="M21.0385 1.27244C20.7678 1.07164 20.5511 0.988878 20.3773 1.018C20.2034 1.04712 20.032 1.19734 19.851 1.47784C19.1556 2.54465 18.4508 3.63062 17.7697 4.68134L17.2395 5.49985C16.9267 5.98166 16.615 6.4632 16.3044 6.9445L15.9512 7.49093C17.1784 8.61446 17.2403 8.72865 17.8197 10.7274H20.0367C21.7391 10.7274 23.4415 10.7274 25.1439 10.7274C25.4916 10.7274 25.7234 10.6592 25.8527 10.5197C25.9821 10.3802 26.0234 10.1633 25.9877 9.84682C25.5876 6.29843 23.9223 3.41527 21.0385 1.27244Z" fill="#16123F"/>
      <path d="M11.922 12.4663C12.3228 12.8583 12.8684 13.0808 13.4389 13.0848H13.4548C14.7383 13.0756 15.7512 12.1253 15.7607 10.9221C15.7641 10.6341 15.708 10.3484 15.5958 10.0816C15.4836 9.81481 15.3174 9.57228 15.107 9.36816C14.8966 9.16404 14.6463 9.00243 14.3705 8.89276C14.0947 8.78309 13.7991 8.72756 13.5008 8.72941C13.2027 8.72757 12.9072 8.78302 12.6315 8.89253C12.3558 9.00203 12.1054 9.16341 11.8949 9.36726C11.6845 9.57111 11.5181 9.81336 11.4056 10.0799C11.293 10.3464 11.2365 10.6319 11.2394 10.9198C11.2463 11.5021 11.4917 12.0582 11.922 12.4663Z" fill="#16123F"/>
    </svg>
  ),
  backgroundNoise: (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.69302 -0.000366211H8.66446C8.43191 8.63447e-05 8.20715 0.0835735 8.03068 0.235071C7.85421 0.386568 7.73761 0.596114 7.70188 0.825975C7.68799 0.957789 7.68295 1.09039 7.6868 1.22288V1.31178C7.6868 8.77352 7.6868 16.2324 7.6868 23.6883V23.7621C7.6841 23.8887 7.68834 24.0154 7.6995 24.1415C7.7206 24.3793 7.83146 24.6002 8.00954 24.7591C8.18761 24.9181 8.41952 25.0032 8.65811 24.9973C9.27073 24.9973 9.62147 24.5932 9.62227 23.8859C9.62227 21.15 9.62227 18.4137 9.62227 15.6772V2.97639C9.62227 2.79461 9.62227 2.61363 9.62227 2.43184C9.62227 1.92381 9.62624 1.3999 9.60958 0.879958C9.59618 0.64456 9.49423 0.422954 9.32421 0.259654C9.15419 0.096355 8.9287 0.00346292 8.69302 -0.000366211Z" fill="#16123F"/>
      <path d="M12.8035 4.35444C12.6985 4.32093 12.5891 4.30353 12.4789 4.30284C12.285 4.30191 12.0959 4.3631 11.9393 4.47747C11.6711 4.67433 11.5163 5.01091 11.5155 5.40146C11.5155 7.0613 11.5155 8.74971 11.5155 10.3826V15.0763C11.5155 15.5283 11.5155 15.9794 11.5155 16.4297C11.5155 17.4776 11.5116 18.5611 11.5203 19.6351C11.5203 20.0146 11.6655 20.3273 11.9171 20.5178C12.0438 20.6073 12.1898 20.6655 12.3433 20.6879C12.4968 20.7102 12.6534 20.696 12.8003 20.6464C13.2677 20.5091 13.4859 20.1463 13.4859 19.5041C13.4859 16.0717 13.4859 12.6396 13.4859 9.20774V6.4977C13.4859 6.37942 13.4859 6.25955 13.4859 6.14366C13.4859 5.87138 13.4859 5.588 13.4859 5.31414C13.4717 4.84977 13.2106 4.48224 12.8035 4.35444Z" fill="#16123F"/>
      <path d="M21.1619 14.3627V12.5417C21.1619 10.3897 21.1619 7.95114 21.1619 5.59355C21.1702 5.36434 21.1434 5.1352 21.0826 4.91405C21.0162 4.73391 20.8961 4.57849 20.7386 4.46876C20.5811 4.35903 20.3937 4.30028 20.2017 4.30045C20.1485 4.30062 20.0953 4.30567 20.043 4.31553C19.5264 4.41158 19.2177 4.79181 19.2161 5.32604C19.2161 7.02636 19.2161 8.73939 19.2161 10.4064C19.2161 11.0943 19.2161 11.7823 19.2161 12.4703V13.6506C19.2161 15.5849 19.2161 17.5191 19.2161 19.4533C19.2101 19.6355 19.2253 19.8177 19.2614 19.9963C19.3129 20.1996 19.4312 20.3797 19.5972 20.5078C19.7632 20.6359 19.9675 20.7046 20.1771 20.7028H20.193C20.6136 20.7028 21.004 20.4265 21.1008 20.0606C21.1492 19.8452 21.1705 19.6247 21.1643 19.4041C21.1683 18.1213 21.1683 16.8243 21.1643 15.5701L21.1619 14.3627Z" fill="#16123F"/>
      <path d="M5.79484 12.533C5.79484 12.0948 5.79484 11.6569 5.79484 11.2192V11.0549C5.79484 9.89121 5.79484 8.68781 5.79484 7.49393C5.79983 7.32378 5.76352 7.15498 5.68903 7.00194C5.61454 6.8489 5.50408 6.7162 5.36712 6.61519C5.21389 6.50783 5.03125 6.45043 4.84417 6.45087C4.72808 6.45139 4.61293 6.47179 4.50373 6.5112C4.04347 6.67631 3.84668 7.01607 3.84668 7.65031C3.84668 10.6088 3.84668 13.7761 3.84668 17.3347C3.84705 17.4831 3.85899 17.6311 3.88239 17.7776C3.90328 17.9348 3.96761 18.083 4.06813 18.2056C4.16865 18.3281 4.30138 18.4202 4.45137 18.4714C4.59669 18.5351 4.75542 18.562 4.9136 18.5498C5.07177 18.5376 5.22452 18.4867 5.35839 18.4016C5.63216 18.2158 5.79167 17.8848 5.79405 17.4935C5.80198 16.3226 5.8004 15.143 5.79881 14.0007C5.79617 13.5112 5.79484 13.022 5.79484 12.533Z" fill="#16123F"/>
      <path d="M16.3436 8.61795C15.7461 8.61795 15.3866 9.01485 15.3818 9.68403C15.3818 10.3318 15.3818 10.9922 15.3818 11.6296V12.3012L15.3866 12.3806V12.4996C15.3866 12.7785 15.3866 13.0577 15.3866 13.3371V13.3887C15.3866 14.0714 15.3811 14.7771 15.3938 15.4716C15.4041 16.0138 15.7858 16.3789 16.346 16.3805C16.9634 16.3805 17.3165 15.9947 17.3189 15.3224C17.326 13.6356 17.326 11.8455 17.3189 9.6864C17.3102 9.04025 16.9301 8.61954 16.3436 8.61795Z" fill="#16123F"/>
      <path d="M0.983552 10.7676C0.956572 10.7676 0.928797 10.7676 0.904197 10.7676C0.66749 10.7833 0.445004 10.8862 0.279693 11.0564C0.114382 11.2266 0.0179667 11.452 0.00907 11.6892C0.00272159 11.8686 0.00430869 12.0543 0.00907 12.2337V12.256C0.00907 12.3282 0.00907 12.4004 0.00907 12.4727C0.00907 12.5624 0.00907 12.6528 0.00907 12.7425V12.7561C0.00907 12.9482 0.00431412 13.1442 0.0146303 13.3331C0.025761 13.5642 0.121841 13.783 0.28442 13.9476C0.446999 14.1121 0.664611 14.2108 0.895469 14.2246C1.45095 14.2635 1.86757 13.9618 1.91359 13.4911C1.9781 12.8358 1.97997 12.1758 1.91915 11.5201C1.86916 11.0756 1.4819 10.7676 0.983552 10.7676Z" fill="#16123F"/>
      <path d="M24.9975 12.5282C24.9975 12.4568 24.9975 12.3853 24.9975 12.3139V12.2829C24.9975 12.1051 24.9975 11.9218 24.9975 11.7408C24.9856 11.2169 24.6134 10.8096 24.1119 10.7715C24.0794 10.7715 24.0468 10.7715 24.0151 10.7715C23.4953 10.7715 23.1168 11.101 23.074 11.5899C23.0192 12.208 23.0224 12.8299 23.0835 13.4474C23.1327 13.9595 23.5334 14.2682 24.1016 14.2325C24.6206 14.2 24.9801 13.8166 24.9975 13.2799C25.0023 13.1117 24.9975 12.9394 24.9975 12.7727C24.9983 12.6878 24.9975 12.6084 24.9975 12.5282Z" fill="#16123F"/>
    </svg>
  ),
  leftArrow: (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="5" fill="#D5EAEB"/>
      <path d="M10.2929 16.2929C9.90237 16.6834 9.90237 17.3166 10.2929 17.7071L16.6569 24.0711C17.0474 24.4616 17.6805 24.4616 18.0711 24.0711C18.4616 23.6805 18.4616 23.0474 18.0711 22.6569L12.4142 17L18.0711 11.3431C18.4616 10.9526 18.4616 10.3195 18.0711 9.92893C17.6805 9.53841 17.0474 9.53841 16.6569 9.92893L10.2929 16.2929ZM23 16H11V18H23V16Z" fill="#16123F"/>
    </svg>
  ),
};
