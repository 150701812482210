import { useEffect } from "react";
import styles from "./accordion.module.scss";

const Accordion = ({title, content, isActive, toggle, isDarkTheme}) => {

  useEffect(() => {
    if(isActive){
      document.getElementById('accordion-content-active').classList.add(styles["accordion-active-content"]);
    }
  });

  return (
    <div className={styles["accordion"]}>
      <div className={!isDarkTheme ? styles["accordion-title"] : styles["accordion-title-dark"]} onClick={toggle}>
        <span>{title}</span>
        <span>{isActive ? '-' : '+'}</span>
      </div>
      <div id="accordion-content-active"
        className={!isActive ? styles["accordion-content"] : `${styles["accordion-content"]} ${styles["accordion-content-active"]}`}
        aria-expanded={!isActive}>
          {content}          
      </div>
    </div>
  )
};

export default Accordion;