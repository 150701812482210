/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { useStore, useDispatch } from "react-redux";
import styles from "./channelItem.module.scss";
import ThemeContext from "../../../../context/theme-context";
import { icons } from "../Assets/icons";
import { translator } from "../../../../utils/translator";
import { fetchDefinedStandards } from "../../../../redux/DefinedStandards/definedStandardsSlice";
import { dateToLocale } from "../../../../utils/timeAndDate";

const getIconAndDescKey = (channelType) => {
  let icon = "";
  let descriptionKey = "";
  switch (channelType) {
    case "Temperature":
      icon = icons.temperature;
      descriptionKey = "temperatureDescription";
      break;
    case "Pressure":
      icon = icons.pressure;
      descriptionKey = "pressureDescription";
      break;
    case "Humidity":
      icon = icons.humidity;
      descriptionKey = "humidityDescription";
      break;
    case "WindSpeed":
      icon = icons.windSpeed ?? "";
      descriptionKey = "windSpeed";
      break;
    case "WindDirection":
      icon = icons.windDirection ?? "";
      descriptionKey = "windDirection";
      break;
    case "PM10":
      icon = icons.pm1;
      break;
    case "PM25":
      icon = icons.pm2;
      break;
    case "PM40":
      icon = icons.pm4;
      break;
    case "PM100":
      icon = icons.pm10;
      break;
    case "NO2":
      icon = icons.no2;
      descriptionKey = "no2Description";
      break;
    case "SO2":
      icon = icons.so2;
      descriptionKey = "so2Description";
      break;
    case "CO":
      icon = icons.co;
      descriptionKey = "coDescription";
      break;
    case "O3":
      icon = icons.o3;
      descriptionKey = "o3Description";
      break;
    case "HCHO":
      icon = icons.hcho;
      descriptionKey = "hchoDescription";
      break;
    default:
      icon = "";
      break;
  }

  return { icon, descriptionKey };
};

const ChannelItem = ({ channel, isOnline, lastTick, lang }) => {

  const ctx = useContext(ThemeContext);
  let { isDarkTheme } = ctx;
  // definedStandards
  const store = useStore();
  const [ { definedStandards }, setDefinedStandards ] = useState(store.getState());  
  const { name, type, value, suffix, standards } = channel;
  let standard = standards[0];
  // end definedStandards
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleCollapse = () => {
    setIsCollapseOpen((prevState) => !prevState);
  };

  const dispatch = useDispatch();

  let matchingStandard = null;

  let percentage = "";
  let standardName = "";
  let standardLimit = "";
  let standardDescrip = "";
  let bgColorClass = "index-bg-";
  let percentageBarClass = "index-color-";
  if (standard) {
    const currPercentage = standard.percentage * 100;

    if (currPercentage <= 25) {
      bgColorClass += "very-low";
      percentageBarClass += "very-low";
    } else if (currPercentage <= 50) {
      bgColorClass += "low";
      percentageBarClass += "low";
    } else if (currPercentage <= 75) {
      bgColorClass += "medium";
      percentageBarClass += "medium";
    } else if (currPercentage <= 100) {
      bgColorClass += "high";
      percentageBarClass += "high";
    } else {
      bgColorClass += "very-high";
      percentageBarClass += "very-high";
    }

    percentage = Math.round(currPercentage) + "%";

    const standardSlug = standard.standard;
    const backupStandardName = standardSlug + " regulations";
    
    if(definedStandards){
      matchingStandard = definedStandards.find(
        (currStand) => currStand.slug === standardSlug
      );
    }

    //console.log('ChannelItem_111:', matchingStandard);
    standardName = matchingStandard
      ? matchingStandard.name
      : backupStandardName;
    standardLimit = matchingStandard
      ? matchingStandard.limit
      : standard.limit;
    standardDescrip = matchingStandard
      ? matchingStandard.description
      : standard.description
  } else {
    if (isDarkTheme) {
      bgColorClass = "index-bg-no-index-dark";
    }
  }

  if(!isOnline){
    if(isDarkTheme){
      bgColorClass = "index-bg-no-recent-dark";
    } else {
      bgColorClass = "index-bg-no-recent";
    }
  }

  const { icon, descriptionKey } = getIconAndDescKey(type);  
  const description = translator.textWidgets[lang][descriptionKey];

  // if standard is not sentense
  if(standardName.startsWith('eu-air')){
    let regexStandName = /-|\s/gi;
    let slugName = standardName.replace(regexStandName, '');
    standardName = translator.navTexts[lang][slugName];
  }

  // to use in useEffect, when language changed
  const getStandards = (standardSlug) => {
    let backupStandardName = standardSlug + " regulations";
    matchingStandard = definedStandards.find(
      (currStand) => currStand.slug === standardSlug
    );
    standardName = matchingStandard
      ? matchingStandard.name
      : backupStandardName;
    standardLimit = matchingStandard
      ? matchingStandard.limit
      : standard.limit;
    standardDescrip = matchingStandard
      ? matchingStandard.description
      : standard.description
  }

  useEffect(() => {
    let isSubscribed = true; // component loaded or not
    dispatch(fetchDefinedStandards())
    .unwrap()
    .then((originalPromiseResult) => {
      // handle result here
      // check if component loaded
      if(isSubscribed) {
        setDefinedStandards(originalPromiseResult);
        // check if device supports standards
        if(standard !== undefined){
          let standardSlug = standard.standard;
          getStandards(standardSlug);
        }
      }
      
    })
    .catch((rejectedValueOrSerializedError) => {
      // handle error here
      if(isSubscribed){
        console.log('ListItemsError: ', rejectedValueOrSerializedError);
      }
    });
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
  }, [lang]);

  //console.log('ChannelItem online: ', isOnline);

  return (
    <div className={`${styles["container"]} ${styles[bgColorClass]} ${isCollapseOpen && (styles["toggled"])}`}>
      <div className={styles["main-container"]}>
        <div
          className={
            isDarkTheme 
              ? styles["icon-container-dark"]
              : styles["icon-container"]
          }
        >
          {icon}
        </div>
        <p
          className={
            isDarkTheme
              ? styles["channel-name-dark"]
              : styles["channel-name"]
          }
        >
          {description ? description : name}
        </p>
        {!isOnline && (<p className={styles["data-not-recent-icon"]}>{icons.noRecentCloud}</p>)}
        <p className={isOnline ? (isDarkTheme && !standard ? styles["p-dark"] : null) : styles["data-not-recent-value"]}>
          {value} {suffix}          
        </p>
        {standard && (
          <button onClick={toggleCollapse}>
            {isCollapseOpen ? icons.upArrow : icons.downArrow}
          </button>
        )}
      </div>
      {standard && isCollapseOpen && (
        <div className={styles.descriptionContainer}>
          <p>{standardName}</p>
          {/* <p>
            {standard.period === "One hour" &&
              translator.textWidgets[lang].limitHText}
            : {standard.limit} {suffix}
          </p> */}
          <p>
            {standardDescrip}: {standardLimit} {suffix}
          </p>
          { !isOnline && (
              <p className={!isDarkTheme ? styles["not-recent-date-text"] : styles["not-recent-date-text-dark"]}>{`${translator.textMessages[lang].dataFrom}${dateToLocale(lastTick, lang)}`}</p>
            )
          }
        </div>
      )}
      <div className={styles["fill-percentage"]}>
        <span
          className={styles[percentageBarClass]}
          style={{ width: `${percentage}` }}
        ></span>
      </div>
    </div>
  );
};

export default ChannelItem;
