import ReactDOM from "react-dom";
import React, { useContext } from "react";
import LangContext from "../../context/lang-context";
import { translator } from "../../utils/translator";

import styles from "./errorModal.module.scss";

const Backdrop = ({text}) => {
  return <div className={styles["backdrop"]}>{text}</div>;
};

const ModalOverlay = ({title, message}) => {
  const langCtx = useContext(LangContext);
  const { lang } = langCtx;
  return (
    <div className={styles["modal-overlay"]} title={title}>
      <div className={styles["modal-message"]}>
        <p>{message}</p>
      </div>
    </div>
  );
};

const ErrorModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop 
          onClick={props.closeErrorModalHandler}
            text={props.text}
          />,
          document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay closeErrorModalHandler={props.closeErrorModalHandler}
          title={props.title}
          message={props.message}/>,
          document.getElementById("overlay-root")
      )}
    </>
  );
};

export default ErrorModal;
