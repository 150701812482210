import React, { useMemo } from "react";
import styles from "./header.module.scss";

import Index from "./Index/Index";

import { icons } from "./Assets/icons";

import bgImageNoIndex from "./Assets/bg-image-no-index.svg";
import bgImageIndexVeryLow from "./Assets/bg-image-index-very-low.svg";
import bgImageIndexLow from "./Assets/bg-image-index-low.svg";
import bgImageIndexMedium from "./Assets/bg-image-index-medium.svg";
import bgImageIndexHigh from "./Assets/bg-image-index-high.svg";
import bgImageIndexVeryHigh from "./Assets/bg-image-index-very-high.svg";
import bgImageWaterVeryLow from "./Assets/bg-image-water-very-low.svg";
import bgImageWaterLow from "./Assets/bg-image-water-low.svg";
import bgImageWaterMedium from "./Assets/bg-image-water-medium.svg";
import bgImageWaterHigh from "./Assets/bg-image-water-high.svg";
import bgImageWaterVeryHigh from "./Assets/bg-image-water-very-high.svg";
import UpdateTimer from "./UpdateTimer/UpdateTimer";
//import { isDataRecent } from "../../../utils/utils";
import { FaceBookButton } from "./ShareButtons";
//import html2canvas from "html2canvas";
//import { mapConfig } from "../../map.config";

//import LangContext from "../../../context/lang-context";
//import ThemeContext from "../../../context/theme-context";
import { translator } from "../../../utils/translator";


//import { useState } from "react";

//import html2canvas from "html2canvas";
import { getImage } from "./ScreenShot";
//import { isNull } from "lodash";

//const updateDeviceIndexMinutes = 10;
//const updateDeviceIndexLimitInMinutes = updateDeviceIndexMinutes * 3;

const getBgDetails = (indexValue, uiCat, limitRecords) => {
  // JUST For test - for Water
  /*
  limitRecords.LowerLimitEnabled = true;
  limitRecords.LowerLimitValue = 20;
  indexValue = 4;
  */
  // End for test

  // following var is for water levels
  let isLowerLimitReaching = false;
  if(limitRecords !== null){
    if(limitRecords.LowerLimitEnabled && indexValue < limitRecords.LowerLimitValue){
      isLowerLimitReaching = true;
    }
  }
  let bgImage = "";
  let bgColorClass = "index-bg-";
  let indexTextBgColorClass = "index-color-";
  let indexDescTextNumber = null;
  
  switch(uiCat) {
    case "1": // air
      if (!indexValue && indexValue !== 0) {
        bgImage = bgImageNoIndex;
        bgColorClass += "no-index";
      } else if (indexValue <= 25) {
        bgImage = bgImageIndexVeryLow;
        bgColorClass += "very-low";
        indexTextBgColorClass += "very-low";
        indexDescTextNumber = 1;
      } else if (indexValue <= 50) {
        bgImage = bgImageIndexLow;
        bgColorClass += "low";
        indexTextBgColorClass += "low";
        indexDescTextNumber = 2;
      } else if (indexValue <= 75) {
        bgImage = bgImageIndexMedium;
        bgColorClass += "medium";
        indexTextBgColorClass += "medium";
        indexDescTextNumber = 3;
      } else if (indexValue <= 100) {
        bgImage = bgImageIndexHigh;
        bgColorClass += "high";
        indexTextBgColorClass += "high";
        indexDescTextNumber = 4;
      } else {
        bgImage = bgImageIndexVeryHigh;
        bgColorClass += "very-high";
        indexTextBgColorClass += "very-high";
        indexDescTextNumber = 5;
      }
    break;
    case "2": // water
      bgColorClass += "water-level";
      indexTextBgColorClass += "water-level";
      if (indexValue === 0) {
        bgImage = bgImageWaterVeryLow;        
        indexDescTextNumber = 1;
      }
       //else if (indexValue <= 25) {
        else if (indexValue === 1) {
        bgImage = bgImageWaterLow;        
        indexDescTextNumber = 1;
      } //else if (indexValue <= 50) {
        else if (indexValue === 2) {
        bgImage = bgImageWaterMedium;
        indexDescTextNumber = 2;
      } //else if (indexValue <= 75) {
      else if (indexValue === 3) {
        if(!isLowerLimitReaching){
          bgImage = bgImageWaterHigh;
        } else {
          bgImage = bgImageWaterLow;
        }
        indexDescTextNumber = 3;
      } //else if (indexValue <= 100) {
      else if (indexValue === 4) {
        if(!isLowerLimitReaching){
          bgImage = bgImageWaterVeryHigh;
        } else {
          bgImage = bgImageWaterVeryLow;
        }
          indexDescTextNumber = 4;
      } else {
        bgImage = bgImageNoIndex;
        bgColorClass = bgColorClass.replace("water-level", "") + "no-index";
      }
    break;

    default:
      bgColorClass += "no-index";
      bgImage = null;

  }

  return {
    bgImage,
    bgColorClass,
    indexTextBgColorClass,
    indexDescTextNumber,
  };
};

var Header = ({ indexes, updateHeader, device, lang, isDarkTheme, mapIdx }) => {
  //const langCtx = useContext(LangContext);
  //const { lang } = langCtx;

  //const darkCtx = useContext(ThemeContext);
  //let { isDarkTheme } = darkCtx;

  const { address, city, country } = device.location;
  const locationDetails = `${address}, ${city}, ${country}`;

  const index = useMemo(() => {
    if (indexes.length > 0) {
      let index = indexes.find((index) => index.slug === "sbaqi");
      if (!index) {
        index = indexes[0];
      }

      const { value, slug, timeStamp } = index;
      return { value, slug, timeStamp };
    }
    return null;
  }, [indexes]);

  //let error = false;
  let error = !device.onlineStatus.isOnline;
  //if (index) {
    //error = !isDataRecent(index.timeStamp, updateDeviceIndexLimitInMinutes);
    //error = !device.onlineStatus.isOnline;
  //}

  let idxVal = index?.value;
  // replace index.value with riskLevel for water qty device
  if(device.uiCategories === "2" ){
    idxVal = device.riskLevel.level;
    //idxVal = 300;
  }
  const { bgImage, bgColorClass, indexTextBgColorClass, indexDescTextNumber } =
    getBgDetails(!error ? idxVal : null, device.uiCategories, JSON.parse(device.limits.limitRecord));

  const bgImageKey = useMemo(() => Math.random().toString(), []);

  let link = '';
  if(mapIdx === 0){
    link = `https://senstate.com/map/#${device.location.latitude},${device.location.longtitude},${device.token},${device.uiCategories}`;
  } else {
    link = `https://senstate.com/map/#${device.location.longtitude},${device.location.latitude},${device.token},${device.uiCategories}`;
  }
  let quote = `${translator.shareLinks[lang].quote} ${device.customName}`; // device->customName

  const onShareClick = () => {
 
    let container = document.body;

      let ogUrl = document.querySelector('meta[property="og:url"]');
      if(ogUrl){
        ogUrl.setAttribute("content", link);
      }
      /*
      getImage(container).then((ogImg) => {
        //if(ogImg !== null){
          let ogMeta = document.querySelector('meta[property="og:image"]');
          if(ogMeta){
            ogMeta.setAttribute("content", ogImg);
          }
        //}
        // ID = 645363227396949
        // Sec = 7db515898176779119adce2b60afa062
        */
        <FaceBookButton
          url={link}
          quote={quote}
          hashtag={`#${translator.shareLinks[lang].hashtag},#${device.location.country},#${device.location.city}`}
          openShareDialogOnClick={true}
          resetButtonStyle={false}
          styles={styles}
          //img={ogImg}
        />
      /* }); */
  };



  //console.log('Header online: ', device.onlineStatus.isOnline);

  return (
    <>
      <div className={`${styles["container"]} ${styles[bgColorClass]}`}>
        <div key={bgImageKey} className={styles["bg-image-container"]}>
          { bgImage !== null && (<img src={bgImage} alt="index background" />) }
        </div>
        <div className={styles["index-and-address-container"]}>
          {(!device.onlineStatus.isOnline && indexes.length > 0) && (
            <div className={styles["error-container"]}>
              <div className={styles["icon-container"]}>
                { icons.noData }
                <p>{icons.noDataCircle}{translator.textMessages[lang].noRecentData}</p>
              </div>
            </div>
          )}
          {(!device.onlineStatus.isOnline && !indexes.length) && (
            <div className={styles["error-container"]}>
              <div className={styles["icon-container"]}>
                <p>{translator.textMessages[lang].noIndexSupport}</p>
              </div>
            </div>
          )}
          {(device.onlineStatus.isOnline && !indexes.length && device.uiCategories === "1") && (
            <div className={styles["error-container"]}>
              <div className={styles["icon-container"]}>
                {/*icons.noData*/}
                <p>{translator.textMessages[lang].noIndexSupport}</p>
              </div>
            </div>
          )}
          {(device.onlineStatus.isOnline && index && device.uiCategories === "1") && (<Index index={index} lang={lang} />)}
          <div className={styles["address-and-social-icons-container"]}>
            {address && (
              <span className={styles["address-container"]}>
                {locationDetails}
              </span>
            )}
            <div className={styles["social-icons-container"]}>
            {/*
            <button 
              style={{background: "transparent", border: "none"}}
              onClick={onShareClick}
              >
                {icons.facebook}
            </button>
            
            <button 
              style={{background: "transparent", border: "none"}}
              onClick={onShareClick}
              >
                {icons.linkedin}
            </button>
            */}
            </div>
          </div>
        </div>
      </div>
      {device.onlineStatus.isOnline && index && (
        <div
          className={`${styles["index-description-container"]} ${styles[indexTextBgColorClass]}`}
        >
          <span>{translator.CAQMDescriptions[lang][indexDescTextNumber]}</span>
        </div>
      )}
      <UpdateTimer 
        lang={lang} 
        updateHeader={updateHeader} 
        indexes={indexes} 
        error={error} 
        lastSubmission={device.onlineStatus.lastSubmission}  
        isOnline={device.onlineStatus.isOnline}
      />
    </>
  );
};

export default Header;
